import React from "react";
import { useContext, useEffect, useState } from "react";
import { LoginType, UserContext, UserProfile } from "../UserAccess/UserContext";
import { CenteredSpinner } from "./CenteredSpinner";


const BusinessAdminView = ({ render }: { render: (profile: UserProfile) => JSX.Element }) => {
  const { user, busy, login } = useContext(UserContext);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (busy) return;

    if (!user) {
      login();
    } else {
      setLoaded(true);
    }
  }, [busy, user, login])

  if (!loaded) return <CenteredSpinner />

  if (!user || user?.userType !== LoginType.User) {
    return <h1>Please login as a user</h1>
  }

  if (!user.selectedBusiness) {
    return <h1>Please select a business</h1>
  }

  if (!user.selectedBusiness.administrator) {
    return <h1>Must be a business administrator to access this page</h1>
  }

  return render(user as UserProfile);
}

export default BusinessAdminView;