import { DraggableAttributes, DraggableSyntheticListeners } from '@dnd-kit/core';
import React, { CSSProperties, forwardRef, HTMLAttributes } from 'react';

interface ItemProps extends HTMLAttributes<HTMLDivElement> {
  id: string;
  attributes?: DraggableAttributes;
  listeners?: DraggableSyntheticListeners;
  isOpacityEnabled?: boolean
  isDragging?: boolean
  handle?: boolean
  handleProps?: any;
}

export const Item = forwardRef<HTMLDivElement, ItemProps>(({ id, style, isOpacityEnabled, isDragging, attributes, listeners, handle, handleProps, ...props }, ref) => {
  const inlineStyles: CSSProperties = {
    opacity: isOpacityEnabled ? "0.4" : "1",
    cursor: !handle ? isDragging ? "grabbing" : "grab" : undefined,
    ...style,
  };

  return (
    <div
      className="position-relative"
      ref={ref}
      style={inlineStyles}
      {...(!handle ? attributes : undefined)}
      {...(!handle ? listeners : undefined)}
      {...props}
      tabIndex={!handle ? 0 : undefined}> 
        {props.children}
        {handle ? <button 
          className="btn position-absolute top-0 start-0 translate-end zi-1"
          style={{ cursor: isDragging ? "grabbing" : "grab" }}
          {...handleProps} {...attributes} {...listeners}>
            <i className="bi-grip-vertical" style={{ fontSize: "1.5em"}}/>
          </button> : null}
    </div>
  );
});
