import React from 'react';
import { FieldValues } from 'react-hook-form';
import { FormGroup, FormFeedback } from 'reactstrap';
import { toDisplayFromCamelCase } from '../../../Utilities';
import { FormCommonProps } from './FormCommon';
import { FormLabel } from './FormLabel';
import { FormRow, FormRowSize } from './FormRow';
import { useFormError } from './useFormError';

interface FormTextProps<FormType extends FieldValues> extends FormCommonProps<FormType> {
  placeholder?: string,
  noPlaceholder?: boolean,
}

interface RowFormTextProps<FormType extends FieldValues> extends FormTextProps<FormType>, FormRowSize { }

export function FormText(props: FormTextProps<any>) {
  return <FormGroup>
    <FormLabel {...props} />
    <FormTextControl {...props} />
  </FormGroup>
}

export function RowFormText(props: RowFormTextProps<any>) {
  return <FormRow
    {...props}
    control={<FormTextControl {...props} />}
  />
}

export function FormTextControl(props: FormTextProps<any>) {
  const { inError, errorMessage } = useFormError(props);
  const placeholder = props.placeholder ?? (props.displayName ?? toDisplayFromCamelCase(props.name)).toLowerCase();
  const controlProps = props.register(props.name, { ...props.options });

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    props.setValue(props.name, e.target.value?.trim());
    controlProps.onBlur(e);
  }

  return <>
    <input
      type='text'
      className={`form-control ${inError ? 'is-invalid' : ''}`}
      placeholder={props.noPlaceholder ? '' : `Enter ${placeholder}`}
      id={props.name}
      ref={controlProps.ref}
      name={controlProps.name}
      onChange={controlProps.onChange}
      onBlur={onBlur}
      disabled={!(props.enabled ?? true)}
    />
    <FormFeedback>{errorMessage}</FormFeedback>
  </>
}
