import React, { Fragment } from 'react';
import { Alert } from 'reactstrap';
import { useUser } from '../UserAccess/useUser';

type Props = {
  children: React.ReactNode
}

const BusinessTemplate = ({ children }: Props) => {
  const user = useUser();

  return (user.selectedBusiness ?
    <Fragment key={user.selectedBusiness.id}>{children}</Fragment> :
    <Alert color='danger'>Please select a business.</Alert>);
}

export default BusinessTemplate;
