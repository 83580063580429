import React from 'react';
import { Query } from './useQuery';
import { Error } from './useFetch';
import { CenteredSpinner } from './CenteredSpinner';
import { Alert } from 'reactstrap';

export interface QueryViewProps<Resp> {
  query: Query<Resp>;
  renderData: (data: Resp) => JSX.Element;
  renderLoading?: () => JSX.Element;
  renderError?: (err: Error) => JSX.Element;
}

const DefaultError = (e: Error) => {
  return <Alert color="danger">{e.message}</Alert>
}

const DefaultLoader = () => {
  return <CenteredSpinner />
}

export default function QueryView<Resp>(props: QueryViewProps<Resp>) {
  const loading = props.renderLoading ?? DefaultLoader;
  const error = props.renderError ?? DefaultError;

  if (props.query.isLoading) return loading();
  if (props.query.isError) return error(props.query.error as Error);

  return props.renderData(props.query.data as Resp);
}
