import { HttpMethod } from "../components/Common/useFetch";
import { RequestOptions } from "../components/Common/useRequest";
import useUserRequest, { UserRequestSpec } from "../components/Common/useUserRequest";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";
import { BranchDto } from "./useGetBranches";

export type BranchUpsertDto = Omit<BranchDto, 'id'>;

const path = (businessId: string) => `/api/businesses/${businessId}/branches`;

const createSpec = (businessId: string): UserRequestSpec => ({
  path: path(businessId),
  method: HttpMethod.POST,
});

const updateSpec = (businessId: string, branchId: string): UserRequestSpec => ({
  path: `${path(businessId)}/${branchId}`,
  method: HttpMethod.PUT,
});

const useUpsertBranch = (branchId?: string, options?: RequestOptions<string>) => {
  const business = useSelectedBusiness();

  var spec = branchId
    ? updateSpec(business.id, branchId)
    : createSpec(business.id);

  return useUserRequest<BranchUpsertDto, string>(spec, options);
}

export default useUpsertBranch;
