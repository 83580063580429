import React, { useCallback, useEffect, useReducer } from 'react'
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { customerSignInRequest, forgotPasswordRequest } from './../../authConfig';
import { useMsal } from '@azure/msal-react';
import { UserContext, UserStatus, UserBusiness, UserProfile, KaEmployeeProfile, LoginType, Business } from './UserContext';
import useHandleForgotPassword from './msal/useHandleForgotPassword';

enum AuthStep {
  NotAuthenticated,
  Authenticated,
  AcquireUserProfile,
  UserDone,
  KaEmployeeDone,
  Error,
}

enum AuthAction {
  SignedIn,
  TokenReceived,
  EmployeeProfileReceived,
  UserProfileReceived,
  ErrorOccurred,
  UserBusinessSelected,
  EmployeeBusinessSelected
}

type State =
  | { step: AuthStep.NotAuthenticated }
  | { step: AuthStep.Authenticated, account: AccountInfo }
  | { step: AuthStep.AcquireUserProfile, account: AccountInfo, tokenResponse: AuthenticationResult }
  | { step: AuthStep.UserDone, user: UserProfile }
  | { step: AuthStep.KaEmployeeDone, employee: KaEmployeeProfile }
  | { step: AuthStep.Error }

type Action =
  | { type: AuthAction.SignedIn, account: AccountInfo }
  | { type: AuthAction.TokenReceived, account: AccountInfo, tokenResponse: AuthenticationResult }
  | { type: AuthAction.UserProfileReceived, userProfile: UserProfile }
  | { type: AuthAction.EmployeeProfileReceived, employee: KaEmployeeProfile }
  | { type: AuthAction.ErrorOccurred, error: string }
  | { type: AuthAction.UserBusinessSelected, selectedBusiness: UserBusiness | null }
  | { type: AuthAction.EmployeeBusinessSelected, selectedBusiness: Business | null }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case AuthAction.SignedIn:
      return { step: AuthStep.Authenticated, account: action.account };

    case AuthAction.TokenReceived:
      return { step: AuthStep.AcquireUserProfile, account: action.account, tokenResponse: action.tokenResponse };

    case AuthAction.EmployeeProfileReceived:
      return { step: AuthStep.KaEmployeeDone, employee: action.employee };

    case AuthAction.UserProfileReceived:
      return { step: AuthStep.UserDone, user: action.userProfile };

    case AuthAction.UserBusinessSelected:
      if (state.step === AuthStep.UserDone) {
        const user = state.user;
        user.selectedBusiness = action.selectedBusiness;
        return { step: AuthStep.UserDone, user: state.user };
      } else { break; }

    case AuthAction.EmployeeBusinessSelected:
      if (state.step === AuthStep.KaEmployeeDone) {
        const employee = state.employee;
        employee.selectedBusiness = action.selectedBusiness;
        return { step: AuthStep.KaEmployeeDone, employee: state.employee};
      } else { break; }
  }

  return { step: AuthStep.Error };
}

type Props = {
  children?: React.ReactNode
};

export const UserProvider: React.FC<Props> = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal();
  const [state, dispatch] = useReducer(reducer, { step: AuthStep.NotAuthenticated });

  useHandleForgotPassword(instance, forgotPasswordRequest);

  const justSignedIn = useCallback(() =>
    state.step === AuthStep.NotAuthenticated && accounts[0]
    && inProgress === InteractionStatus.None,
    [accounts, state.step, inProgress]);

  const acquireTokenForAccount = useCallback((account: AccountInfo) => {
    const request = { ...customerSignInRequest, account };
    return instance.acquireTokenSilent(request)
      .catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(request);
        }
        throw error;
      });
  }, [instance]);

  useEffect(() => {
    if (state.step === AuthStep.Authenticated) {
      acquireTokenForAccount(state.account)
        .then(tokenResponse => dispatch({ type: AuthAction.TokenReceived, account: state.account, tokenResponse }));
    }
  }, [state, acquireTokenForAccount]);

  useEffect(() => {
    if (justSignedIn()) {
      dispatch({ type: AuthAction.SignedIn, account: accounts[0] as AccountInfo });
    }
  }, [justSignedIn, accounts])

  const isKaEmployee = (tokenResponse: AuthenticationResult) => (tokenResponse.idTokenClaims as any).tfp === "B2C_1_Kahler_Employee";

  const fetchHeaders = async (account: AccountInfo) => {
    const token = await acquireTokenForAccount(account).then(r => r.accessToken);
    return new Headers({ 'Authorization': `Bearer ${token}` });
  }

  const fetchKaEmployeeHeaders = async (account: AccountInfo) => {
    if (account!.idTokenClaims!.exp! <= (Date.now() / 1000)) {
      logout(account);
    }

    return fetchHeaders(account);
  }

  const logout = (account: AccountInfo) => instance.logout({
    account: account,
    postLogoutRedirectUri: window.location.pathname,
  })

  useEffect(() => {
    if (state.step === AuthStep.AcquireUserProfile) {
      if (isKaEmployee(state.tokenResponse)) {
        const headers = new Headers({ 'Authorization': `Bearer ${state.tokenResponse.accessToken}` });
        fetch('/employeeApi/users/me', { headers })
          .then(response => response.json())
          .then(userInfo =>
            dispatch({
              type: AuthAction.EmployeeProfileReceived,
              employee: {
                id: state.tokenResponse.uniqueId,
                email: (state.tokenResponse.idTokenClaims as any).emails[0],
                userType: LoginType.KaEmployee,
                fetchHeaders: () => fetchKaEmployeeHeaders(state.account),
                signOut: () => logout(state.account),
                canEditBusinesses: userInfo.canEditBusinesses,
                canViewNotifications: userInfo.canViewNotifications,
                canViewSiteStatus: userInfo.canViewSiteStatus,
                canViewOrders: userInfo.canViewOrders,
                businesses: userInfo.businesses
                  .map((b: any) => ({ id: b.id, name: b.name })),
                selectedBusiness: initialSelectedEmployeeBusiness(userInfo.businesses),
                setSelectedBusiness: setSelectedEmployeeBusiness
              },
            })
          );
      } else {
        const headers = new Headers({ 'Authorization': `Bearer ${state.tokenResponse.accessToken}` });
        fetch('/api/users/me', { headers })
          .then(response => response.json())
          .then(userInfo =>
            dispatch({
              type: AuthAction.UserProfileReceived,
              userProfile: {
                id: userInfo.id,
                userType: LoginType.User,
                isDevelopment: userInfo.isDevelopment,
                email: userInfo.emailAddress,
                fetchHeaders: () => fetchHeaders(state.account),
                signOut: () => logout(state.account),
                businesses: userInfo.businesses
                  .map((b: any) => ({
                    id: b.id,
                    name: b.name,
                    administrator: b.administrator,
                    siteAllowance: b.siteAllowance,
                    numberOfSites: b.numberOfSites,
                    disabled: b.disabled,
                    sandbox: b.sandbox,
                  } as UserBusiness)),
                selectedBusiness: initialSelectedBusiness(userInfo.businesses),
                setSelectedBusiness: setSelectedUserBusiness
              }
            })
          );
      }
    }
  }, [state, acquireTokenForAccount])

  const initialSelectedBusiness = (businesses: UserBusiness[]): UserBusiness | null => {
    if (businesses.length > 0) {
      let sortedBusinesses = businesses.sort((a, b) => Number(a.sandbox) - Number(b.sandbox) || a.name.localeCompare(b.name));

      return sortedBusinesses[0];
    }

    return null;
  }

  const initialSelectedEmployeeBusiness = (businesses: Business[]): Business | null => {
    if (businesses.length > 0) {
      let sortedBusinesses = businesses.sort((a, b) => a.name.localeCompare(b.name));

      return sortedBusinesses[0];
    }

    return null;
  }

  function setSelectedUserBusiness (business: UserBusiness | null) {
    dispatch({
      type: AuthAction.UserBusinessSelected,
      selectedBusiness: business
    });
  }

  function setSelectedEmployeeBusiness(business: Business | null) {
    dispatch({
      type: AuthAction.EmployeeBusinessSelected,
      selectedBusiness: business
    });
  }

  function toUserStatus(state: State): UserStatus {
    const defaultStatus = {
      busy: inProgress !== InteractionStatus.None,
      user: null,
      login: () => instance.loginRedirect()
    }

    switch (state.step) {
      case AuthStep.UserDone:
        return { ...defaultStatus, user: state.user };

      case AuthStep.KaEmployeeDone:
        return {
          ...defaultStatus,
          user: state.employee
        };

      case AuthStep.Authenticated:
      case AuthStep.AcquireUserProfile:
        return { ...defaultStatus, busy: true };

      case AuthStep.NotAuthenticated:
        return { ...defaultStatus, busy: defaultStatus.busy || justSignedIn() }

      case AuthStep.Error:
      default:
        return defaultStatus;
    }
  }

  return (
    <UserContext.Provider value={toUserStatus(state)}>
      {children}
    </UserContext.Provider>
  );
};
