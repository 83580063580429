import React, { useState } from "react";
import { Path, useForm } from "react-hook-form";
import DriverDto from "../Common/dtos/DriverDto";
import DriverUpsertDto from "../Common/dtos/DriverUpsertDto";
import { RowFormText } from "../Common/Forms/FormText";
import KaAlert from "../Common/KaAlert";
import KaModal from "../Common/KaModal";
import SpinnerButton from "../Common/SpinnerButton";
import useDisplayMessage from "../Common/useDisplayMessage";
import { HttpMethod } from "../Common/useFetch";
import useUserRequest, { UserRequestSpec } from "../Common/useUserRequest";
import { UserProfile } from "../UserAccess/UserContext";

interface CreateEditDriverProps {
    user: UserProfile;
    initialDriver: DriverDto | null;
    onDismiss: () => void;
    onUpsert: (driverId: string, driverName: string, assignSites: boolean) => void;
}

const path = (businessId?: string) => `/api/businesses/${businessId}/drivers`;

const createSpec = (businessId?: string): UserRequestSpec => ({
    path: path(businessId), method: HttpMethod.POST,
});

const updateSpec = (businessId?: string, driverId?: string): UserRequestSpec => ({
    path: `${path(businessId)}/${driverId}`, method: HttpMethod.PUT,
});


const newDriver = (): DriverDto => ({
  id: '', name: '', number: '', password: '', addressStreet: '',
  addressCity: '', addressState: '', addressPostalCode: '',
  addressCountry: '', phone: '', email: ''
});

export const CreateEditDriver = (props: CreateEditDriverProps) => {
    const businessId = props.user.selectedBusiness?.id;
    const displayMessage = useDisplayMessage();
    const { id, ...defaultValues } = props.initialDriver ? props.initialDriver : newDriver();
    const requestSpec = props.initialDriver ? updateSpec(businessId, id) : createSpec(businessId);
    const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm<DriverUpsertDto>({
        defaultValues
    });
    const [assignSites, setAssignSites] = useState(false);

    const dismissed = () => {
        props.onDismiss();
        displayMessage.clear();
    };

    const upsert = useUserRequest<DriverUpsertDto, string>(requestSpec, {
        onSuccess: (response) => {
            dismissed();
            const driverId = props.initialDriver ? props.initialDriver.id : response;
            props.onUpsert(driverId, getValues().name, assignSites);
        },
        onError: (e) => displayMessage.fail(e.message),
    });

    const formProps = (name: Path<DriverUpsertDto>) => ({
        labelSpace: 4,
        inputSpace: 7,
        name: name,
        setValue,
        register,
        errors,
    });

    const saveAndAssignClicked = (driverUpsert: DriverUpsertDto) => {
      setAssignSites(true);
      upsert.request(driverUpsert);
    }

    return (<KaModal
        title={`${props.initialDriver ? 'Update' : 'Create'} a driver`} onHide={dismissed} show={true}
        body={(<>
            <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
            <RowFormText {...formProps('name')}
                options={{
                    required: 'Name is required',
                    validate: { length: v => v.length < 51 || 'Name cannot be longer than 50 characters' }
                }} />
            <RowFormText {...formProps('number')}
                options={{
                    validate: { length: v => v.length < 51 || 'Number cannot be longer than 50 characters' }
                }} />

            <RowFormText {...formProps('password')}
                options={{
                    validate: { length: v => v.length < 51 || 'Password cannot be longer than 50 characters' }
                }} />
            <RowFormText {...formProps("addressStreet")}
              options={{
                validate: {
                  length: v => v.length < 51 || 'Address Street cannot be longer than 50 characters' }
              }} />
            <RowFormText {...formProps("addressCity")}
              options={{
                validate: {
                  length: v => v.length < 51 || 'Address City cannot be longer than 50 characters' }
              }} />
            <RowFormText {...formProps("addressState")}
              options={{
                validate: {
                  length: v => v.length < 51 || 'Address State cannot be longer than 50 characters' }
              }} />
            <RowFormText {...formProps("addressPostalCode")}
              options={{
                validate: {
                  length: v => v.length < 16 || 'Address Postal Code cannot be longer than 15 characters' }
              }} />
            <RowFormText {...formProps("addressCountry")}
              options={{
                validate: {
                  length: v => v.length < 101 || 'Address Country cannot be longer than 100 characters' }
              }} />
            <RowFormText {...formProps('phone')}
                options={{
                    validate: { length: v => v.length < 51 || 'Phone cannot be longer than 50 characters' }
                }} />
            <RowFormText {...formProps("email")}
                options={{
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Email Address is invalid' 
                  }
                }} />
        </>)}
        footer={(
            <div className="btn-group">
              <SpinnerButton 
                className="w-25 m-1 rounded btn-ghost-primary"
                spinning={upsert.isLoading && !upsert.isError} 
                onClick={handleSubmit(upsert.request)}>
                  {`${props.initialDriver ? 'Update' : 'Create'}`}
              </SpinnerButton>
              <SpinnerButton 
                className="w-25 m-1 rounded btn-ghost-primary"
                spinning={upsert.isLoading && !upsert.isError} 
                onClick={handleSubmit(saveAndAssignClicked)}>
                  {`${props.initialDriver ? 'Update and Assign' : 'Create and Assign'}`}
              </SpinnerButton>
            </div>)
        } />);
};
