import { QueryOptions } from "../Common/useQuery";
import useUserQuery from "../Common/useUserQuery";
import { useUser } from "../UserAccess/useUser";

export interface ProductsSiteAssignmentDto {
	assignments: ProductSiteAssignmentDto[];
}

export interface ProductSiteAssignmentDto {
	productId: string;
	productName: string;
	assigned: boolean;
}

const useGetProductsSiteAssignment = (siteId: string, options?: QueryOptions<ProductsSiteAssignmentDto>) => { 
  const user = useUser();

  return useUserQuery<ProductsSiteAssignmentDto>(
    { path: `/api/businesses/${user.selectedBusiness?.id!}/sites/${siteId}/productsAssignment` },
    options);
}

export default useGetProductsSiteAssignment;
