import React from 'react';
import KaAlert from '../Common/KaAlert';
import useDisplayMessage from '../Common/useDisplayMessage';
import useUserQuery, { UserQuerySpec } from '../Common/useUserQuery';
import { useUser } from '../UserAccess/useUser';
import BusinessAdminTemplate from '../UserAccess/BusinessAdminTemplate';
import { HttpMethod } from '../Common/useFetch';
import useUserRequest, { UserRequestSpec } from '../Common/useUserRequest';
import SpinnerButton from '../Common/SpinnerButton';
import { useForm } from 'react-hook-form';
import { UserProfile } from '../UserAccess/UserContext';
import QueryView from '../Common/QueryView';

const getSpec = (businessId?: string): UserQuerySpec => ({
  path: `/api/businesses/${businessId}/orderSettings`,
});

const putSpec = (businessId?: string): UserRequestSpec => ({
  path: `/api/businesses/${businessId}/orderSettings`,
  method: HttpMethod.PUT,
});

export enum OrderCompletionType {
  Manual = 'Manual',
  Percentage = 'Percentage'
}

interface OrderCompletionDto {
  type: OrderCompletionType;
  percentage?: number;
}

interface OrderCompletionForm {
  percentageEnabled: boolean;
  percentage: number;
}

const OrderSettingsPage = () => {
  const user = useUser();
  const getOrderSettings = useUserQuery<OrderCompletionDto>(getSpec(user.selectedBusiness?.id));

  return <BusinessAdminTemplate>
    <QueryView
      query={getOrderSettings}
      renderData={settings =>
        <Form
          orderSettings={settings}
          user={user} />
      } />
  </BusinessAdminTemplate>
}

interface FormProps {
  orderSettings: OrderCompletionDto,
  user: UserProfile
}

const Form = (props: FormProps) => {
  const displayMessage = useDisplayMessage();

  const { register, handleSubmit, watch, getValues, formState: { errors } } = useForm<OrderCompletionForm>({
    defaultValues: {
      percentageEnabled: props.orderSettings.type == OrderCompletionType.Percentage,
      percentage: props.orderSettings.percentage
    }
  });

  const handleUpdateOrderCompletion = (formValues: OrderCompletionForm) => {
    useUpdateOrderCompletion.request({
      type: formValues.percentageEnabled
        ? OrderCompletionType.Percentage
        : OrderCompletionType.Manual,
      percentage: formValues.percentageEnabled
        ? formValues.percentage
        : undefined,
    })
  }

  const useUpdateOrderCompletion = useUserRequest<OrderCompletionDto>(putSpec(props.user.selectedBusiness?.id), {
    onSuccess: () => displayMessage.success("Order Completion settings have been updated."),
    onError: (e) => displayMessage.fail(e.message),
  });

  return (<>
    <h3 className="ka-blue">Order Settings</h3>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <div className="row border m-2 p-2">
      <div className="col">
        <div className="row">
          <label className="col" htmlFor='orderCompletionPercentageInput'>
            Order Completion Percentage
          </label>
          <div className="col form-check form-switch">
            <input
              type="checkbox"
              className="form-check-input"
              {...register('percentageEnabled')}
            />
          </div>
        </div>
      </div>
      <div className="col">
        <div className="input-group">
          <input
            id='orderCompletionPercentageInput'
            type="number"
            disabled={!watch('percentageEnabled')}
            className={`form-control ${!!errors.percentage && 'is-invalid'}`}
            {...register('percentage', {
              valueAsNumber: true,
              required: 'Order Completion Percentage required',
              validate: {
                positive: v => getValues('percentageEnabled')
                  ? v > 0 || 'Must be greater than 0'
                  : true
              },
            })}
          />
          <div className="input-group-append input-group-text">
            %
          </div>
          <span className="invalid-feedback">{errors.percentage?.message}</span>
        </div>
      </div>
    </div>
    <div className="float-end">
      <SpinnerButton
        className="btn-ghost-primary"
        spinning={useUpdateOrderCompletion.isLoading}
        onClick={handleSubmit(handleUpdateOrderCompletion)} >
        Save
      </SpinnerButton>
    </div>
  </>);
}

export { OrderSettingsPage };
