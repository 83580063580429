import React from 'react';
import KaAlert from '../Common/KaAlert'
import SpinnerButton from '../Common/SpinnerButton';
import { UserProfile } from '../UserAccess/UserContext';
import useUserRequest, { UserRequestSpec } from '../Common/useUserRequest';
import { HttpMethod } from '../Common/useFetch';
import { useDisplayMessage } from '../Common/useDisplayMessage';
import KaModal from '../Common/KaModal';
import CustomerDto from '../Common/dtos/CustomerDto';
import CustomerUpsertDto from '../Common/dtos/CustomerUpsertDto';
import { Path, useForm } from 'react-hook-form';
import { RowFormText } from '../Common/Forms/FormText';

interface IProps {
  user: UserProfile,
  initialCustomer: CustomerDto | null,
  onHide: () => void,
  onSuccess: (customer: CustomerUpsertDto) => void
}

const customersPath = (businessId: string) => `/api/businesses/${businessId}/customers`;

const postSpec = (businessId: string): UserRequestSpec => ({
  path: customersPath(businessId),
  method: HttpMethod.POST
});

const putSpec = (businessId: string, customerId: string): UserRequestSpec => ({
  path: `${customersPath(businessId)}/${customerId}`,
  method: HttpMethod.PUT
});

const newCustomer = (): CustomerUpsertDto =>
({
  name: '', accountNumber: '', billingAddressStreet: '',
  billingAddressCity: '', billingAddressState: '', billingAddressPostalCode: '',
  billingAddressCountry: '', email: '', ticketNotes: ''
});

export const CreateEditCustomer = (props: IProps) => {
  const create = props.initialCustomer === null;
  let { id, ...initialCustomer } = { ...props.initialCustomer };
  const customer = create ? newCustomer() : initialCustomer;

  const businessId = props.user.selectedBusiness?.id ?? "";
  const requestSpec = create
    ? postSpec(businessId)
    : putSpec(businessId, id ?? "");

  const displayMessage = useDisplayMessage();

  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm<CustomerUpsertDto>({
    defaultValues: { ...customer }
  });

  const handleCreateUpdate = (customerUpsert: CustomerUpsertDto) => {
    useCreateEdit.request(customerUpsert);
  }

  const useCreateEdit = useUserRequest<CustomerUpsertDto>(requestSpec, {
    onSuccess: () => props.onSuccess(getValues()),
    onError: (e) => displayMessage.fail(e.message),
  });

  const hookProps = (name: Path<CustomerUpsertDto>) => ({
    labelSpace: 4,
    inputSpace: 7,
    name: name,
    setValue,
    register,
    errors,
  });

  const onClose = () => {
    props.onHide();
    displayMessage.clear();
  }

  return (<KaModal
    onHide={onClose}
    show={true}
    title={`${create ? 'Create' : 'Update'} a customer`}
    body={(<>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <RowFormText
        {...hookProps("name")}
        options={{
          required: 'Name is required',
          validate: {
            length: v => v.length < 51 || 'Name cannot be longer than 50 characters'
          }
        }}
      />
      <RowFormText
        {...hookProps("accountNumber")}
        options={{
          validate: {
            length: v => v.length < 51 || 'Account Number cannot be longer than 50 characters'
          }
        }}
      />
      <RowFormText
        {...hookProps("billingAddressStreet")}
        options={{
          validate: {
            length: v => v.length < 51 || 'Billing Address Street cannot be longer than 50 characters'
          }
        }}
      />
      <RowFormText
        {...hookProps("billingAddressCity")}
        options={{
          validate: {
            length: v => v.length < 51 || 'Billing Address City cannot be longer than 50 characters'
          }
        }}
      />
      <RowFormText
        {...hookProps("billingAddressState")}
        options={{
          validate: {
            length: v => v.length < 51 || 'Billing Address State cannot be longer than 50 characters'
          }
        }}
      />
      <RowFormText
        {...hookProps("billingAddressPostalCode")}
        options={{
          validate: {
            length: v => v.length < 16 || 'Billing Address Postal Code cannot be longer than 15 characters'
          }
        }}
      />
      <RowFormText
        {...hookProps("billingAddressCountry")}
        options={{
          validate: {
            length: v => v.length < 101 || 'Billing Address Country cannot be longer than 100 characters'
          }
        }}
      />
      <RowFormText
        {...hookProps("email")}
        options={{
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Email Address is invalid'
          }
        }}
      />
      <RowFormText
        {...hookProps("ticketNotes")}
      />
    </>)}
    footer={(
      <SpinnerButton
        className="btn-ghost-primary"
        spinning={useCreateEdit.isLoading}
        onClick={handleSubmit(handleCreateUpdate)}>
        {`${create ? 'Create' : 'Update'}`}
      </SpinnerButton>)
    }
  />);
}
