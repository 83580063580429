import React, { useState } from 'react';
import KaAlert from '../KaAlert';
import { SiteAssignment } from './SiteAssignment';
import KaModal from '../../Common/KaModal';
import SpinnerButton from '../../Common/SpinnerButton';
import { useDisplayMessage } from '../../Common/useDisplayMessage';
import { HttpMethod } from '../../Common/useFetch';
import useUserRequest, { UserRequestSpec } from '../../Common/useUserRequest';
import SiteAssignmentUpsertDto from '../dtos/SiteAssignmentUpsertDto';
import useUserQuery from '../useUserQuery';
import SiteAssignmentsDto from '../dtos/SiteAssignmentsDto';
import QueryView from '../QueryView';

export interface SiteAssignmentFormProps {
  title: string,
  getAssignedSitesPath: string,
  assignSitePath: string,
  onHide: () => void,
  onSuccess: () => void
}

export const SiteAssignmentForm = (props: SiteAssignmentFormProps) => {
  const postSpec: UserRequestSpec = {
    path: props.assignSitePath,
    method: HttpMethod.POST,
  };

  const [assignedSiteIds, setAssignedSiteIds] = useState<string[]>([]);
  const assignedSitesChangedHandler = (assignedSites: string[]) => setAssignedSiteIds(assignedSites);

  const displayMessage = useDisplayMessage();

  const useAssignSites = useUserRequest<SiteAssignmentUpsertDto>(postSpec, {
    onSuccess: () => props.onSuccess(),
    onError: (e) => displayMessage.fail(e.message)
  });

  const getAssignedSitesQuery = useUserQuery<SiteAssignmentsDto>({ path: props.getAssignedSitesPath }, {
    onSuccess: ({ assignments }) => {
      const sorted = assignments.sort((a, b) => a.siteName.localeCompare(b.siteName));
      setAssignedSiteIds(sorted.filter(a => a.assigned).map(as => as.siteId))
    }
  });

  const handleSubmit = () => {
    useAssignSites.request({ siteIds: assignedSiteIds ?? [] });
  }

  const onClose = () => {
    props.onHide();
    displayMessage.clear();
  }

  return (<KaModal
    onHide={onClose}
    show={true}
    title={props.title ?? 'Select sites'}
    body={(<>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <QueryView
        query={getAssignedSitesQuery}
        renderData={sites =>
          <SiteAssignment
            assignedSiteIds={assignedSiteIds}
            sites={sites.assignments}
            assignedSitesChanged={assignedSitesChangedHandler}
          />}
      />
    </>)}
    footer={(<SpinnerButton
      className="btn-ghost-primary"
      spinning={useAssignSites.isLoading}
      onClick={handleSubmit}
      disabled={!getAssignedSitesQuery.isSuccess}>
      Assign
    </SpinnerButton>)
    }
  />);
}
