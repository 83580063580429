import { HttpMethod } from "../components/Common/useFetch";
import { RequestOptions } from "../components/Common/useRequest";
import useUserRequest, { UserRequestSpec } from "../components/Common/useUserRequest";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";
import { ApplicatorDto } from "./useGetApplicators";

export type ApplicatorUpsertDto = Omit<ApplicatorDto, 'id'>;

const path = (businessId: string) => `/api/businesses/${businessId}/applicators`;

const createSpec = (businessId: string): UserRequestSpec => ({
  path: path(businessId),
  method: HttpMethod.POST,
});

const updateSpec = (businessId: string, applicatorId: string): UserRequestSpec => ({
  path: `${path(businessId)}/${applicatorId}`,
  method: HttpMethod.PUT,
});

const useUpsertApplicator = (applicatorId?: string, options?: RequestOptions<string>) => {
  const business = useSelectedBusiness();
  var spec = applicatorId
    ? updateSpec(business.id, applicatorId)
    : createSpec(business.id);
  return useUserRequest<ApplicatorUpsertDto, string>(spec, options);
}

export default useUpsertApplicator;