import useQuery, { Query, QueryOptions } from './useQuery';
import { useKaEmployee } from '../UserAccess/useKaEmployee';
import { UserQuerySpec } from './useUserQuery';

export default function useKaEmployeeQuery<Resp>(spec: UserQuerySpec, options?: QueryOptions<Resp>): Query<Resp> {
  const kaEmployee = useKaEmployee();
  return useQuery<Resp>({
    path: spec.path,
    headers: kaEmployee.fetchHeaders,
  }, options);
}