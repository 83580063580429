import { joinNonEmptyString } from '../../Utilities'

export function formatPostalAddress(street: string = "",
  city: string = "",
  state: string = "",
  postalCode: string = "",
  country: string = "",
  lineJoinCharacter: string = ", "): string {
  let stateZip = joinNonEmptyString([state, postalCode, country], " ");
  let cityStateZip = joinNonEmptyString([city, stateZip], ", ");
  return joinNonEmptyString([street, cityStateZip], lineJoinCharacter);
}

export default formatPostalAddress;
