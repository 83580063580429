import React from 'react';
import KaAlert from '../../Common/KaAlert'
import SpinnerButton from '../../Common/SpinnerButton';
import { UserProfile } from '../../UserAccess/UserContext';
import useUserRequest, { UserRequestSpec } from '../../Common/useUserRequest';
import { HttpMethod } from '../../Common/useFetch';
import { useDisplayMessage } from '../../Common/useDisplayMessage';
import KaModal from '../../Common/KaModal';
import DestinationDto from '../../Common/dtos/DestinationDto';
import DestinationUpsertDto from '../../Common/dtos/DestinationUpsertDto';
import CustomerUpsertDto from '../../Common/dtos/CustomerUpsertDto';
import { Path, useForm } from 'react-hook-form';
import { RowFormText } from '../../Common/Forms/FormText';

interface IProps {
  user: UserProfile,
  customerId: string,
  initialDestination: DestinationDto | null,
  onHide: () => void,
  onSuccess: (destination: DestinationUpsertDto) => void
}

const destinationsPath = (businessId?: string, customerId?: string) =>
    `/api/businesses/${businessId}/customers/${customerId}/destinations`;

const postSpec = (businessId?: string, customerId?: string): UserRequestSpec => ({
  path: destinationsPath(businessId, customerId),
  method: HttpMethod.POST,
});

const putSpec = (businessId?: string, customerId?: string, destinationId?: string): UserRequestSpec => ({
  path: `${destinationsPath(businessId, customerId)}/${destinationId}`,
  method: HttpMethod.PUT,
});

const newDestination = (): DestinationUpsertDto => ({
  name: '', shippingAddressStreet: '', shippingAddressCity: '',
  shippingAddressState: '', shippingAddressPostalCode: '', shippingAddressCountry: ''
});

export const CreateEditDestination = (props: IProps) => {
  const create = props.initialDestination === null;
  let { id, ...initialDestination } = { ...props.initialDestination };
  const destination = create ? newDestination() : initialDestination;

  const businessId = props.user.selectedBusiness?.id;
  const requestSpec = create
    ? postSpec(businessId, props.customerId)
    : putSpec(businessId, props.customerId, id ?? "");

  const displayMessage = useDisplayMessage();

  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm<DestinationUpsertDto>({
    defaultValues: { ...destination }
  });

  const handleCreateUpdate = (destinationUpsert: DestinationUpsertDto) => {
    useCreateEdit.request(destinationUpsert);
  }

  const useCreateEdit = useUserRequest<CustomerUpsertDto>(requestSpec, {
    onSuccess: () => props.onSuccess(getValues()),
    onError: (e) => displayMessage.fail(e.message),
  });

  const hookProps = (name: Path<DestinationUpsertDto>) => ({
    labelSpace: 4,
    inputSpace: 7,
    name: name,
    setValue,
    register,
    errors,
  });

  const onClose = () => {
    props.onHide();
    displayMessage.clear();
  }

  return (<KaModal
    onHide={onClose}
    show={true}
    title={`${create ? 'Create' : 'Update'} a destination`}
    body={(<>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <RowFormText
        {...hookProps("name")}
        options={{
          required: 'Name is required',
          validate: {
            length: v => v.length < 51 || 'Name cannot be longer than 50 characters'
          }
        }}
      />
      <RowFormText
        {...hookProps("shippingAddressStreet")}
        options={{
          validate: {
            length: v => v.length < 51 || 'Shipping Address Street cannot be longer than 50 characters'
          }
        }}
      />
      <RowFormText
        {...hookProps("shippingAddressCity")}
        options={{
          validate: {
            length: v => v.length < 51 || 'Shipping Address City cannot be longer than 50 characters'
          }
        }}
      />
      <RowFormText
        {...hookProps("shippingAddressState")}
        options={{
          validate: {
            length: v => v.length < 51 || 'Shipping Address State cannot be longer than 50 characters'
          }
        }}
      />
      <RowFormText
        {...hookProps("shippingAddressPostalCode")}
        options={{
          validate: {
            length: v => v.length < 16 || 'Shipping Address Postal Code cannot be longer than 15 characters'
          }
        }}
      />
      <RowFormText
        {...hookProps("shippingAddressCountry")}
        options={{
          validate: {
            length: v => v.length < 101 || 'Shipping Address Country cannot be longer than 100 characters'
          }
        }}
      />
    </>)}
    footer={(
      <SpinnerButton
        className='btn-ghost-primary'
        spinning={useCreateEdit.isLoading}
        onClick={handleSubmit(handleCreateUpdate)}>
        {`${create ? 'Create' : 'Update'}`}
      </SpinnerButton>)
    }
  />);
}
