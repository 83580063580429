import { QueryOptions } from "../Common/useQuery";
import useUserQuery from "../Common/useUserQuery";
import { useUser } from "../UserAccess/useUser";

export type ProductLoadedResponse = {
  id: string,
  productName: string,
  loadedAmountHour?: number,
  loadedAmountDay?: number,
  loadedAmountWeek?: number,
  loadedAmountMonth?: number,
  error?: string,
  startOfHour?: string,
  startOfDay?: string,
  startOfWeek?: string,
  startOfMonth?: string,
}

export type PathParams = {
  unit: string,
  timeZoneOffset: number,
  product?: string,
  siteId?: string,
}

const useGetProductLoaded = (params: PathParams, options?: QueryOptions<ProductLoadedResponse>) => {
  const user = useUser();
  const baseUrl = `/api/businesses/${user.selectedBusiness?.id!}/productLoaded/${params.product}`;
  const urlParams = new URLSearchParams();

  urlParams.append('unit', params.unit);
  urlParams.append('timeZoneOffset', params.timeZoneOffset.toString());

  if (params.siteId) urlParams.append('siteId', params.siteId);

  return useUserQuery<ProductLoadedResponse>(
    { path: `${baseUrl}?${urlParams.toString()}` },
    options);
}

export default useGetProductLoaded;
