import { useContext } from 'react';
import { KaEmployeeProfile, UserContext, LoginType } from './UserContext';

export const useKaEmployee = () => {
  const { user } = useContext(UserContext);

  if (user?.userType === LoginType.KaEmployee) {
    return user as KaEmployeeProfile
  }

  throw new Error("useKaEmployee hook can only be used inside of components contained inside an KaEmployeeTemplate component");
}