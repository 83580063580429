import React, { useState } from "react";
import { Container, Row, Table } from "reactstrap";
import useGetApplicators, { ApplicatorDto } from "../../requests/useGetApplicators";
import { useUser } from "../UserAccess/useUser";
import useDisplayMessage from "../Common/useDisplayMessage";
import FilterInput, { textSatisfiesFilter } from "../FilterInput";
import useRemoveApplicator from "../../requests/useRemoveApplicator";
import { CenteredSpinner } from "../Common/CenteredSpinner";
import BoldButton from "../Common/BoldButton";
import KaAlert from "../Common/KaAlert";
import { Button } from "react-bootstrap"; 
import { CreateEditApplicator } from "./CreateEditApplicators";
import { SiteAssignmentForm } from "../Common/SiteAssignment/SiteAssignmentForm";
import Confirm from "../Common/Confirm";

enum States {
  None,
  AddEditApplicator,
  AssignSites
}

type PopupState =
  { state: States.None; } |
  {
    state: States.AddEditApplicator;
    applicator: ApplicatorDto | null;
  } |
  {
    state: States.AssignSites;
    applicator: ApplicatorDto;
  };

const apiPath = (businessId?: string): string => `/api/businesses/${businessId}/applicators`;

const apiGetSiteAssignmentPath = (businessId?: string, applicatorId?: string): string =>
  `${apiPath(businessId)}/${applicatorId}/siteAssignment`;

const apiSetSiteAssignmentPath = (businessId?: string, applicatorId?: string): string =>
  `${apiPath(businessId)}/${applicatorId}/assignToSites`;

const alphabeticallyByName = (a: ApplicatorDto, b: ApplicatorDto) => a.name.localeCompare(b.name);

const ApplicatorPage = () => {
  const user = useUser();
  const displayMessage = useDisplayMessage();
  const [assignSitesForApplicatorId, setAssignSitesForApplicatorId] = useState<string | undefined>();
  const [filterText, setFilterText] = useState('');
  const [popupState, setPopupState] = useState<PopupState>({ state: States.None });
  const [applicatorToRemove, setApplicatorToRemove] = useState<ApplicatorDto | undefined>(undefined);

  const getApplicator = useGetApplicators({
    onSuccess: data => {
      if (assignSitesForApplicatorId && data) {
        const applicatorToAssign = data.find(r => r.id === assignSitesForApplicatorId);

        applicatorToAssign
          ? assignSitesClick(applicatorToAssign)
          : displayMessage.fail(
            "Could not assign sites because the applicator was not found")

        setAssignSitesForApplicatorId(undefined);
      }
    }
  });

  const containsFilterText = (applicator: ApplicatorDto) => {
    const satisfiesFilter = textSatisfiesFilter(filterText);
    return satisfiesFilter(applicator.name)
      || satisfiesFilter(applicator.license)
      || satisfiesFilter(applicator.epaNumber);
  };

  const filterTextChanged = (text: string) => setFilterText(text);

  const removeSuccess = () => {
    displayMessage.success(`Applicator ${applicatorToRemove?.name} removed`);
    return getApplicator.query();
  };

  const removeRequest = useRemoveApplicator(applicatorToRemove?.id ?? '', {
    onSuccess: removeSuccess,
    onError: (err) => displayMessage.fail(err.message),
    onComplete: () => setApplicatorToRemove(undefined),
  });

  const createClicked = () => {
    displayMessage.clear();
    setPopupState({ state: States.AddEditApplicator, applicator: null });
  }

  const editClicked = (applicator: ApplicatorDto) => {
    displayMessage.clear();
    setPopupState({ state: States.AddEditApplicator, applicator: applicator });
  }

  const applicatorUpserted = (applicatorId: string, applicatorName: string, assignSites: boolean) => {
    if (popupState.state !== States.AddEditApplicator) return;

    displayMessage.success(`Applicator ${applicatorName} ${popupState.applicator ? 'updated' : 'created'}`);
    if (assignSites) setAssignSitesForApplicatorId(applicatorId);

    setPopupState({ state: States.None });
    getApplicator.query();
  }

  const editDismissed = () => setPopupState({ state: States.None });

  const assignSitesClick = (applicator: ApplicatorDto) => {
    displayMessage.clear();
    setPopupState({ state: States.AssignSites, applicator });
  }

  const assignSitesSuccess = () => {
    if (popupState.state === States.AssignSites) {
      displayMessage.success(`Sites successfully updated for ${popupState.applicator?.name}`);
    }

    assignSitesClosed();
  }

  const assignSitesClosed = () => setPopupState({ state: States.None });

  if (getApplicator.isError) return <h3>{getApplicator.error?.message}</h3>
  if (getApplicator.isLoading || removeRequest.isLoading) return <CenteredSpinner />

  return <Container>
    {popupState.state === States.AddEditApplicator &&
      <CreateEditApplicator
        initialApplicator={popupState.applicator}
        onUpsert={applicatorUpserted}
        onDismiss={editDismissed} />}
    {popupState.state === States.AssignSites &&
      <SiteAssignmentForm
        title={`Select sites for ${popupState.applicator.name}`}
        getAssignedSitesPath={apiGetSiteAssignmentPath(user.selectedBusiness?.id, popupState.applicator?.id)}
        assignSitePath={apiSetSiteAssignmentPath(user.selectedBusiness?.id, popupState.applicator?.id)}
        onHide={assignSitesClosed}
        onSuccess={assignSitesSuccess} />
    }
    <Confirm
      visible={!!applicatorToRemove}
      title='Remove applicator'
      body={`Are you sure that you want to remove applicator ${applicatorToRemove?.name}?`}
      onDismiss={() => setApplicatorToRemove(undefined)}
      onConfirm={() => removeRequest.request()} />
    <Row className='justify-content-between mb-2'>
      <h2 className='col-auto ka-blue'>Applicators</h2>
      <BoldButton className='col-auto btn-ghost-primary' onClick={createClicked}>Create Applicator</BoldButton>
    </Row>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <form>
      <div className='row gx-2 gx-md-3 mb-7'>
        <div className='col-md-4 mb-2 mb-md-0'>
          <FilterInput placeholder='Filter applicators by keyword' onFilterTextChange={filterTextChanged} />
        </div>
      </div>
    </form>
    <Table bordered style={{ tableLayout: "fixed" }}>
      <thead>
        <tr>
          <th className="col-auto">Name</th>
          <th className="col">License</th>
          <th className="col">EPA Number</th>
          <th className="col-auto">Email</th>
          <th className="col"></th>
        </tr>
      </thead>
      <tbody>
        {getApplicator.data?.filter(containsFilterText).sort(alphabeticallyByName).map(applicator =>
          <tr key={applicator.id}>
            <td>{applicator.name}</td>
            <td>{applicator.license}</td>
            <td>{applicator.epaNumber}</td>
            <td>{applicator.email}</td>
            <td align="right">
              <div className='dropdown'>
                <Button id='actionsMenuButton'
                  className='dropdown-toggle btn-ghost-secondary btn-link'
                  variant='link'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'>
                  <strong>Actions</strong>
                </Button>
                <div className='dropdown-menu' aria-labelledby='actionsMenuButton'>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => editClicked(applicator)}>Edit</BoldButton>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => assignSitesClick(applicator)}>Assign sites</BoldButton>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => setApplicatorToRemove(applicator)}>Remove</BoldButton>
                </div>
              </div>
            </td>
          </tr>)}
      </tbody>
    </Table>
  </Container>
};
export { ApplicatorPage };