import { HttpMethod } from "../Common/useFetch";
import { RequestOptions } from "../Common/useRequest";
import useUserRequest from "../Common/useUserRequest";
import { useUser } from "../UserAccess/useUser";

export interface AssignmentGroupsDto {
	assignmentGroups: AssignmentsDto[];
}

export interface AssignmentsDto {
	siteId: string;
	productIds: string[];
}

const useAssignProductsToSites = (options?: RequestOptions) => { 
  const user = useUser();

  return useUserRequest<AssignmentGroupsDto>({
    method: HttpMethod.POST,
    path: `/api/businesses/${user.selectedBusiness?.id!}/products/assignToSites` },
    options);
}

export default useAssignProductsToSites;
