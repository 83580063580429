import { FieldError, FieldErrors, FieldValues, Path } from 'react-hook-form';

interface FormErrorProps<FormType extends FieldValues> {
  name: Path<FormType>,
  errors?: FieldErrors<FormType>,
}

interface FormErrorReturn {
  inError: boolean,
  errorMessage: string,
}

export function useFormError<FormType extends FieldValues>(props: FormErrorProps<FormType>): FormErrorReturn {
    if (props.errors) {
      let errorObj = props.errors as any;
      const keys = props.name.split('.');
      for (let key of keys) {
          if (errorObj)
            errorObj = errorObj[key] as FieldError;
          else break;
      }
      const error = errorObj;
      if (error) {
        return { inError: true, errorMessage: error.message ?? '' }
      }
    }

  return { inError: false, errorMessage: '' };
}
