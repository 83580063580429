import React from 'react'

export interface UserStatus {
  busy: boolean;
  login: () => void;
  user: (UserProfile | KaEmployeeProfile | null);
}

export enum LoginType {
  KaEmployee,
  User
}

export interface Profile {
  id: string;
  userType: LoginType.User | LoginType.KaEmployee;
  fetchHeaders: () => Promise<Headers>;
  signOut: () => void;
  email: string;
  businesses: Business[];
  selectedBusiness: Business | null;
}

export interface KaEmployeeProfile extends Profile {
  userType: LoginType.KaEmployee;
  canEditBusinesses: boolean;
  canViewNotifications: boolean;
  canViewSiteStatus: boolean;
  canViewOrders: boolean;
  setSelectedBusiness: (business: Business | null) => void;
}

export interface UserProfile extends Profile {
  userType: LoginType.User;
  isDevelopment: boolean;
  businesses: UserBusiness[];
  selectedBusiness: UserBusiness | null;
  setSelectedBusiness: (business: UserBusiness | null) => void;
}

export interface Business {
  id: string;
  name: string;
}

export interface UserBusiness extends Business {
  administrator: boolean;
  siteAllowance: number;
  numberOfSites: number;
  disabled: boolean;
  sandbox: boolean;
}

export const UserContext = React.createContext<UserStatus>({ busy: false, user: null, login: () => { } });
