import React, { useEffect, useState } from 'react';
import KaAlert from '../Common/KaAlert';
import { useUser } from '../UserAccess/useUser';
import { Row, Table, Container, Nav, NavItem, NavLink, } from 'reactstrap';
import { CenteredSpinner } from '../Common/CenteredSpinner';
import useUserRequest, { UserRequestSpec } from '../Common/useUserRequest';
import useDisplayMessage from '../Common/useDisplayMessage';
import OrderDto, { OrderDisplayDto } from '../Common/dtos/OrderDto'
import { CreateEditOrder, CreateEditMode } from './CreateEditOrder'
import { useLogException } from '../Monitoring/useMonitoring';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import FilterInput, { textSatisfiesFilter } from '../FilterInput';
import Confirm from '../Common/Confirm';
import { HttpMethod } from '../Common/useFetch';
import { SiteAssignmentForm } from '../Common/SiteAssignment/SiteAssignmentForm';
import { customerDisplayName } from '../../Utilities';
import BoldButton from '../Common/BoldButton';
import { Button } from 'react-bootstrap';
import { OrderTransactionPage } from './Transactions/OrderTransactionPage';
import { useParams } from 'react-router';
import useGetOrders from '../Requests/useGetOrders';
import OverflowTooltip from '../Common/OverflowTooltip';
import QueryView from '../Common/QueryView';

const apiPath = (businessId?: string): string => `/api/businesses/${businessId}/orders`;

const completeRequestSpec = (businessId?: string, orderId?: string): UserRequestSpec => ({
  path: `/api/businesses/${businessId}/orders/${orderId}/complete`,
  method: HttpMethod.POST,
});

const removeRequestSpec = (businessId?: string, orderId?: string): UserRequestSpec => ({
  path: `/api/businesses/${businessId}/orders/${orderId}`,
  method: HttpMethod.DELETE,
});

const apiGetSiteAssignmentPath = (businessId?: string, orderId?: string): string =>
  `${apiPath(businessId)}/${orderId}/siteAssignment`;

const apiSetSiteAssignmentPath = (businessId?: string, orderId?: string): string =>
  `${apiPath(businessId)}/${orderId}/assignToSites`;

const OrderPage = () => {
  const user = useUser();
  const [viewCompletedOrders, setViewCompletedOrders] = useState(false);
  const [assignSitesForOrderId, setAssignSitesForOrderId] = useState<string | undefined>();
  const getOrders = useGetOrders({ completed: viewCompletedOrders }, {
    onSuccess: (orders) => {
      if (!assignSitesForOrderId || !orders) return;

      const assignSitesForOrder = orders.find(n => n.id === assignSitesForOrderId);
      assignSitesForOrder
        ? assignSitesClick(assignSitesForOrder)
        : displayMessage.fail("Could not assign sites because the order was not found");

      setAssignSitesForOrderId(undefined);
    }
  });
  const [filterText, setFilterText] = useState('');
  const [confirmOrderCompletion, setConfirmOrderCompletion] = useState(false);
  const [orderToComplete, setOrderToComplete] = useState<OrderDisplayDto | undefined>(undefined);
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [orderToRemove, setOrderToRemove] = useState<OrderDisplayDto | undefined>(undefined);
  const [showSiteAssignmentForm, setShowSiteAssignmentForm] = useState(false);
  const [orderToAssign, setOrderToAssign] = useState<OrderDisplayDto | undefined>(undefined);
  const displayMessage = useDisplayMessage();
  const logException = useLogException();
  const { orderNumber: initialOrderNumber, completed: initialCompleted } = useParams();

  const [createEditMode, setCreateEditMode] = useState<CreateEditMode | null>(null);

  const [showOrderTransactions, setShowOrderTransactions] = useState(false);
  const [orderForOrderTransactions, setOrderForOrderTransactions] = useState<OrderDisplayDto | undefined>(undefined);

  useEffect(() => {
    if (initialOrderNumber) {
      filterTextChanged(initialOrderNumber);
    }

    if (initialCompleted === 'completed') {
      setViewCompletedOrders(true);
    }
  }, [initialOrderNumber, initialCompleted]);

  const filterTextChanged = (text: string) => setFilterText(text);

  const containsFilterText = (order: OrderDisplayDto) => {
    const satisfiesFilter = textSatisfiesFilter(filterText);
    return satisfiesFilter(order.number) || satisfiesFilter(order.purchaseOrder) || satisfiesFilter(order.customerName)
      || satisfiesFilter(getOrderItems(order).join(", ")) || satisfiesFilter(order.siteNames.join(", "))
      || satisfiesFilter(viewCompletedOrders ? order.completedAt : order.createdAt);
  };

  const createEditSuccess = (orderId: string, orderNumber: string, assignSites: boolean) => {
    displayMessage.success(`Order ${orderNumber} ${createEditMode?.create ? 'created' : 'updated'}`);
    editingOrderCompleted();
    if (assignSites) setAssignSitesForOrderId(orderId);
    getOrders.query();
  }

  const editingOrderCompleted = () => {
    setCreateEditMode(null);
  }

  const completeRequest = useUserRequest(
    completeRequestSpec(user.selectedBusiness?.id, orderToComplete?.id), {
    onSuccess: () => getOrders.query(),
    onError: (err) => displayMessage.fail(err.message),
    onComplete: () => setConfirmOrderCompletion(false),
  });

  const removeSuccess = () => {
    displayMessage.success(`Order ${orderToRemove?.number} removed`);
    return getOrders.query();
  };

  const removeRequest = useUserRequest(
    removeRequestSpec(user.selectedBusiness?.id, orderToRemove?.id), {
    onSuccess: removeSuccess,
    onError: (err) => displayMessage.fail(err.message),
    onComplete: () => setShowConfirmRemove(false),
  });

  const createClick = () => {
    displayMessage.clear();
    setCreateEditMode({ create: true });
  }

  const isComplete = (order: OrderDto) => order.stage === 'Complete';

  const isReadOnly = (order: OrderDto) => order.hasTransactions || isComplete(order);

  const getOrderItems = (order: OrderDto) => order.blends.flatMap(b => b.items.map(i => i.name))

  const editClick = (order: OrderDisplayDto) => {
    displayMessage.clear();
    setCreateEditMode({ create: false, initialOrder: order });
  }

  const assignSitesClick = (order: OrderDisplayDto) => {
    displayMessage.clear();
    setOrderToAssign(order);
    setShowSiteAssignmentForm(true);
  }

  const assignSitesSuccess = () => {
    displayMessage.success(`Assigned ${orderToAssign?.number} to the selected sites`);
    assignSitesClosed();
    getOrders.query();
  }

  const assignSitesClosed = () => {
    setShowSiteAssignmentForm(false);
    setOrderToAssign(undefined);
  }

  const complete = (order: OrderDisplayDto) => {
    setOrderToComplete(order);
    setConfirmOrderCompletion(true);
  };

  const confirmOrderCompletionDismissed = () => setConfirmOrderCompletion(false);

  const orderCompletionConfirmed = () => completeRequest.request(null);

  const remove = (order: OrderDisplayDto) => {
    setOrderToRemove(order);
    setShowConfirmRemove(true);
  };

  const dismissConfirmRemove = () => setShowConfirmRemove(false);

  const removeConfirmed = () => removeRequest.request(null);

  const showOrderTransactionClick = (order: OrderDisplayDto) => {
    setOrderForOrderTransactions(order);
    setShowOrderTransactions(true);
  }

  const setUnrecoverableDataError = (exception: Error, severityLevel?: SeverityLevel) => {
    editingOrderCompleted();
    displayMessage.fail("Something is wrong with this order. Please contact Kahler Automation.");
    logException(exception, severityLevel);
  }

  return <Container>
    <Confirm
      visible={confirmOrderCompletion}
      title='Complete Order'
      body={`When an order is completed it is no longer available to dispense.
               Completion cannot be undone.
               Are you sure that you want to complete order ${orderToComplete?.number}?`}
      onDismiss={confirmOrderCompletionDismissed}
      onConfirm={orderCompletionConfirmed} />
    <Confirm
      visible={showConfirmRemove}
      title='Remove Order'
      body={`When an order is removed it is no longer available to dispense. 
               The order cannot be restored. 
               Order transactions are not removed. 
               Are you sure that you want to remove order ${orderToRemove?.number}?`}
      onDismiss={dismissConfirmRemove}
      onConfirm={removeConfirmed} />
    {showSiteAssignmentForm &&
      <SiteAssignmentForm
        title={`Select sites for order ${orderToAssign?.number}`}
        getAssignedSitesPath={apiGetSiteAssignmentPath(user.selectedBusiness?.id, orderToAssign?.id)}
        assignSitePath={apiSetSiteAssignmentPath(user.selectedBusiness?.id, orderToAssign?.id)}
        onHide={assignSitesClosed}
        onSuccess={assignSitesSuccess} />
    }
    {createEditMode !== null ? (
      <CreateEditOrder
        onSuccess={createEditSuccess}
        user={user}
        mode={createEditMode}
        readOnly={!createEditMode.create && isReadOnly(createEditMode.initialOrder)}
        onCancelled={editingOrderCompleted}
        setUnrecoverableDataError={setUnrecoverableDataError}
      />
    ) : showOrderTransactions && orderForOrderTransactions != null ? (
      <OrderTransactionPage
        profile={user}
        order={orderForOrderTransactions}
        backClicked={() => setShowOrderTransactions(false)}
      />
    ) : (
      <>
        <Row className="justify-content-between mb-2">
          <h2 className="col-auto ka-blue">Orders</h2>
          <BoldButton className="col-auto btn-ghost-primary" onClick={createClick}>
            Create Order
          </BoldButton>
        </Row>
        <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
        <form>
          <div className="row gx-2 gx-md-3 mb-7">
            <div className="col-md-4 mb-2 mb-md-0">
              <FilterInput placeholder='Filter orders by keyword' value={filterText} onFilterTextChange={filterTextChanged} />
            </div>
          </div>
        </form>
        <div className="d-flex justify-content-center">
          <Nav tabs className="w-35">
            <NavItem className="w-50">
              <NavLink
                className="d-flex justify-content-center"
                active={!viewCompletedOrders}
                onClick={() => setViewCompletedOrders(false)}>
                Active
              </NavLink>
            </NavItem>
            <NavItem className="w-50">
              <NavLink
                className="d-flex justify-content-center"
                active={viewCompletedOrders}
                onClick={() => setViewCompletedOrders(true)}>
                Completed
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <QueryView query={getOrders}
          renderLoading={() => <div className="mt-8">
            <CenteredSpinner />
          </div>}
          renderData={orders => {
            if (completeRequest.isLoading || removeRequest.isLoading) {
              return <div className="mt-8">
                <CenteredSpinner />
              </div>
            }
            return <Table bordered>
              <thead>
                <tr>
                  <th className="col col-2">Number</th>
                  <th className="col col-3">Products</th>
                  <th className="col col-3">Customer</th>
                  <th className="col col-3">Sites</th>
                  <th className="col col-3">{viewCompletedOrders ? "Completed" : "Created"}</th>
                  <th className="col col-1"></th>
                </tr>
              </thead>
              <tbody>
                {orders.sort(byNumber)
                  .filter(containsFilterText)
                  .filter(o => viewCompletedOrders ? isComplete(o) : !isComplete(o))
                  .map(order =>
                    <tr key={order.id}>
                      <td>
                        <p>
                          {order.number}
                        </p>
                      </td>
                      <td>
                        <OverflowTooltip text={getOrderItems(order).join(", ")} />
                      </td>
                      <td>
                        <OverflowTooltip text={customerDisplayName(order.customerName, order.customerAccountNumber)} />
                      </td>
                      <td>
                        <OverflowTooltip text={order.siteNames.join(", ")} />
                      </td>
                      <td>
                        <p>
                          {viewCompletedOrders ? order.completedAt! : order.createdAt}
                        </p>
                      </td>
                      <td align="right">
                        <div className="dropdown">
                          <Button className="dropdown-toggle btn-ghost-secondary" variant="link" id="actionsMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            <strong>Actions</strong>
                          </Button>
                          <div className="dropdown-menu" aria-labelledby="actionsMenuButton">
                            <BoldButton className="dropdown-item btn-ghost-secondary" onClick={() => editClick(order)}>
                              {isReadOnly(order) ? "View" : "Edit"}
                            </BoldButton>
                            {!isComplete(order) && <BoldButton className="dropdown-item btn-ghost-secondary" onClick={() => complete(order)}>Complete</BoldButton>}
                            <BoldButton className="dropdown-item btn-ghost-secondary" onClick={() => remove(order)}>Remove</BoldButton>
                            <BoldButton className="dropdown-item btn-ghost-secondary" onClick={() => assignSitesClick(order)}>Assign sites</BoldButton>
                            {order.hasTransactions && <BoldButton className="dropdown-item btn-ghost-secondary" onClick={() => showOrderTransactionClick(order)}>View Transactions</BoldButton>}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
              </tbody>
            </Table>
          }} />
      </>
    )}
  </Container>;
}

const byNumber = (a: OrderDto, b: OrderDto) => a.number.localeCompare(b.number);

export { OrderPage };