import { QueryOptions } from "../Common/useQuery";
import useUserQuery from "../Common/useUserQuery";
import { useUser } from "../UserAccess/useUser";

export interface SiteDto {
	id: string;
	name: string;
}

const useGetSitesNonAdmin = (options?: QueryOptions<SiteDto[]>) => { 
  const user = useUser();

  return useUserQuery<SiteDto[]>(
    { path: `/api/businesses/${user.selectedBusiness?.id!}/sites/nonAdmin` },
    options);
}

export default useGetSitesNonAdmin;
