import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { UserContext, LoginType, UserProfile, KaEmployeeProfile } from '../UserAccess/UserContext';

interface IProps {
  requiresAdmin?: boolean,
  businessNotRequired?: boolean,
  defaultPath: string,
  linkDisplayText: string,
  iconName?: string,
}

export default function NavPageLink(props: IProps) {
  const { user } = useContext(UserContext);
  let displayLink = false;
  if (user != null) {
    if (user?.userType === LoginType.User) {
      displayLink = IsLinkValidForUser(props, user);
    } else if (user?.userType === LoginType.KaEmployee) {
      displayLink = IsLinkValidForKaEmployee(props, user);
    }
  }

  return (
    displayLink
      ? <li className="nav-item">
        <NavLink className="nav-link px-3" to={props.defaultPath}>
          <i className={`bi-${props.iconName} nav-icon`}></i>
          {props.linkDisplayText}
        </NavLink>
      </li>
      : <></>
  );

  function IsLinkValidForUser(props: IProps, user?: UserProfile): boolean {
    let adminRequired = props.requiresAdmin ?? false;
    let businessRequired = adminRequired || (!props.businessNotRequired ?? true);

    if (!businessRequired)
      return true;

    if (adminRequired) {
      return user?.selectedBusiness?.administrator ?? false;
    } else {
      return user?.selectedBusiness != null;
    }
  }

  function IsLinkValidForKaEmployee(props: IProps, user?: KaEmployeeProfile): boolean {
    let businessRequired = !props.businessNotRequired ?? true;

    if (!businessRequired)
      return true;

    return user?.selectedBusiness != null;

  }
}