import React from 'react';
import { Alert } from 'reactstrap';
import { useUser } from '../UserAccess/useUser';

type Props = {
  children: React.ReactNode;
}

const BusinessAdminTemplate: React.FC<Props> = ({ children }) => {
  const user = useUser();

  if (user.selectedBusiness?.administrator) return <>{children}</>;

  return <Alert color='warning'>Only business administrators may access this resource.</Alert>;
}

export default BusinessAdminTemplate;