import { QueryOptions } from "../components/Common/useQuery";
import useUserQuery from "../components/Common/useUserQuery";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";

export interface CompartmentDto {
  capacityUnit: string;
  capacity?: number;
}

export interface TransportDto {
  id: string;
  name: string;
  number?: string;
  weightUnit: string;
  maxWeight?: number;
  compartments: CompartmentDto[];
}

const useGetTransports = (options?: QueryOptions<TransportDto[]>) => {
  const business = useSelectedBusiness();
  return useUserQuery<TransportDto[]>({ path: `/api/businesses/${business.id}/transports` }, options);
}

export default useGetTransports;
