import React, { useState } from "react";
import { Path, useForm } from "react-hook-form";
import { ApplicatorDto } from "../../requests/useGetApplicators";
import useUpsertApplicator, { ApplicatorUpsertDto } from "../../requests/useUpsertApplicator";
import { RowFormText } from "../Common/Forms/FormText";
import KaAlert from "../Common/KaAlert";
import KaModal from "../Common/KaModal";
import SpinnerButton from "../Common/SpinnerButton";
import useDisplayMessage from "../Common/useDisplayMessage";

interface CreateEditApplicatorProps {
  initialApplicator: ApplicatorDto | null;
  onDismiss: () => void;
  onUpsert: (applicatorId: string, applicatorName: string, assignSites: boolean) => void;
}

export const CreateEditApplicator = (props: CreateEditApplicatorProps) => {
  const displayMessage = useDisplayMessage();
  const { id, ...initapplicator } = { ...props.initialApplicator };
  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm<ApplicatorUpsertDto>({
    defaultValues: { ...initapplicator }
  });

  const [assignSites, setAssignSites] = useState(false);

  const dismissed = () => {
    props.onDismiss();
  };

  const submit = (formValues: ApplicatorUpsertDto, assign: boolean) => {
    setAssignSites(assign);
    upsert.request(formValues);
  }

  const formProps = (name: Path<ApplicatorUpsertDto>) => ({
    labelSpace: 4,
    inputSpace: 7,
    name: name,
    setValue,
    register,
    errors,
  });

  const upsert = useUpsertApplicator(props.initialApplicator?.id, {
    onSuccess: response => {
      props.onDismiss();
      const applicatorId = props.initialApplicator ? props.initialApplicator.id : response;
      props.onUpsert(applicatorId, getValues().name, assignSites);
    },
    onError: (e) => displayMessage.fail(e.message),
  });

  return <KaModal
    title={`${props.initialApplicator ? 'Update' : 'Create'} a applicator`} onHide={dismissed} show={true}
    body={(<>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <RowFormText {...formProps('name')}
        options={{
          required: 'Name is required',
          validate: { length: v => v.length < 51 || 'Name cannot be longer than 50 characters' }
        }} />
      <RowFormText {...formProps('license')}
        displayName="License"
        options={{
          validate: { length: v => v.length < 51 || 'License cannot be longer than 50 characters' }
        }} />
      <RowFormText {...formProps("epaNumber")}
        displayName="EPA Number"
        options={{
          validate: {
            length: v => v.length < 51 || 'EPA Number cannot be longer than 50 characters'
          }
        }} />
      <RowFormText {...formProps("email")}
        options={{
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Email Address is invalid'
          }
        }} />
    </>)}
    footer={(
      <div className="btn-group">
        <SpinnerButton
          className="w-25 m-1 rounded btn-ghost-primary"
          spinning={upsert.isLoading && !upsert.isError}
          onClick={handleSubmit(values => submit(values, false))}>
          {`${props.initialApplicator ? 'Update' : 'Create'}`}
        </SpinnerButton>
        <SpinnerButton
          className="w-25 m-1 rounded btn-ghost-primary"
          spinning={upsert.isLoading && !upsert.isError}
          onClick={handleSubmit(values => submit(values, true))}>
          {`${props.initialApplicator ? 'Update and Assign' : 'Create and Assign'}`}
        </SpinnerButton>
      </div>)
    }
  />
};