import React, { useState } from "react";
import useInterval from "../Common/useInterval";
import useGetIntegrationOrderExportStatuses, { IntegrationOrderExportStatusDto, OrderStatus } from "../../requests/useGetIntegrationOrderExportStatuses";
import { Table } from "react-bootstrap";
import { WidgetDisplay } from "./WidgetDisplay";
import { WidgetProps } from "./DashboardContext";
import useDisplayMessage from "../Common/useDisplayMessage";
import useUpdateIntegrationOrderExportStatusWidgetSettings from "../../requests/useUpdateIntegrationOrderExportStatusWidgetSettings";
import KaModal from "../Common/KaModal";
import KaAlert from "../Common/KaAlert";

export const IntegrationOrderExportStatus = (props: WidgetProps) => {
  const refreshTimeMs = 30000;
  const [name, setName] = useState<string>(props.name === "" ? "Integration Order Export Status" : props.name);
  const [data, setData] = useState<IntegrationOrderExportStatusDto[] | null>(null);

  const getIntegrationOrderExportStatuses = useGetIntegrationOrderExportStatuses(
    { orderCount: 7, },
    { onSuccess: (resp) => setData(resp.integrationOrderExportStatuses) }
  );

  useInterval(() => getIntegrationOrderExportStatuses.query(), refreshTimeMs, false);

  const integrationOrderExportStatusIndicator = (orderStatus: IntegrationOrderExportStatusDto) =>
    orderStatus.state === OrderStatus.Processed ? 'bi-circle-fill traffic-light-green'
      : orderStatus.state === OrderStatus.Warning ? 'bi-triangle-fill traffic-light-yellow'
        : 'bi-clock-fill text-primary';

        
  const Settings = (show: boolean, onClose: () => void) => {
    const displayMessage = useDisplayMessage();
    const [modifiedName, setModifiedName] = useState(name);

    const updateSettings = useUpdateIntegrationOrderExportStatusWidgetSettings(props.id.toString(), {
      onSuccess: () => {
        setName(modifiedName);
        displayMessage.clear();
        onClose();
      },
      onError: (err) => displayMessage.fail(err.message)
    }); 

    const saveSettings = () =>
      updateSettings.request({
        name: modifiedName,
      });

    return <KaModal
      onHide={onClose}
      show={show}
      title={'Integration Order Export Status Settings'}
      body={(<div className="mt-3">
        <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
        <h5>Widget Name:</h5>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Widget Name"
          value={modifiedName}
          onChange={(e) => setModifiedName(e.target.value)}/>
      </div>)}
      footer={(
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => saveSettings()}>
          Save
        </button>)}
    />
  }

  return <WidgetDisplay
    widgetId={props.id}
    title={name}
    path="/integrationOrderExportStatus"
    settings={Settings}
    isLoading={data === null && getIntegrationOrderExportStatuses.isLoading}>
    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
      {getIntegrationOrderExportStatuses.isError || data?.length === 0 ?
        <h2 className="h-100 w-100 d-flex justify-content-center align-items-center pb-5 pt-1 text-wrap text-center" style={{ color: 'darkslategrey' }}>
          No integration orders available
        </h2>
        : <div className="row d-flex justify-content-around">
          <Table borderless style={{ tableLayout: 'fixed' }}>
            <tbody>
              {data?.slice(0, 7).map((orderStatus) =>
                <tr key={`integration-order-${orderStatus.orderNumber}`}>
                  <td className="col-1 py-0">
                    <i className={integrationOrderExportStatusIndicator(orderStatus)} />
                  </td>
                  <td className="col-3 p-0 pe-1">
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {orderStatus.orderNumber}
                    </div>
                  </td>
                  <td className="col-4 p-0 pe-1">
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {orderStatus.siteNames}
                    </div>
                  </td>
                  <td className="col-4 p-0">
                    {new Date(orderStatus.completedAt).toLocaleString('en-US',
                      { month: '2-digit', day: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' })}
                  </td>
                </tr>)}
            </tbody>
          </Table>
        </div>
      }
    </div>
  </WidgetDisplay>
}

export const IntegrationOrderExportStatusIndicator = (orderStatus: IntegrationOrderExportStatusDto) =>
  orderStatus.state === OrderStatus.Processed ? 'bi-circle-fill traffic-light-green'
    : orderStatus.state === OrderStatus.Warning ? 'bi-triangle-fill traffic-light-yellow'
      : 'bi-clock-fill text-primary';