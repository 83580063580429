import React from 'react';
import { UserQuerySpec } from '../Common/useUserQuery';
import DestinationDto from '../Common/dtos/DestinationDto';
import { Profile } from '../UserAccess/UserContext';
import QueryView from '../Common/QueryView';
import { FormSelectSearch, FormSelectSearchProps, SelectOption } from '../Common/Forms/SelectSearch';
import useUserOrEmployeeQuery from '../Common/useUserOrEmployeeQuery';

export interface CustomerDestinationSelectProps extends Omit<FormSelectSearchProps<any>, 'selectOptions'> {
  profile: Profile,
  customerId: string | undefined,
  firstOptionInList?: SelectOption,
}

const getDestinationSpec = (businessId?: string, customerId?: string): UserQuerySpec => ({
  path: `/api/businesses/${businessId}/customers/${customerId}/destinations`,
});

export function CustomerDestinationSelectControl(props: CustomerDestinationSelectProps) {
  const getDestinations = useUserOrEmployeeQuery<DestinationDto[]>(getDestinationSpec(props.profile.selectedBusiness?.id, props.customerId));

  const destinationOptionsFromData = (destinations: DestinationDto[]) => {
    let destinationOptions: SelectOption[] = [];
    if (props.firstOptionInList) destinationOptions.push(props.firstOptionInList);

    const sortedDestinations = destinations.sort((a, b) => a.name.localeCompare(b.name));
    
    const options = sortedDestinations.map(d => {
      const otherDestinationWithSameName = sortedDestinations.find(dto => dto.name === d.name && dto.id !== d.id);

      if (otherDestinationWithSameName && !!d.shippingAddressCity && !!d.shippingAddressState) {
        return { value: d.id, name: `${d.name} (${d.shippingAddressCity}, ${d.shippingAddressState})` };
      } 

      return { value: d.id, name: d.name };
    });
    destinationOptions.push(...options);
    return destinationOptions;
  }

  return (<QueryView
    query={getDestinations}
    renderData={(destinations) =>
      <FormSelectSearch
        {...props}
        displayName={props.displayName ?? "Destination"}
        selectOptions={destinationOptionsFromData(destinations)} />}
  />);
}

type Props = {
  children: React.ReactNode,
  customerId: string | undefined,
}

const CustomerTemplate: React.FC<Props> =
  ({ customerId, children }) => (<>{customerId && children}</>);

export function CustomerDestinationSelect(props: CustomerDestinationSelectProps) {
  return <CustomerTemplate customerId={props.customerId}>
    <CustomerDestinationSelectControl {...props} />
  </CustomerTemplate>
}
