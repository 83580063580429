import React, { useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useIsOverflow } from "./useIsOverflow";

const OverflowTooltip = (props: { text: string }) => {
  const ref = useRef<HTMLParagraphElement>(null);
  const isOverflow = useIsOverflow(ref);

  const tooltip = isOverflow ? <Tooltip>{props.text}</Tooltip> : <></>;

  return <OverlayTrigger placement="bottom-start" overlay={tooltip}>
    <p className="line-clamp-2" ref={ref}>
      {props.text}
    </p>
  </OverlayTrigger>
}

export default OverflowTooltip;