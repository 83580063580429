import React from 'react';
  
const FilterInput = (props: FilterInputProps) => {
  return (
    <div className='input-group input-group-merge'>
      <span className='input-group-prepend input-group-text'>
        <i className='bi-search'></i>  
      </span>
      <input
        type='text'
        className='form-control form-control-lg'
        value={props.value}
        placeholder={props.placeholder}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onFilterTextChange(e.target.value)} />
    </div>);
};

export interface FilterInputProps {
  placeholder: string;
  value?: string;
  onFilterTextChange: (text: string) => void;
}

export const textSatisfiesFilter = (filterText: string) => {
  return (text: string | undefined) => text && text.search(new RegExp(filterText, 'i')) !== -1;
};

export default FilterInput;