import useRequest, { Request, RequestOptions } from './useRequest';
import { useKaEmployee } from '../UserAccess/useKaEmployee';
import { HttpMethod } from './useFetch';

export interface UserRequestSpec {
  path: string;
  method: HttpMethod;
}

export default function useKaEmployeeRequest<Req, Resp = void>(spec: UserRequestSpec, options?: RequestOptions<Resp>): Request<Resp, Req> {
  const user = useKaEmployee();
  return useRequest<Req, Resp>({
    path: spec.path,
    method: spec.method,
    headers: user.fetchHeaders,
  }, options);
}