import React, { useState } from "react"
import useInterval from "../Common/useInterval";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { WidgetDisplay } from "./WidgetDisplay";
import useGetTransportsLoaded, { TransportsLoadedResponse } from "../Requests/useGetTransportsLoaded";
import CountUp from "react-countup";
import { CenteredSpinner } from "../Common/CenteredSpinner";
import { TransportsLoadedWidgetSettings, WidgetProps } from "./DashboardContext";
import { isMultiValue, KaSelect } from "../Common/KaSelect";
import KaModal from "../Common/KaModal";
import useUpdateTransportsLoadedWidgetSettings from "../../requests/useUpdateTransportsLoadedWidgetSettings";
import useDisplayMessage from "../Common/useDisplayMessage";
import KaAlert from "../Common/KaAlert";
import useGetSitesNonAdmin from "../Requests/useGetSitesNonAdmin";

type TransportsLoadedWidgetProps = WidgetProps & TransportsLoadedWidgetSettings;

export const TransportsLoaded = (props: TransportsLoadedWidgetProps) => {
  const getSites = useGetSitesNonAdmin();
  const [name, setName] = useState<string>(props.name === "" ? "Transports Loaded" : props.name);
  const [siteId, setSiteId] = useState<string | undefined>(props.selectedSiteId);
  const refreshTimeMs = 30000;
  const [data, setData] = useState<TransportsLoadedResponse | null>(null);

  const getTransportsLoaded = useGetTransportsLoaded({
    timeZoneOffset: new Date().getTimezoneOffset(),
    siteId: siteId === 'AllSites' ? undefined : siteId,
  }, {
    onSuccess: (resp) => {
      setData(resp);
    }
  });

  useInterval(() => getTransportsLoaded.query(), refreshTimeMs, false);

  const TransportCounterWithTime = ({ amount, displayText, dateText, error }:
    {
      amount?: number,
      displayText: string,
      dateText?: string,
      error?: string
    }) => {
    return <div className="w-100">
      <div className="row px-0 d-flex justify-content-center">
        <h4 className="ka-blue mb-0 w-auto">
          {amount === undefined
            ? <OverlayTrigger
              placement={'right'}
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip>
                  {getTransportsLoaded.error?.message ?? error ?? "Unexpected error occurred"}
                </Tooltip>
              }>
              <span className="text-muted">N/A</span>
            </OverlayTrigger>
            : <CountUp
              end={amount!}
              suffix={amount! === 1 ? " load" : " loads"}
              duration={0.5}
              redraw={false}
              preserveValue={true} />}
        </h4>
      </div>
      <div className="row px-0 d-flex justify-content-center">
        <p className="mb-0 w-auto" style={{ fontSize: 13 }}>{displayText}</p>
      </div>
      {dateText &&
        <div className="row px-0 d-flex justify-content-center">
          <p className="mb-0 w-auto" style={{ fontSize: 11 }}>{dateText}</p>
        </div>
      }
    </div>
  };

  const Settings = (show: boolean, onClose: () => void) => {
    const displayMessage = useDisplayMessage();
    const [modifiedName, setModifiedName] = useState(name);
    const [selectedSite, setSelectedSite] = useState<string>(siteId ?? 'AllSites');

    const updateSettings = useUpdateTransportsLoadedWidgetSettings(props.id.toString(), {
      onSuccess: () => {
        setName(modifiedName);
        setSiteId(selectedSite);
        displayMessage.clear();
        onClose();
      },
      onError: (err) => displayMessage.fail(err.message)
    }); 

    if (getSites.isLoading) return <KaModal
      onHide={onClose}
      show={show}
      title={'Transports Loaded Settings'}
      body={(<div className="mt-3">
        <CenteredSpinner />
      </div>)}
    />

    if (getSites.isError) return <KaModal
      onHide={onClose}
      show={show}
      title={'Transports Loaded Settings'}
      body={(<div className="mt-3">
        <h3>{getSites.error?.message}</h3>
      </div>)}
    />

    const sortedSites = getSites.data?.sort((a, b) => a.name.localeCompare(b.name));

    const sortedSiteOptions = sortedSites!.map(s => ({ value: s.id, label: s.name }));
    const AggregateSite = { value: 'AllSites', label: "All Sites" };
    const options = [AggregateSite].concat(sortedSiteOptions);

    const saveSettings = () =>
      updateSettings.request({
        name: modifiedName,
        selectedSiteId: selectedSite === 'AllSites' ? undefined : selectedSite,
      });

    return <KaModal
      onHide={onClose}
      show={show}
      title={'Transports Loaded Settings'}
      body={(<div className="mt-3">
        <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
        <h5>Widget Name:</h5>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Widget Name"
          value={modifiedName}
          onChange={(e) => setModifiedName(e.target.value)}/>
        <h5 className="mt-3">Show transports for:</h5>
        <KaSelect
          options={options}
          defaultValue={options.find((option) => option.value === siteId!)}
          onChange={(valueObject) => {
            if (!isMultiValue(valueObject)) { setSelectedSite(valueObject?.value!); }
          }}
        />
      </div>)}
      footer={(
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => saveSettings()}>
          Save
        </button>)}
    />
  }

  return <WidgetDisplay
    widgetId={props.id}
    title={name}
    isLoading={data === null && getTransportsLoaded.isLoading}
    settings={Settings}>
    <div className="row mb-2 px-3 mt-2">
      <div className="col ps-0">
        <TransportCounterWithTime
          amount={data?.loadedAmountHour}
          displayText={"Within the hour"}
          dateText={`Starting ${data?.startOfHour}`}
          error={getTransportsLoaded.error?.message}
        />
      </div>
      <div className="col pe-0">
        <TransportCounterWithTime
          amount={data?.loadedAmountDay}
          displayText={"Today"}
          dateText={`Starting ${data?.startOfDay}`}
          error={getTransportsLoaded.error?.message}
        />
      </div>
    </div>
    <div className="row mb-2 px-3">
      <div className="col ps-0">
        <TransportCounterWithTime
          amount={data?.loadedAmountWeek}
          displayText={"This week"}
          dateText={`Starting on ${data?.startOfWeek}`}
          error={getTransportsLoaded.error?.message}
        />
      </div>
      <div className="col pe-0">
        <TransportCounterWithTime
          amount={data?.loadedAmountMonth}
          displayText={"This month"}
          dateText={`Starting on ${data?.startOfMonth}`}
          error={getTransportsLoaded.error?.message}
        />
      </div>
    </div>
    <div className="row mb-2 px-3">
      <div className="col ps-0">
        <TransportCounterWithTime
          amount={data?.loadedAmountYear}
          displayText={"Year to date"}
          error={getTransportsLoaded.error?.message}
        />
      </div>
    </div>
  </WidgetDisplay>
};
