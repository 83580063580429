import { HttpMethod } from "../components/Common/useFetch";
import { RequestOptions } from "../components/Common/useRequest";
import useUserRequest from "../components/Common/useUserRequest";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";

export interface UpdateIntegrationOrderExportStatusWidgetSettingsDto {
  name: string;
}

const useUpdateIntegrationOrderExportStatusWidgetSettings = (widgetId: string, options?: RequestOptions<void>) => {
  const business = useSelectedBusiness();
  return useUserRequest<UpdateIntegrationOrderExportStatusWidgetSettingsDto>({
    path: `/api/businesses/${business.id}/integrationOrderExportStatusWidgetSettings/${widgetId}`,
    method: HttpMethod.PUT,
  }, options);
}

export default useUpdateIntegrationOrderExportStatusWidgetSettings;