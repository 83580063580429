import React from 'react';
import { NonUserTemplate } from './UserAccess/NonUserTemplate';
import { UserTemplate } from './UserAccess/UserTemplate';
import { Dashboard } from './Dashboard/Dashboard';
import { DashboardProvider } from './Dashboard/DashboardProvider';

export const Home = () =>
  <div>
    <UserTemplate>
      <DashboardProvider>
        <Dashboard />
      </DashboardProvider>
    </UserTemplate>
    <NonUserTemplate>
      <img src='/Constellation-Logo.png' className='position-absolute top-50 start-50 translate-middle' alt="" />
    </NonUserTemplate>
  </div>