import React from 'react';
import { Col, FormGroup } from 'reactstrap';
import { FormCommonDisplayProps } from './FormCommon';
import { FormLabel } from './FormLabel';

export interface FormRowSize {
  labelSpace?: number,
  inputSpace?: number,
}

interface FormRowProps extends FormRowSize, FormCommonDisplayProps<any> {
  control: JSX.Element,
}

export const FormRow = (props: FormRowProps) => {
  return <FormGroup row>
    <Col xs={props.labelSpace}>
      <FormLabel {...props} />
    </Col>
    <Col xs={props.inputSpace}>
      {props.control}
    </Col>
  </FormGroup>
}
