import React from 'react';
import Alert from 'react-bootstrap/Alert';

export interface DisplayMessage {
  message: string,
  success: boolean,
}

interface KaAlertProps {
  displayMessage: DisplayMessage | null
  onClose: () => void,
}

const KaAlert = ({ displayMessage, onClose }: KaAlertProps) => {
  return <Alert
    show={displayMessage != null}
    variant={displayMessage?.success ? "success" : "danger"}
    onClose={onClose}
    dismissible>
    {displayMessage?.message ?? ""}
  </Alert>
}

export default KaAlert;