import 'jquery';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const publicClientApp = new PublicClientApplication(msalConfig);

const root = ReactDOMClient.createRoot(rootElement);

root.render(<BrowserRouter basename={baseUrl}>
  <App pca={publicClientApp} />
</BrowserRouter>);

unregister();
