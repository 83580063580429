import React from 'react';
import { Path, useForm } from 'react-hook-form';
import KaAlert from '../Common/KaAlert'
import KaModal from '../Common/KaModal';
import SpinnerButton from '../Common/SpinnerButton';
import useDisplayMessage from '../Common/useDisplayMessage';
import { HttpMethod } from '../Common/useFetch';
import useKaEmployeeRequest, { UserRequestSpec } from '../Common/useKaEmployeeRequest';
import { KaEmployeeProfile } from '../UserAccess/UserContext';
import { RowFormText } from '../Common/Forms/FormText';
import { BusinessDto } from './BusinessList';

interface IProps {
  kaUser: KaEmployeeProfile;
  business: BusinessDto;
  onHide: () => void;
  onSuccess: (businessUser: BusinessAddAdminUserDto, business: BusinessDto) => void;
}

export interface BusinessAddAdminUserDto { emailAddress: string }

const postSpec = (businessId?: string): UserRequestSpec => ({
  path: `/employeeApi/businesses/${businessId}/addAdminUser`,
  method: HttpMethod.POST,
});

const newUser = (): BusinessAddAdminUserDto => ({ emailAddress: '' });

export const BusinessAddUser = (props: IProps) => {
  const user = newUser();
  const requestSpec = postSpec(props.business.id);
  const displayMessage = useDisplayMessage();

  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm<BusinessAddAdminUserDto>({
    defaultValues: { ...user }
  });

  const handleCreateBusinessAdmin = (userUpsert: BusinessAddAdminUserDto) => {
    useAddUser.request(userUpsert);
  }

  const useAddUser = useKaEmployeeRequest<BusinessAddAdminUserDto>(requestSpec, {
    onSuccess: () => props.onSuccess(getValues(), props.business),
    onError: (e) => displayMessage.fail(e.message),
  });

  const hookProps = (name: Path<BusinessAddAdminUserDto>) => ({
    labelSpace: 3,
    inputSpace: 8,
    name: name,
    setValue,
    register,
    errors,
  })

  const onClose = () => {
    props.onHide();
    displayMessage.clear();
  }

  return (<KaModal
    onHide={onClose}
    show={true}
    title={`Add user to ${props.business.name}`}
    body={(<>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <RowFormText displayName="User's email"
        {...hookProps("emailAddress")}
        options={{
          required: 'Email Address is required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Email Address is invalid'
          }
        }}
      />
    </>)}
    footer={(
      <SpinnerButton
        className="btn-ghost-primary"
        spinning={useAddUser.isLoading}
        onClick={handleSubmit(handleCreateBusinessAdmin)} >
        Add
      </SpinnerButton>)
    }
  />);
}
