import React, { useState } from "react";
import { Container, Row, Table } from "reactstrap";
import { CenteredSpinner } from "../Common/CenteredSpinner";
import DriverDto from "../Common/dtos/DriverDto";
import KaAlert from "../Common/KaAlert";
import { SiteAssignmentForm } from "../Common/SiteAssignment/SiteAssignmentForm";
import useDisplayMessage from "../Common/useDisplayMessage";
import useUserQuery, { UserQuerySpec } from "../Common/useUserQuery";
import FilterInput, { textSatisfiesFilter } from "../FilterInput";
import { useUser } from "../UserAccess/useUser";
import { CreateEditDriver } from "./CreateEditDriver";
import BoldButton from "../Common/BoldButton";
import { Button } from "react-bootstrap";
import { HttpMethod } from "../Common/useFetch";
import useUserRequest, { UserRequestSpec } from "../Common/useUserRequest";
import Confirm from "../Common/Confirm";

enum States {
  None,
  AddEditDriver,
  AssignSites,
}

type PopupState =
  {
    state: States.None;
    driver?: null;
  } |
  {
    state: States.AddEditDriver;
    driver: DriverDto | null;
  } |
  {
    state: States.AssignSites;
    driver: DriverDto;
  };

const apiPath = (businessId?: string): string => `/api/businesses/${businessId}/drivers`;
const getSpec = (businessId?: string): UserQuerySpec => ({
  path: apiPath(businessId),
});

const apiGetSiteAssignmentPath = (businessId?: string, driverId?: string): string =>
  `${apiPath(businessId)}/${driverId}/siteAssignment`;
const apiSetSiteAssignmentPath = (businessId?: string, driverId?: string): string =>
  `${apiPath(businessId)}/${driverId}/assignToSites`;

const removeRequestSpec = (businessId?: string, driverId?: string): UserRequestSpec => ({
  path: `/api/businesses/${businessId}/drivers/${driverId}`,
  method: HttpMethod.DELETE,
});


const alphabeticallyByName = (a: DriverDto, b: DriverDto) => a.name.localeCompare(b.name);

const DriverPage = () => {
  const user = useUser();
  const displayMessage = useDisplayMessage();
  const [filterText, setFilterText] = useState('');
  const [popupState, setPopupState] = useState<PopupState>({ state: States.None });
  const [assignSitesForDriverId, setAssignSitesForDriverId] = useState<string | undefined>();
  const [driverToRemove, setDriverToRemove] = useState<DriverDto | undefined>(undefined);

  const getDrivers = useUserQuery<DriverDto[]>(getSpec(user.selectedBusiness?.id), {
    onSuccess: (data) => {
      if (assignSitesForDriverId && data) {
        const driverToAssign = data.find(r => r.id === assignSitesForDriverId);

        driverToAssign
          ? assignSitesClick(driverToAssign)
          : displayMessage.fail(
            "Could not assign sites because the driver was not found")

        setAssignSitesForDriverId(undefined);
      }
    }
  });

  const containsFilterText = (driver: DriverDto) => {
    const satisfiesFilter = textSatisfiesFilter(filterText);
    return satisfiesFilter(driver.name) || satisfiesFilter(driver.number);
  };

  const filterTextChanged = (text: string) => setFilterText(text);

  const createClicked = () => {
    displayMessage.clear();
    setPopupState({ state: States.AddEditDriver, driver: null });
  }

  const editClicked = (driver: DriverDto) => {
    displayMessage.clear();
    setPopupState({ state: States.AddEditDriver, driver: driver });
  }

  const driverUpserted = (driverId: string, driverName: string, assignSites: boolean) => {
    displayMessage.success(`Driver ${driverName} ${popupState.driver ? 'updated' : 'created'}`);
    if (assignSites) setAssignSitesForDriverId(driverId);
    getDrivers.query();
  }

  const editDismissed = () => setPopupState({ state: States.None });

  const assignSitesClick = (driver: DriverDto) => {
    displayMessage.clear();
    setPopupState({ state: States.AssignSites, driver: driver });
  }

  const assignSitesSuccess = () => {
    displayMessage.success(`Sites successfully updated for ${popupState.driver?.name}`);
    assignSitesClosed();
  }

  const assignSitesClosed = () => setPopupState({ state: States.None });

  const removeSuccess = () => {
    displayMessage.success(`Driver ${driverToRemove?.name} removed`);
    return getDrivers.query();
  };

  const removeRequest = useUserRequest<void, void>(
    removeRequestSpec(user.selectedBusiness?.id, driverToRemove?.id), {
    onSuccess: removeSuccess,
    onError: (err) => displayMessage.fail(err.message),
    onComplete: () => setDriverToRemove(undefined),
  });

  if (getDrivers.isError) return <h3>{getDrivers.error?.message}</h3>
  if (getDrivers.isLoading || removeRequest.isLoading) return <CenteredSpinner />

  return <Container>
    {popupState.state === States.AddEditDriver && <CreateEditDriver
      user={user}
      initialDriver={popupState.driver}
      onUpsert={driverUpserted}
      onDismiss={editDismissed} />}
    {popupState.state === States.AssignSites &&
      <SiteAssignmentForm
        title={`Select sites for ${popupState.driver?.name}`}
        getAssignedSitesPath={apiGetSiteAssignmentPath(user.selectedBusiness?.id, popupState.driver?.id)}
        assignSitePath={apiSetSiteAssignmentPath(user.selectedBusiness?.id, popupState.driver?.id)}
        onHide={assignSitesClosed}
        onSuccess={assignSitesSuccess} />
    }
    <Confirm
      visible={!!driverToRemove}
      title='Remove driver'
      body={`Are you sure that you want to remove driver ${driverToRemove?.name}?`}
      onDismiss={() => setDriverToRemove(undefined)}
      onConfirm={() => removeRequest.request()} />
    <Row className='justify-content-between mb-2'>
      <h2 className='col-auto ka-blue'>Drivers</h2>
      <BoldButton className='col-auto btn-ghost-primary' onClick={createClicked}>Create Driver</BoldButton>
    </Row>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <form>
      <div className='row gx-2 gx-md-3 mb-7'>
        <div className='col-md-4 mb-2 mb-md-0'>
          <FilterInput placeholder='Filter drivers by keyword' onFilterTextChange={filterTextChanged} />
        </div>
      </div>
    </form>
    <Table bordered>
      <thead><tr><th>Name</th><th>Number</th><th></th></tr></thead>
      <tbody>
        {getDrivers.data?.filter(containsFilterText).sort(alphabeticallyByName).map(driver =>
          <tr key={driver.id}>
            <td>{driver.name}</td>
            <td>{driver.number}</td>
            <td align="right">
              <div className='dropdown'>
                <Button id='actionsMenuButton'
                  className='dropdown-toggle btn-ghost-secondary'
                  variant='link'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'><strong>Actions</strong></Button>
                <div className='dropdown-menu' aria-labelledby='actionsMenuButton'>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => editClicked(driver)}>Edit</BoldButton>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => assignSitesClick(driver)}>Assign sites</BoldButton>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => setDriverToRemove(driver)}>Remove</BoldButton>
                </div>
              </div>
            </td>
          </tr>)}
      </tbody>
    </Table>
  </Container>
};

export { DriverPage };
