import React, { useState } from 'react';
import KaAlert from '../Common/KaAlert';
import { Row, Table, Container, Nav, NavItem, NavLink, } from 'reactstrap';
import { CenteredSpinner } from '../Common/CenteredSpinner';
import { UserQuerySpec } from '../Common/useUserQuery';
import useDisplayMessage from '../Common/useDisplayMessage';
import OrderDto, { OrderDisplayDto } from '../Common/dtos/OrderDto'
import { useLogException } from '../Monitoring/useMonitoring';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import FilterInput, { textSatisfiesFilter } from '../FilterInput';
import OverflowTooltip from '../Common/OverflowTooltip';
import { customerDisplayName } from '../../Utilities';
import BoldButton from '../Common/BoldButton';
import { Button } from 'react-bootstrap';
import useKaEmployeeQuery from '../Common/useKaEmployeeQuery';
import { useKaEmployee } from '../UserAccess/useKaEmployee';
import { ViewOrder } from './ViewOrder';
import { OrderTransactionPage } from '../Orders/Transactions/OrderTransactionPage';

const apiPath = (businessId?: string): string => `/employeeApi/businesses/${businessId}/orders`;

const getSpec = (businessId?: string): UserQuerySpec => ({
  path: apiPath(businessId),
});

const EmployeeOrderPage = () => {
  const kaEmployee = useKaEmployee();
  const getOrders = useKaEmployeeQuery<OrderDisplayDto[]>(getSpec(kaEmployee.selectedBusiness?.id));
  const [filterText, setFilterText] = useState('');
  const displayMessage = useDisplayMessage();
  const logException = useLogException();

  const [viewOrder, setViewOrder] = useState<OrderDto | null>(null);

  const [showOrderTransactions, setShowOrderTransactions] = useState(false);
  const [orderForOrderTransactions, setOrderForOrderTransactions] = useState<OrderDto | undefined>(undefined);

  const [viewCompletedOrders, setViewCompletedOrders] = useState(false);

  const filterTextChanged = (text: string) => setFilterText(text);

  const containsFilterText = (order: OrderDisplayDto) => {
    const satisfiesFilter = textSatisfiesFilter(filterText);
    return satisfiesFilter(order.number) || satisfiesFilter(order.purchaseOrder) || satisfiesFilter(order.customerName)
      || satisfiesFilter(getOrderItems(order).join(", ")) || satisfiesFilter(order.siteNames.join(", "))
      || satisfiesFilter(viewCompletedOrders ? order.completedAt : order.createdAt);
  };

  const getOrderItems = (order: OrderDto) => order.blends.flatMap(b => b.items.map(i => i.name))

  const isComplete = (order: OrderDto) => order.stage === 'Complete';

  const viewClick = (order: OrderDto) => {
    displayMessage.clear();
    setViewOrder(order);
  }

  const showOrderTransactionClick = (order: OrderDto) => {
    setOrderForOrderTransactions(order);
    setShowOrderTransactions(true);
  }

  const setUnrecoverableDataError = (exception: Error, severityLevel?: SeverityLevel) => {
    setViewOrder(null);
    displayMessage.fail("Something is wrong with this order. Please contact Kahler Automation.");
    logException(exception, severityLevel);
  }

  if (getOrders.isLoading) return <CenteredSpinner />
  if (getOrders.isError) return <h3>{getOrders.error?.message}</h3>

  return <Container>
    {viewOrder !== null ? (
      <ViewOrder
        employee={kaEmployee}
        order={viewOrder!}
        onClose={() => setViewOrder(null)}
        setUnrecoverableDataError={setUnrecoverableDataError}
      />
    ) : showOrderTransactions && orderForOrderTransactions != null ? (
      <OrderTransactionPage
        profile={kaEmployee}
        order={orderForOrderTransactions}
        backClicked={() => setShowOrderTransactions(false)}
      />
    ) : (
      <>
        <Row className="justify-content-between mb-2">
          <h2 className="col-auto ka-blue">Orders</h2>
        </Row>
        <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
        <form>
          <div className="row gx-2 gx-md-3 mb-7">
            <div className="col-md-4 mb-2 mb-md-0">
              <FilterInput placeholder='Filter orders by keyword' onFilterTextChange={filterTextChanged} />
            </div>
          </div>
        </form>
        <Nav tabs className="d-flex justify-content-center">
          <NavItem>
            <NavLink active={!viewCompletedOrders} onClick={() => setViewCompletedOrders(false)}>
              Active
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={viewCompletedOrders} onClick={() => setViewCompletedOrders(true)}>
              Completed
            </NavLink>
          </NavItem>
        </Nav>
        <Table bordered>
          <thead>
            <tr>
              <th className="col col-2">Number</th>
              <th className="col col-3">Products</th>
              <th className="col col-3">Customer</th>
              <th className="col col-3">Sites</th>
              <th className="col col-3">{viewCompletedOrders ? "Completed" : "Created"}</th>
              <th className="col col-1"></th>
            </tr>
          </thead>
          <tbody>
            {getOrders.data
              ?.sort(byNumber)
              .filter(containsFilterText)
              .filter(o => viewCompletedOrders ? isComplete(o) : !isComplete(o))
              .map(order =>
                <tr key={order.id}>
                  <td>
                    {order.number}
                  </td>
                  <td>
                    <OverflowTooltip text={getOrderItems(order).join(", ")} />
                  </td>
                  <td>
                    <OverflowTooltip text={customerDisplayName(order.customerName, order.customerAccountNumber)} />
                  </td>
                  <td>
                    <OverflowTooltip text={order.siteNames.join(", ")} />
                  </td>
                  <td>
                    {viewCompletedOrders ? order.completedAt! : order.createdAt}
                  </td>
                  <td align="right">
                    <div className="dropdown">
                      <Button className="dropdown-toggle btn-ghost-secondary" variant="link" id="actionsMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                        <strong>Actions</strong>
                      </Button>
                      <div className="dropdown-menu" aria-labelledby="actionsMenuButton">
                        <BoldButton className="dropdown-item btn-ghost-secondary" onClick={() => viewClick(order)}>
                          View
                        </BoldButton>
                        {order.hasTransactions && <BoldButton className="dropdown-item btn-ghost-secondary" onClick={() => showOrderTransactionClick(order)}>View Transactions</BoldButton>}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
      </>
    )}
  </Container>;
}

const byNumber = (a: OrderDto, b: OrderDto) => a.number.localeCompare(b.number);

export { EmployeeOrderPage };