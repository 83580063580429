import React from 'react';
import KaModal from './KaModal';
import BoldButton from './BoldButton';

interface ConfirmProps {
  visible: boolean;
  title: string;
  body: string;
  onDismiss: () => void;
  onConfirm: () => void;
}

const Confirm = (props: ConfirmProps) => {
  return (<KaModal
    onHide={props.onDismiss}
    show={props.visible}
    title={props.title}
    body={props.body}
    footer={
      <div className="container">
        <div className="row justify-content-between">
          <BoldButton className='col-3 btn-ghost-secondary' onClick={props.onDismiss}>Cancel</BoldButton>
          <BoldButton className='col-3 btn-ghost-danger' onClick={props.onConfirm}>Continue</BoldButton>
        </div>
      </div>
    }
  />)
};

export default Confirm;