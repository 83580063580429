import React from "react";
import KaAlert from "../Common/KaAlert";
import KaModal from "../Common/KaModal";
import SpinnerButton from "../Common/SpinnerButton";
import useDisplayMessage from "../Common/useDisplayMessage";
import { HttpMethod } from "../Common/useFetch";
import useKaEmployeeRequest, { UserRequestSpec } from "../Common/useKaEmployeeRequest";
import { BusinessDto } from "./BusinessList";
import BoldButton from "../Common/BoldButton";

interface IProps {
  business: BusinessDto;
  onHide: () => void;
  onSuccess: (message: string) => void;
}

const postSpec = (businessId?: string): UserRequestSpec => ({
  path: `/employeeApi/businesses/${businessId}/disable`,
  method: HttpMethod.POST,
});

const DisableSites = (props: IProps) => {
  const requestSpec = postSpec(props.business.id);
  const displayMessage = useDisplayMessage();

  const useDisableSites = useKaEmployeeRequest<void>(requestSpec, {
    onSuccess: () => props.onSuccess("Sites disabled successfully"),
    onError: (e) => displayMessage.fail(e.message),
  });

  const onClose = () => {
    props.onHide();
    displayMessage.clear();
  }

  return <KaModal
    onHide={onClose}
    show={true}
    body={(<>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <div className="container">
        <div className="row justify-content-center">
          <label className="h2 mt-2">
            Are you sure you want to disable site connections for {props.business.name}?
          </label>
        </div>
        <div className="row">
          <label className="mt-4 h4 text-center">
            This business will no longer be functional
          </label>
        </div>
      </div>
    </>)
    }
    footer={
      <div className="container">
        <div className="row justify-content-between">
          <BoldButton className="col-3 btn btn-ghost-secondary" variant="link" onClick={() => props.onHide()}>
            Cancel
          </BoldButton>
          <SpinnerButton
            className="col-3 btn-ghost-primary"
            spinning={useDisableSites.isLoading}
            onClick={() => useDisableSites.request()} >
            Disable
          </SpinnerButton>
        </div>
      </div>
    }
  />
}

export default DisableSites;