import React from 'react';
import Button from 'react-bootstrap/Button';
import { ButtonProps } from 'react-bootstrap';

export default function BoldButton(props: ButtonProps) {
  return <Button
    {...props}
    variant="link"
  >
    <strong> {props.children} </strong>
  </Button>
}