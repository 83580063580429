import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CenteredSpinner } from '../Common/CenteredSpinner';
import { useUser } from '../UserAccess/useUser';
import useLocalStorage from '../Common/useLocalStorage';
import useUserQuery from '../Common/useUserQuery';
import useGetBulkProductAllocation, { BulkProductAllocationDto } from '../Requests/useGetBulkProductAllocation';
import ProductDto from '../Common/dtos/ProductDto';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip as ChartToolTip,
  Plugin as ChartPlugin,
  Legend,
  TooltipPositionerFunction,
  ChartType,
  BarElement,
  ChartOptions,
  Point,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { errorCircleIcon } from "../BootstrapIcons/ErrorIcons";
import { isMultiValue, KaSelect } from '../Common/KaSelect';
import KaModal from '../Common/KaModal';
import useGetSitesNonAdmin from '../Requests/useGetSitesNonAdmin';


export const units = ["Pound", "Gallon", "Ton"]

export const BulkProductAllocationPage = () => {
  const user = useUser();
  const business = user.selectedBusiness;
  const [widgetUnit] = useLocalStorage<string>("bulkProductAllocationUnit", units[0]);
  const [unit, setUnit] = useLocalStorage<string>("bulkProductAllocation-AdvancedViewUnit", widgetUnit ?? units[0]);
  const [selectedUnit, setSelectedUnit] = useState<string>(unit);

  const [widgetSite] = useLocalStorage<string | undefined>("bulkProductAllocationSite", 'AllSites');
  const [site, setSite] = useLocalStorage<string>("bulkProductAllocation-AdvancedViewSite", widgetSite ?? 'AllSites');
  const [selectedSite, setSelectedSite] = useState<string>(site);

  const [widgetProducts] = useLocalStorage<string[] | undefined>("bulkProductAllocationProducts", undefined);
  const [products, setProducts] = useLocalStorage<string[] | undefined>("bulkProductAllocation-AdvancedViewProducts", undefined);
  const [selectedProducts, setSelectedProducts] = useState<string[] | undefined>(products);

  const [data, setData] = useState<BulkProductAllocationDto[]>();
  const [showSettings, setShowSettings] = useState<boolean>(false);

  const getSites = useGetSitesNonAdmin();
  const getProducts = useUserQuery<ProductDto[]>({ path: `/api/businesses/${business?.id}/products` });

  const getBulkProductAllocation = useGetBulkProductAllocation({
    unit: unit ?? units[0],
    siteId: site,
    products: products,
  }, {
    onSuccess: (resp) => {
      setData(resp.bulkProductAllocations);
    }
  });

  if ((data === undefined && getBulkProductAllocation.isLoading))
    return <CenteredSpinner />

  var errorMessage = "";
  if (getSites.isError) { errorMessage = getSites.error!.message; }
  else if (getProducts.isError) { errorMessage = getProducts.error!.message; }
  else if (getBulkProductAllocation.isError) { errorMessage = getBulkProductAllocation.error!.message; }

  const sortedProducts = getProducts.data?.sort((a, b) => a.name.localeCompare(b.name));
  const selectedProductIds = sortedProducts?.filter(product => widgetProducts?.some(p => p === product.id))
    .concat(
      sortedProducts
        .filter(product => !widgetProducts?.some(p => p === product.id))
        .slice(0, 15 - (widgetProducts?.length ?? 0)))
    .map(p => p.id);

  if (products === undefined && selectedProductIds !== undefined) {
    setProducts(selectedProductIds);
    setSelectedProducts(selectedProductIds);
  }

  const Settings = (show: boolean, onClose: () => void) => {

    if (getSites.isLoading || getProducts.isLoading) return <KaModal
      onHide={onClose}
      show={show}
      title={'Bulk Product Allocation Settings'}
      body={(<div className="mt-3">
        <CenteredSpinner />
      </div>)}
    />

    if (getSites.isError || getProducts.isError) return <KaModal
      onHide={onClose}
      show={show}
      title={'Bulk Product Allocation Settings'}
      body={(<div className="mt-3">
        <h3>{getSites.error?.message ?? getProducts.error?.message}</h3>
      </div>)}
    />


    const sortedSites = getSites.data?.sort((a, b) => a.name.localeCompare(b.name));
    const sortedSiteOptions = sortedSites?.map(s => ({ value: s.id, label: s.name })) ?? [];
    const AggregateSite = { value: 'AllSites', label: "All Sites" };
    const siteOptions = [AggregateSite].concat(sortedSiteOptions);

    const sortedProducts = getProducts.data?.sort((a, b) => a.name.localeCompare(b.name));
    const productOptions = sortedProducts?.map(p => ({ value: p.id, label: p.name })) ?? [];
    const selectedProductOptions = productOptions.filter(po => products?.some(p => p === po.value));

    const saveSettings = () => {
      setUnit(selectedUnit ?? units[0]);
      setSite(selectedSite);
      setProducts(selectedProducts);
      onHide();
    }

    const onHide = () => {
      setSelectedProducts(products);
      onClose();
    }

    return (<KaModal
      onHide={onHide}
      show={show}
      title={'Bulk Product Allocation Settings'}
      body={(<>
        <div className="row mb-3">
          <h5 className="w-100 d-flex justify-content-center">
            The bulk product allocation display shows allocated quantities for open orders versus on-hand inventory at a site.
          </h5>
        </div>
        <div className="row">
          <div className="col">
            <KaSelect
              placeholder="Select Site"
              options={siteOptions}
              onChange={(valueObject) => {
                if (!isMultiValue(valueObject)) { setSelectedSite(valueObject?.value!); }
              }}
              defaultValue={siteOptions.find((option) => option.value === site!)} />
          </div>
          <div className="col">
            <KaSelect
              placeholder="Select Unit"
              options={units.map(u => ({ value: u, label: u }))}
              onChange={(valueObject) => {
                if (!isMultiValue(valueObject)) { setSelectedUnit(valueObject?.value ?? units[0]); }
              }}
              defaultValue={{ value: unit, label: unit }} />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <div className="w-100">
            <h5 className="w-100 d-flex justify-content-center">Select up to 25 Products</h5>
            <KaSelect
              placeholder="Select Products..."
              isClearable={true}
              closeMenuOnSelect={false}
              isMulti={true}
              options={productOptions}
              defaultValue={selectedProductOptions}
              isOptionDisabled={() => {
                if (selectedProducts) return (selectedProducts!.length >= 25)
                else return true;
              }}
              onChange={(valueObject) => {
                if (isMultiValue(valueObject)) { setSelectedProducts(valueObject?.map(p => p.value)) }
              }} />
          </div>
        </div>
      </>)}
      footer={(
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => saveSettings()}>
          Save
        </button>)}
    />)
    }

  const chartHeight = window.screen.height - 310;

  return <>
    <div className="ms-n2 mt-n4 pb-3 me-5 row justify-content-between">
      {Settings(showSettings, () => setShowSettings(false))}
      <NavLink className="card-link text-nowrap col-auto mb-auto" to="/" style={{ fontSize: 20 }}>
        <i className="bi-chevron-left" />
        Bulk Product Allocation
      </NavLink>
      <button className="btn col-auto py-0 px-2" onClick={() => setShowSettings(true)} data-testid="settings">
        <i className="bi bi-gear ka-blue" style={{ fontSize: "2em" }} />
      </button>
    </div>
    <div className="w-100" style={{ height: chartHeight }}>
      <div className="h-100 d-flex justify-content-center">
        {data
          ? <BulkProductAllocationPageDisplay data={data!} unit={unit.concat('s')} />
          : <div className="col h-100 d-flex align-items-center" style={{ color: 'rgba(237, 71, 52)' }}>
            <div className="w-100 mx-4">
              <div className="row text-center">
                <i className="bi bi-x-circle-fill fs-1"></i>
              </div>
              <div className="row text-center fs-5">
                {errorMessage}
              </div>
            </div>
          </div>}
      </div>
    </div>
  </>
};

declare module 'chart.js' {
  interface TooltipPositionerMap {
    CustomPositioner: TooltipPositionerFunction<ChartType>;
  }
}

const BulkProductAllocationPageDisplay = ({ data, unit }: { data: BulkProductAllocationDto[], unit: string }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    ChartToolTip,
  );

  const labels = data.map((product) => product.name);
  const overAllocatedBars: Array<number> = [];

  const openOrderAllocationData = data.map((product, i) => {
    if (!product.openOrderAllocation || product.openOrderAllocation < 0) { return 0; }
    if (product.currentInventory! >= product.openOrderAllocation!) { return product.openOrderAllocation!; }
    overAllocatedBars.push(i);
    return product.currentInventory!;
  });

  const currentInventoryData = data.map((product) => {
    if (!product.currentInventory || product.currentInventory < 0) { return 0; }
    if (product.currentInventory! >= product.openOrderAllocation!) { return product.currentInventory! - product.openOrderAllocation!; }
    return product.openOrderAllocation! - product.currentInventory!;
  });

  const errorBars: Array<number> = [];
  data.forEach((dto, i) => {
    if (dto.error) {
      errorBars.push(i);
    }
  })

  const chartData: any = {
    labels,
    datasets: [
      {
        label: 'Allocated to Open Orders',
        data: openOrderAllocationData,
        borderWidth: 0,
        backgroundColor: openOrderAllocationData.map((_, i) => overAllocatedBars.includes(i) ? 'rgba(237, 71, 52)' : 'rgb(141,183,225)'),
        categoryPercentage: 0.95,
        barPercentage: 0.8,
        errorBars: errorBars,
      },
      {
        label: 'Current Inventory',
        data: currentInventoryData,
        borderWidth: 0,
        backgroundColor: currentInventoryData.map((_, i) => overAllocatedBars.includes(i) ? 'rgb(246,163,154)' : 'rgba(27, 110, 194)'),
        categoryPercentage: 0.95,
        barPercentage: 0.8,
      },
    ],
  };

  ChartToolTip.positioners.CustomPositioner = function (_, position: Point) { return position; }

  const WarningDataLabel = (data: BulkProductAllocationDto[]): ChartPlugin => {
    return {
      id: "WarningDataLabel",
      afterDatasetDraw: (chart: ChartJS) => {
        const datasets: any = chart.data.datasets;
        var errorBars: Array<number> = datasets[0].errorBars;
        errorBars.forEach(i => {
          const ctx = chart.ctx as CanvasRenderingContext2D;

          const point = chart.getDatasetMeta(0).data[i];
          const errorIconBase64 = btoa(errorCircleIcon);
          const img = new Image();
          img.src = `data:image/svg+xml;base64,${errorIconBase64}`;

          ctx.drawImage(img, point.x - 5, point.y - 25);
        });
      }
    }
  }

  const chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    interaction: {
      mode: 'nearest' as const,
      axis: 'x' as const,
      intersect: false
    },
    layout: {
      padding: {
        bottom: 2,
      }
    },
    scales: {
      x: {
        stacked: 'single' as const,
        grid: {
          drawTicks: false
        }
      },
      y: {
        stacked: 'single' as const,
        grid: {
          drawTicks: false
        },
      }
    } as any,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
          generateLabels: (chart) => {
            return [
              {
                datasetIndex: 0,
                text: "Allocated to Open Orders",
                fontColor: '#666',
                fillStyle: 'rgb(141,183,225)',
                strokeStyle: 'rgb(141,183,225)',
                hidden: !chart.getDatasetMeta(0).visible,
              },
              {
                datasetIndex: 1,
                text: "Current Inventory",
                fontColor: '#666',
                fillStyle: 'rgba(27, 110, 194)',
                strokeStyle: 'rgba(27, 110, 194)',
                hidden: !chart.getDatasetMeta(1).visible,
              }
            ];
          }
        },
        onClick: function (e: any) { return; }
      },
      tooltip: {
        position: 'CustomPositioner' as const,
        enabled: false,
        external: function (context) {
          var tooltipEl = document.getElementById('chartjs-tooltip-bulkProductAllocationPage');

          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.className = "bg-white border border-2 rounded-3 chart-tooltip";
            tooltipEl.id = 'chartjs-tooltip-bulkProductAllocationPage';
            tooltipEl.style.transition = 'all .1s ease';
            document.body.appendChild(tooltipEl);
          }

          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          } else {
            tooltipEl.style.opacity = "1";
          }

          const currentProduct = data.find((dp) => dp.name === tooltipModel.title[0]);

          if (tooltipModel.body && currentProduct?.error) {

            tooltipEl.innerHTML = `
              <table>
                <thead>
                  <tr>
                    <th class="ka-blue fs-5 fw-bold">
                      ${tooltipModel.title[0]}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span style="color:rgba(237, 71, 52);">
                        <i class="bi bi-x-circle-fill pe-1"></i>Display Error: ${currentProduct?.error}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>`;

          } else if (tooltipModel.body) {
            const tableHeadHtml = `
                <tr>
                  <th>
                    <span class="ka-blue fs-5 fw-bold pe-3">${tooltipModel.title[0]}</span>
                    <span class="text-secondary" style="float:right;">(${unit})</span>
                  </th>
                </tr>`;

            const barIndex = context.tooltip.dataPoints[0].dataIndex;
            const barColors = [
              overAllocatedBars.includes(barIndex) ? 'rgb(246,163,154)' : 'rgb(141,183,225)',
              overAllocatedBars.includes(barIndex) ? 'rgba(237, 71, 52)' : 'rgba(27, 110, 194)'
            ]

            var tableBodyHtml = `
              <tr>
                <td>
                  <i class="bi bi-circle-fill me-1" style="color: ${barColors[0]}"></i>
                  <span class="ka-blue pe-3">Allocated to Open Orders:
                    <span style="float:right;">${currentProduct?.openOrderAllocation?.toLocaleString()}</span>
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <i class="bi bi-circle-fill me-1" style="color: ${barColors[1]}"></i>
                  <span class="ka-blue pe-3">Current Inventory:
                    <span style="float:right;">${currentProduct?.currentInventory?.toLocaleString()}</span>
                  </span>
                </td>
              </tr>`;

            const remainingInventory = (currentProduct?.currentInventory ?? 0) - (currentProduct?.openOrderAllocation ?? 0);
            const remainingInventoryStyle = 'float:right;' + (remainingInventory < 0 ? 'color: rgba(237, 71, 52)' : '')
            const subtractionRowHtml = `
              <tr>
                <td>
                  <hr class="text-dark" style="margin: 2px -3px 5px 20px">
                  <i class="bi bi-circle-fill me-1" style="color: rgba(0, 0, 0, 0)"></i>
                  <span class="text-secondary pe-3">After Order Fulfillment:
                    <span style="${remainingInventoryStyle}">${remainingInventory.toLocaleString()}</span>
                  </span>
                </td>
              </tr>`;
            tableBodyHtml += subtractionRowHtml

            tooltipEl.innerHTML = `
                <table class="w-auto">
                  <thead>
                    ${tableHeadHtml}
                  </thead>
                  <tbody>
                    ${tableBodyHtml}
                  </tbody>
                </table>
              `;
          }

          const position = context.chart.canvas.getBoundingClientRect();
          const mousePositionLeft = position.left + window.pageXOffset;
          const mousePositionTop = position.top + window.pageYOffset;
          const canvasRenderedWidth = context.chart.chartArea.right - context.chart.chartArea.left;

          var tooltipLeft;
          var tooltipTop;
          if (canvasRenderedWidth - tooltipModel.caretX < 85) {
            // Tooltip to the left
            tooltipLeft = mousePositionLeft + tooltipModel.caretX - tooltipEl.clientWidth - 10 + 'px';
            tooltipTop = mousePositionTop + tooltipModel.caretY - (tooltipEl.clientHeight / 2) + 'px';
          } else {
            // Tooltip up
            tooltipLeft = mousePositionLeft + tooltipModel.caretX - (tooltipEl.clientWidth / 2) + 'px';
            tooltipTop = mousePositionTop + tooltipModel.caretY - tooltipEl.clientHeight - 10 + 'px';
          }

          tooltipEl.style.opacity = "1";
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = tooltipLeft;
          tooltipEl.style.top = tooltipTop;
          tooltipEl.style.padding = '10px';
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.zIndex = "9999";

        }
      }
    },
  };

  return <Bar options={chartOptions} data={chartData} plugins={[ChartToolTip, WarningDataLabel(data)]} />
}
