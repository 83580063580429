import { Configuration, RedirectRequest } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: "4821e775-f080-44af-ab56-38905905a3be",
    authority: "https://KahlerAutomationB2C.b2clogin.com/KahlerAutomationB2C.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
    knownAuthorities: ["KahlerAutomationB2C.b2clogin.com"],
    redirectUri: "/",
    postLogoutRedirectUri: "/"
  }
};

const scopes: Array<string> =
  ["openid", "https://KahlerAutomationB2C.onmicrosoft.com/4821e775-f080-44af-ab56-38905905a3be/All"];

export const customerSignInRequest: RedirectRequest = {
  scopes
};

export const kaEmployeeSignInRequest: RedirectRequest = {
  scopes,
  authority: "https://KahlerAutomationB2C.b2clogin.com/KahlerAutomationB2C.onmicrosoft.com/b2c_1_Kahler_Employee"
};

export const forgotPasswordRequest: RedirectRequest = {
  scopes,
  authority: "https://KahlerAutomationB2C.b2clogin.com/KahlerAutomationB2C.onmicrosoft.com/b2c_1_reset"
}
