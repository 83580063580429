import React, { useRef, useState } from 'react';
import KaAlert from '../Common/KaAlert'
import KaModal from '../Common/KaModal';
import SpinnerButton from '../Common/SpinnerButton';
import useDisplayMessage from '../Common/useDisplayMessage';
import { KaEmployeeProfile } from '../UserAccess/UserContext';
import { BusinessDto } from './BusinessList';

interface IProps {
  kaUser: KaEmployeeProfile;
  business: BusinessDto;
  onHide: () => void;
  onSuccess: (message: string) => void;
}

export const ImportDrivers = (props: IProps) => {
  const displayMessage = useDisplayMessage();
  const [isLoading, setIsLoading] = useState(false);

  const fileInput = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const input = (event.currentTarget as (HTMLInputElement));
    setFile(input.files?.item(0));
  }

  const onClose = () => {
    props.onHide();
    displayMessage.clear();
  }

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const url = `/api/businesses/${props.business.id}/drivers/import`;
    const headers = await props.kaUser.fetchHeaders();

    const formData = new FormData();
    formData.append('file', file!);
    formData.append('fileName', file!.name);

    setIsLoading(true);
    try {
      var response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers
      });

      setIsLoading(false);
      if (response.ok) {
        props.onSuccess("Drivers imported successfully");
      } else {
        const errorBody = (await response.json()) as any;
        displayMessage.fail(errorBody.message);
      }
    } catch (err) {
      setIsLoading(false);
      displayMessage.fail((err as Error)?.message ?? "Unknown error");
    } finally {
      if (fileInput.current) {
        fileInput.current.value = "";
        setFile(null);
      }
    }
  }

  return <KaModal
    onHide={onClose}
    show={true}
    title={`Import drivers for ${props.business.name}`}
    body={
      <>
        <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
        <label htmlFor="fileUpload" className="form-label-lg">CSV File</label>
        <input
          type="file"
          id="fileUpload"
          ref={fileInput}
          className="form-control"
          onChange={handleChange} />
      </>
    }
    footer={
      <SpinnerButton
        className="btn-ghost-primary"
        disabled={file == null}
        spinning={isLoading}
        onClick={handleSubmit} >
        Import
      </SpinnerButton>
    }
  />;
}