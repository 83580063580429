import React, { useContext, useState } from 'react';
import { CenteredSpinner } from '../Common/CenteredSpinner';
import { NavLink } from 'react-router-dom';
import { SortableItem } from '../Common/Sortable/SortableItem';
import { UniqueIdentifier } from '@dnd-kit/core';
import { DashboardContext } from './DashboardContext';

type Props = {
  widgetId: UniqueIdentifier;
  title: string;
  path?: string;
  isLoading: Boolean;
  children: React.ReactNode;
  settings?: (show: boolean, onClose: () => void) => JSX.Element;
}

export const WidgetDisplay: React.FC<Props> = ({ widgetId, title, path, isLoading, children, settings }) => {

  const [showSettings, setShowSettings] = useState<boolean>(false);
  const toggleModal = () => {
    setShowSettings(!showSettings);
  }

  return <>
    {settings && settings(showSettings, () => setShowSettings(false))}
    <Widget
      widgetId={widgetId}
      title={title}
      path={path}
      isLoading={isLoading}
      children={children}
      settings={settings}
      toggleModal={toggleModal} />
  </>
}

type WidgetProps = Props & {
  toggleModal: () => void
}

const Widget: React.FC<WidgetProps> = React.memo(({ widgetId, title, path, isLoading, children, settings, toggleModal }) => {

  const dashboard = useContext(DashboardContext);
  const widget = dashboard.widgets.find((w) => w.id === widgetId);

  return <SortableItem key={widgetId.toString()} id={widgetId.toString()} handle={false}>
    <div className="widget card h-100 border position-relative" ref={widget?.componentRef}>
      {settings &&
        <button className="btn m-0 position-absolute top-0 end-0 translate-end" onClick={() => toggleModal()} data-testid="settings">
          <i className="bi bi-gear ka-blue" style={{ fontSize: "1.25em" }} />
        </button>}
      <div className="card-header card-header-content-sm-between mb-0 pb-0 pt-3">
        <h4 className="card-header-title text-center ka-blue">{title}</h4>
      </div>
      <hr className="hr mt-2 mb-2 mx-5" />
      <NavLinkWrapper path={path}>
        <div className="card-body py-0 px-2 mt-0 h-100">
          {isLoading ?
            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
              <CenteredSpinner />
            </div>
            : children
          }
        </div>
      </NavLinkWrapper>
    </div>
  </SortableItem>
})

const NavLinkWrapper = ({ path, children }: { path?: string, children: React.ReactNode }) => {
  return (path !== undefined ?
    <NavLink to={path} className="advanced-view text-decoration-none text-reset">
      <div className="btn m-0 position-absolute bottom-0 end-0 translate-end">
        <i className="bi bi-box-arrow-in-down-right ka-blue" style={{ fontSize: "1.5em" }} />
      </div>
      {children}
    </NavLink>
    : <>{children}</>)
};