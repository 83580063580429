import React, { useState } from "react";
import { Container, Row, Table } from "reactstrap";
import { CenteredSpinner } from "../Common/CenteredSpinner";
import RecipeDto from "../Common/dtos/RecipeDto";
import KaAlert from "../Common/KaAlert";
import { SiteAssignmentForm } from "../Common/SiteAssignment/SiteAssignmentForm";
import useDisplayMessage from "../Common/useDisplayMessage";
import useUserQuery, { UserQuerySpec } from "../Common/useUserQuery";
import FilterInput, { textSatisfiesFilter } from "../FilterInput";
import { useUser } from "../UserAccess/useUser";
import { EditRecipe } from "./EditRecipe";
import BoldButton from "../Common/BoldButton";
import { Button } from "react-bootstrap";
import useUserRequest, { UserRequestSpec } from "../Common/useUserRequest";
import { HttpMethod } from "../Common/useFetch";
import Confirm from "../Common/Confirm";

enum States {
  None,
  AddEditRecipe,
  AssignSites,
}

type PopupState =
  {
    state: States.None;
    recipe?: null;
  } |
  {
    state: States.AddEditRecipe;
    recipe: RecipeDto | null;
  } |
  {
    state: States.AssignSites;
    recipe: RecipeDto;
  };

const apiPath = (businessId?: string): string => `/api/businesses/${businessId}/recipes`;
const getSpec = (businessId?: string): UserQuerySpec => ({
  path: apiPath(businessId),
});

const apiGetSiteAssignmentPath = (businessId?: string, recipeId?: string): string =>
  `${apiPath(businessId)}/${recipeId}/siteAssignment`;
const apiSetSiteAssignmentPath = (businessId?: string, recipeId?: string): string =>
  `${apiPath(businessId)}/${recipeId}/assignToSites`;

const removeRequestSpec = (businessId?: string, recipeId?: string): UserRequestSpec => ({
  path: `${apiPath(businessId)}/${recipeId}`,
  method: HttpMethod.DELETE,
});

const alphabeticallyByName = (a: RecipeDto, b: RecipeDto) => a.name.localeCompare(b.name);

const RecipePage = () => {
  const user = useUser();
  const displayMessage = useDisplayMessage();
  const [filterText, setFilterText] = useState('');
  const [popupState, setPopupState] = useState<PopupState>({ state: States.None });
  const [assignSitesForRecipeId, setAssignSitesForRecipeId] = useState<string | undefined>();
  const [recipeToRemove, setRecipeRemove] = useState<RecipeDto | undefined>(undefined);

  const getRecipes = useUserQuery<RecipeDto[]>(getSpec(user.selectedBusiness?.id), {
    onSuccess: (data) => {
      if (assignSitesForRecipeId && data) {
        const recipeToAssign = data.find(r => r.id === assignSitesForRecipeId);

        recipeToAssign
          ? assignSitesClick(recipeToAssign)
          : displayMessage.fail(
            "Could not assign sites because the recipe was not found")

        setAssignSitesForRecipeId(undefined);
      }
    }
  });

  const containsFilterText = (recipe: RecipeDto) => {
    const satisfiesFilter = textSatisfiesFilter(filterText);
    return satisfiesFilter(recipe.name) || satisfiesFilter(recipe.epaNumber) || satisfiesFilter(recipe.ticketNotes);
  };

  const filterTextChanged = (text: string) => setFilterText(text);

  const createClicked = () => {
    displayMessage.clear();
    setPopupState({ state: States.AddEditRecipe, recipe: null });
  }

  const editClicked = (recipe: RecipeDto) => {
    displayMessage.clear();
    setPopupState({ state: States.AddEditRecipe, recipe: recipe });
  }

  const recipeUpserted = (recipeId: string, recipeName: string, assignSites: boolean) => {
    displayMessage.success(`Recipe ${recipeName} ${popupState.recipe ? 'updated' : 'created'}`);
    if (assignSites) setAssignSitesForRecipeId(recipeId);
    getRecipes.query();
  }

  const editDismissed = () => setPopupState({ state: States.None });

  const assignSitesClick = (recipe: RecipeDto) => {
    displayMessage.clear();
    setPopupState({ state: States.AssignSites, recipe: recipe });
  }

  const assignSitesSuccess = () => {
    displayMessage.success(`Sites successfully updated for ${popupState.recipe?.name}`);
    assignSitesClosed();
  }

  const assignSitesClosed = () => setPopupState({ state: States.None });

  const removeSuccess = () => {
    displayMessage.success(`Recipe ${recipeToRemove?.name} removed`);
    return getRecipes.query();
  };

  const removeRequest = useUserRequest<void, void>(
    removeRequestSpec(user.selectedBusiness?.id, recipeToRemove?.id), {
    onSuccess: removeSuccess,
    onError: (err) => displayMessage.fail(err.message),
    onComplete: () => setRecipeRemove(undefined),
  });

  if (getRecipes.isLoading || removeRequest.isLoading) return <CenteredSpinner />
  if (getRecipes.isError) return <h3>{getRecipes.error?.message}</h3>

  return <Container>
    {popupState.state === States.AddEditRecipe && <EditRecipe
      user={user}
      initialRecipe={popupState.recipe}
      onUpsert={recipeUpserted}
      onDismiss={editDismissed} />}
    {popupState.state === States.AssignSites &&
      <SiteAssignmentForm
        title={`Select sites for ${popupState.recipe?.name}`}
        getAssignedSitesPath={apiGetSiteAssignmentPath(user.selectedBusiness?.id, popupState.recipe?.id)}
        assignSitePath={apiSetSiteAssignmentPath(user.selectedBusiness?.id, popupState.recipe?.id)}
        onHide={assignSitesClosed}
        onSuccess={assignSitesSuccess} />
    }
    <Confirm
      visible={!!recipeToRemove}
      title='Remove recipe'
      body={`When a recipe is removed it is no longer available to dispense. 
               The recipe cannot be restored. 
               Order transactions will not be removed but will no longer link to this recipe.
               Are you sure that you want to remove recipe ${recipeToRemove?.name}?`}
      onDismiss={() => setRecipeRemove(undefined)}
      onConfirm={() => removeRequest.request()} />
    <Row className='justify-content-between mb-2'>
      <h2 className='col-auto ka-blue'>Recipes</h2>
      <BoldButton className='col-auto btn-ghost-primary' onClick={createClicked}>Create Recipe</BoldButton>
    </Row>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <form>
      <div className='row gx-2 gx-md-3 mb-7'>
        <div className='col-md-4 mb-2 mb-md-0'>
          <FilterInput placeholder='Filter recipes by keyword' onFilterTextChange={filterTextChanged} />
        </div>
      </div>
    </form>
    <Table bordered>
      <thead><tr><th>Name</th><th></th></tr></thead>
      <tbody>
        {getRecipes.data?.filter(containsFilterText).sort(alphabeticallyByName).map(recipe =>
          <tr key={recipe.id}>
            <td>{recipe.name}</td>
            <td align="right">
              <div className='dropdown'>
                <Button id='actionsMenuButton'
                  className='dropdown-toggle btn-ghost-secondary'
                  variant='link'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'>
                  <strong>Actions</strong>
                </Button>
                <div className='dropdown-menu' aria-labelledby='actionsMenuButton'>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => editClicked(recipe)}>Edit</BoldButton>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => assignSitesClick(recipe)}>Assign sites</BoldButton>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => setRecipeRemove(recipe)}>Remove</BoldButton>
                </div>
              </div>
            </td>
          </tr>)}
      </tbody>
    </Table>
  </Container>;
};

export { RecipePage };
