import React, { useContext } from 'react';
import { UserContext, UserStatus, LoginType } from './UserContext';

type Props = {
  children: React.ReactNode;
}

export const KaEmployeeTemplate: React.FC<Props> = ({ children }) => {
  const userStatus = useContext<UserStatus>(UserContext);
  return (<>
    {userStatus.user?.userType === LoginType.KaEmployee && children}
  </>);
}