import React, { useContext } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { NavMenu } from './NavMenu';
import { NavSideMenu } from './Navigation/NavSideMenu';
import { UserContext, LoginType } from './UserAccess/UserContext';

const DisabledAlert = () =>
  <div className="alert alert-soft-danger mt-n5 mb-5 mx-n1" role="alert">
    Communication with sites has been interrupted. Please contact Kahler Automation for more details.
  </div>

const SandboxAlert = () =>
  <div className="alert alert-soft-danger mt-n5 mb-5 mx-n1" role="alert">
    Sandbox Testing Version
  </div>


type Props = {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
  const { user } = useContext(UserContext);

  return <>
    <NavMenu />
    <main id="content" role="main" className="navbar-sidebar-aside-sm">
      {useIsAuthenticated() &&
        <NavSideMenu />
      }
      <div className="navbar-sidebar-aside-content content-space-t-1 content-space-b-2 px-lg-5">
        {user?.userType === LoginType.User && user.selectedBusiness?.disabled && <DisabledAlert />}
        {user?.userType === LoginType.User && user.selectedBusiness?.sandbox && <SandboxAlert />}
        {children}
      </div>
    </main>
  </>
}

export { Layout };
