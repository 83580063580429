import CustomerDto from "./components/Common/dtos/CustomerDto";

export function toDisplayFromCamelCase(string: string) {
  return capitalizeFirstLetter(string.replace(/([A-Z])/g, ' $1'))
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function customerDisplayNameFromDto(customer: CustomerDto) {
  return customerDisplayName(customer.name, customer.accountNumber);
}

export function customerDisplayName(name: string, accountNumber: string | undefined) {
  return name + (accountNumber ? ` - ${accountNumber}` : '');
}

export function joinNonEmptyString(array: (string | null | undefined)[], joinString: string = ", ") {
  return array.filter(i => [i].join(" ").trim().length > 0).join(joinString);
}

export function removeEmptyFields(obj: any) {
  for (var propName in obj) {
    // Short circuit so that 0 isn't removed
    if (typeof obj[propName] === 'number') { continue; }

    if (!obj[propName] || obj[propName] === "00000000-0000-0000-0000-000000000000") {
      delete obj[propName];
    }
  }
  return obj;
}
