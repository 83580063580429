export enum UnitType {
  Mass,
  Volume
}

export default interface Unit {
  value: string;
  name: string;
  type: UnitType;
}
