import useQuery, { Query, QueryOptions } from './useQuery';
import { useUser } from '../UserAccess/useUser';

export interface UserQuerySpec {
    path: string;
}

export default function useUserQuery<Resp>(spec: UserQuerySpec, options?: QueryOptions<Resp>): Query<Resp> {
    const user = useUser();
    return useQuery<Resp>({
        path: spec.path,
        headers: user.fetchHeaders,
    }, options);
}