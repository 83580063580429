import React, { useState } from "react";
import { Path, useFieldArray, useForm } from "react-hook-form";
import { FormTextControl, RowFormText } from "../Common/Forms/FormText";
import KaAlert from "../Common/KaAlert";
import KaModal from "../Common/KaModal";
import SpinnerButton from "../Common/SpinnerButton";
import useDisplayMessage from "../Common/useDisplayMessage";
import { TransportDto } from "../../requests/useGetTransports";
import useUpsertTransport, { TransportUpsertDto } from "../../requests/useUpsertTransport";
import { RowUnitsSelect, UnitsSelectControl } from "../Units/UnitSelect";
import { UnitType } from "../Common/dtos/UnitDto";
import BoldButton from "../Common/BoldButton";
import { Row } from "react-bootstrap";
import { Label } from "reactstrap";

interface CreateEditTransportProps {
  initialTransport: TransportDto | null;
  onDismiss: () => void;
  onUpsert: (transportId: string, transportName: string, assignSites: boolean) => void;
}

export const CreateEditTransport = (props: CreateEditTransportProps) => {
  const displayMessage = useDisplayMessage();
  const { id, ...iniTransport } = { ...props.initialTransport };
  const { register, handleSubmit, getValues, setValue, control, formState: { errors } } = useForm<TransportUpsertDto>({
    defaultValues: { ...iniTransport }
  });
  const [assignSites, setAssignSites] = useState(false);

  const compartments = useFieldArray({ control, name: 'compartments' });

  const addCompartment = () => {
    compartments.append({ capacity: 0, capacityUnit: 'Pound' });
  }

  const dismissed = () => {
    props.onDismiss();
  };

  const upsert = useUpsertTransport(props.initialTransport?.id, {
    onSuccess: response => {
      props.onDismiss();
      const transportId = props.initialTransport ? props.initialTransport.id : response;
      props.onUpsert(transportId, getValues().name, assignSites);
    },
    onError: (e) => displayMessage.fail(e.message),
  });

  const submit = (formValues: TransportUpsertDto, assign: boolean) => {
    const upsertDto = {
      ...formValues,
      maxWeight: formValues.maxWeight ? Number(formValues.maxWeight) : undefined,
      number: formValues.number ? formValues.number : undefined,
    };

    setAssignSites(assign);
    upsert.request(upsertDto);
  }

  const formProps = (name: Path<TransportUpsertDto>) => ({
    labelSpace: 4,
    inputSpace: 8,
    name: name,
    setValue,
    register,
    errors,
  });

  return <KaModal
    title={`${props.initialTransport ? 'Update' : 'Create'} a transport`} onHide={dismissed} show={true}
    body={(<>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <RowFormText {...formProps('name')}
        options={{
          required: 'Name is required',
          validate: { length: v => v.length < 51 || 'Name cannot be longer than 50 characters' }
        }} />
      <RowFormText {...formProps('number')}
        options={{
          validate: { length: v => v.length < 51 || 'Number cannot be longer than 50 characters' }
        }} />
      <RowFormText {...formProps('maxWeight')}
        options={{
          validate: {
            number: v => v ? !isNaN(Number(v)) || 'Must be a number' : true,
            greaterThanZero: v => v ? v > 0 || 'Must be a non-zero amount' : true,
            maxWeight: v => v ? v <= 100000000 || 'Max weight must be less than 10,000,000' : true,
          }
        }}
      />
      <RowUnitsSelect
        id='weightUnit'
        unitType={UnitType.Mass}
        {...formProps('weightUnit')}
        selectedValue={getValues('weightUnit')}
      />
      <div className='row pe-2'>
        <Label className="col-form-label col">Compartments:</Label>
        {
          compartments.fields.length === 0
            ? <div className="col-8 ps-4 pt-2">No compartments</div>
            : compartments.fields.map((compartment, index) => {
              return <Row key={compartment.id} className="mb-2">
                <div className="col-1" />
                <div className="col-2 ps-4">
                  <label className="col-form-label form-label" htmlFor={`compartments.${index}.capacity`}>
                    {`${index + 1}:`}
                  </label>
                </div>
                <div className="col-4">
                  <FormTextControl {...formProps(`compartments.${index}.capacity`)}
                    options={{
                      valueAsNumber: true,
                      validate: {
                        number: v => !isNaN(Number(v)) || 'Must be a number',
                        greaterThanZero: v => v > 0 || 'Must be a non-zero amount',
                        maxWeight: v => v ? v <= 100000000 || 'Must be less than 10,000,000' : true,
                      }
                    }}
                  />
                </div>
                <div className="col-4">
                  <UnitsSelectControl
                    id={`compartment${index}`}
                    unitType={UnitType.Mass}
                    {...formProps(`compartments.${index}.capacityUnit`)}
                    selectedValue={getValues(`compartments.${index}.capacityUnit`)} />
                </div>
                <div className="col-1 pe-0">
                  <BoldButton className='btn-close btn-sm mt-2' onClick={() => compartments.remove(index)} />
                </div>
              </Row>
            })
        }
        <Row className="justify-content-end pt-2">
          <BoldButton
            className='col-auto btn-ghost-primary'
            disabled={compartments.fields.length >= 8}
            onClick={addCompartment}>
            Add compartment
          </BoldButton>
        </Row>
      </div>
    </>)}
    footer={(
      <div className="btn-group">
        <SpinnerButton
          className="w-25 m-1 rounded btn-ghost-primary"
          spinning={upsert.isLoading && !upsert.isError}
          onClick={handleSubmit(values => submit(values, false))}>
          {`${props.initialTransport ? 'Update' : 'Create'}`}
        </SpinnerButton>
        <SpinnerButton
          className="w-25 m-1 rounded btn-ghost-primary"
          spinning={upsert.isLoading && !upsert.isError}
          onClick={handleSubmit(values => submit(values, true))}>
          {`${props.initialTransport ? 'Update and Assign' : 'Create and Assign'}`}
        </SpinnerButton>
      </div>)
    }
  />
};
