import { UserBusiness } from './UserContext';
import { useUser } from './useUser';

export const useSelectedBusiness = (): UserBusiness => {
  const user = useUser();

  if (!user.selectedBusiness)
    throw new Error("useSelectedBusiness hook can only be used inside of components contained inside an BusinessTemplate component");

  return user.selectedBusiness;
}
