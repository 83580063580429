import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { SiteAssignmentDto } from '../dtos/SiteAssignmentsDto'

export interface SiteAssignmentProps {
  sites: SiteAssignmentDto[],
  assignedSiteIds: string[],
  assignedSitesChanged: (assignedSites: string[]) => void,
}

export function SiteAssignment(props: SiteAssignmentProps) {
  const isSiteUnavailable = (s: SiteAssignmentDto): boolean =>
    !!s.unassignableReasons && s.unassignableReasons.length > 0;

  const availableSites = props.sites.filter(site => !isSiteUnavailable(site));

  const [selectAll, setSelectAll] = useState(!!availableSites.length && props.assignedSiteIds.length === availableSites.length);

  const assignedSiteCheckedChanged = (checked: boolean, siteId: string) => {
    var assignedSiteIds = [...props.assignedSiteIds];

    if (checked) {
      assignedSiteIds.push(siteId);
    } else {
      const siteIndex = assignedSiteIds.indexOf(siteId);
      assignedSiteIds.splice(siteIndex, 1);
    }

    props.assignedSitesChanged(assignedSiteIds);
    setSelectAll(assignedSiteIds.length === availableSites.length);
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const assignedSiteIds = checked ? availableSites.map(site => site.siteId) : [];

    props.assignedSitesChanged(assignedSiteIds);
    setSelectAll(checked);
  }

  return <Form.Group>
    <div className="list-group">
      <Form.Check
        type="checkbox"
        label="Select All"
        checked={selectAll}
        onChange={handleSelectAll}
        disabled={availableSites.length === 0}
      />
      {props.sites.map(site => (
        <OverlayTrigger
          key={site.siteId}
          placement="bottom-start"
          popperConfig={{ modifiers: [{ name: 'arrow', options: { padding: 30 } }] }}
          overlay={isSiteUnavailable(site)
            ? <Tooltip>
              <ul className="my-0 pl-3">
                {site.unassignableReasons!.map((r, index) => <li key={index} className="text-start">{r}</li>)}
              </ul>
            </Tooltip>
            : <></>}>
          {({ ref, ...triggerHandler }) => (
            <div data-testid={site.siteId} {...triggerHandler}>
              <Form.Check
                ref={ref}
                label={site.siteName}
                id={site.siteId}
                checked={props.assignedSiteIds.includes(site.siteId)}
                onChange={ev => assignedSiteCheckedChanged(ev.target.checked, site.siteId)}
                disabled={isSiteUnavailable(site)}
                style={{ pointerEvents: isSiteUnavailable(site) ? 'none' : 'auto' }} />
            </div>
          )}
        </OverlayTrigger>
      ))}
    </div>
  </Form.Group>
}