import { HttpMethod } from "../components/Common/useFetch";
import { RequestOptions } from "../components/Common/useRequest";
import useUserRequest from "../components/Common/useUserRequest";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";

export interface UpdateProductLoadedWidgetSettingsDto {
  name: string;
  selectedProductId?: string;
  selectedSiteId?: string;
  selectedUnit: string;
}

const useUpdateProductLoadedWidgetSettings = (widgetId: string, options?: RequestOptions<void>) => {
  const business = useSelectedBusiness();
  return useUserRequest<UpdateProductLoadedWidgetSettingsDto>({
    path: `/api/businesses/${business.id}/productLoadedWidgetSettings/${widgetId}`,
    method: HttpMethod.PUT,
  }, options);
}

export default useUpdateProductLoadedWidgetSettings;