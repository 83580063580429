import React, { useState } from "react";
import { Container, Row, Table } from "reactstrap";
import { CenteredSpinner } from "../Common/CenteredSpinner";
import KaAlert from "../Common/KaAlert";
import useDisplayMessage from "../Common/useDisplayMessage";
import FilterInput, { textSatisfiesFilter } from "../FilterInput";
import { CreateEditBranch } from "./CreateEditBranch";
import BoldButton from "../Common/BoldButton";
import { Button } from "react-bootstrap";
import Confirm from "../Common/Confirm";
import useGetBranches, { BranchDto } from "../../requests/useGetBranches";
import useRemoveBranch from "../../requests/useRemoveBranch";
import formatPostalAddress from "../Common/PostalAddressDisplay";

enum States {
  None,
  AddEditBranch,
}

type PopupState =
  { state: States.None; } |
  {
    state: States.AddEditBranch;
    branch: BranchDto | null;
  };

const alphabeticallyByName = (a: BranchDto, b: BranchDto) => a.name.localeCompare(b.name);

const BranchPage = () => {
  const displayMessage = useDisplayMessage();
  const [filterText, setFilterText] = useState('');
  const [popupState, setPopupState] = useState<PopupState>({ state: States.None });
  const [branchToRemove, setBranchToRemove] = useState<BranchDto | undefined>(undefined);

  const getBranches = useGetBranches();

  const containsFilterText = (branch: BranchDto) => {
    const satisfiesFilter = textSatisfiesFilter(filterText);
    return satisfiesFilter(branch.name) || satisfiesFilter(branch.number);
  };

  const filterTextChanged = (text: string) => setFilterText(text);

  const removeSuccess = () => {
    displayMessage.success(`Branch ${branchToRemove?.name} removed`);
    return getBranches.query();
  };

  const removeRequest = useRemoveBranch(branchToRemove?.id ?? '', {
    onSuccess: removeSuccess,
    onError: (err) => displayMessage.fail(err.message),
    onComplete: () => setBranchToRemove(undefined),
  });

  const createClicked = () => {
    displayMessage.clear();
    setPopupState({ state: States.AddEditBranch, branch: null });
  }

  const editClicked = (branch: BranchDto) => {
    displayMessage.clear();
    setPopupState({ state: States.AddEditBranch, branch: branch });
  }

  const branchUpserted = (branchName: string) => {
    if (popupState.state !== States.AddEditBranch) return;
    displayMessage.success(`Branch ${branchName} ${popupState.branch ? 'updated' : 'created'}`);
    setPopupState({ state: States.None });
    getBranches.query();
  }

  const editDismissed = () => setPopupState({ state: States.None });

  if (getBranches.isError) return <h3>{getBranches.error?.message}</h3>
  if (getBranches.isLoading || removeRequest.isLoading) return <CenteredSpinner />

  const formatBranchPostalAddress = (branch: BranchDto) => {
    return formatPostalAddress(
      branch.addressStreet,
      branch.addressCity,
      branch.addressState,
      branch.addressPostalCode,
      branch.addressCountry);
  };

  return <Container>
    {popupState.state === States.AddEditBranch && <CreateEditBranch
      initialBranch={popupState.branch}
      onUpsert={branchUpserted}
      onDismiss={editDismissed} />}
    <Confirm
      visible={!!branchToRemove}
      title='Remove branch'
      body={`Are you sure that you want to remove branch ${branchToRemove?.name}?`}
      onDismiss={() => setBranchToRemove(undefined)}
      onConfirm={() => removeRequest.request()} />
    <Row className='justify-content-between mb-2'>
      <h2 className='col-auto ka-blue'>Branches</h2>
      <BoldButton className='col-auto btn-ghost-primary' onClick={createClicked}>Create Branch</BoldButton>
    </Row>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <form>
      <div className='row gx-2 gx-md-3 mb-7'>
        <div className='col-md-4 mb-2 mb-md-0'>
          <FilterInput placeholder='Filter branches by keyword' onFilterTextChange={filterTextChanged} />
        </div>
      </div>
    </form>
    <Table bordered>
      <thead><tr><th>Name</th><th>Number</th><th>Address</th><th></th></tr></thead>
      <tbody>
        {getBranches.data?.filter(containsFilterText).sort(alphabeticallyByName).map(branch =>
          <tr key={branch.id}>
            <td>{branch.name}</td>
            <td>{branch.number}</td>
            <td>{formatBranchPostalAddress(branch)}</td>
            <td align="right">
              <div className='dropdown'>
                <Button id='actionsMenuButton'
                  className='dropdown-toggle btn-ghost-secondary'
                  variant='link'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'><strong>Actions</strong></Button>
                <div className='dropdown-menu' aria-labelledby='actionsMenuButton'>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => editClicked(branch)}>Edit</BoldButton>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => setBranchToRemove(branch)}>Remove</BoldButton>
                </div>
              </div>
            </td>
          </tr>)}
      </tbody>
    </Table>
  </Container>
};

export { BranchPage };
