import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'

interface FormCheckboxProps {
  label: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  span?: number,
  offset?: number
}

export function FormCheckbox(props: FormCheckboxProps) {
  return <Form.Group as={Row}>
    <Col xs={{ span: props.span, offset: props.offset }}>
      <Form.Check label={props.label} onChange={props.onChange} />
    </Col>
  </Form.Group>
}
