import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { UserProfile } from '../UserAccess/UserContext';
import useUserQuery, { UserQuerySpec } from '../Common/useUserQuery';
import SiteDto from '../Common/dtos/SiteDto';
import { HttpMethod } from '../Common/useFetch';
import QueryView from '../Common/QueryView';
import { Alert, Col, Container, Row, Table } from 'reactstrap';
import SpinnerButton from '../Common/SpinnerButton';
import useUserRequest, { UserRequestSpec } from '../Common/useUserRequest';
import SiteClientRegisteredDto from '../Common/dtos/SiteClientRegisteredDto';
import RegisterSiteClientDto from '../Common/dtos/RegisterSiteClientDto';
import { redirect } from '../Common/redirect';
import useDisplayMessage from '../Common/useDisplayMessage';
import KaAlert from '../Common/KaAlert';
import BusinessAdminView from '../Common/BusinessAdminView';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const getSpec = (businessId?: string): UserQuerySpec => ({
  path: `/api/businesses/${businessId}/sites`,
});

const requestSpec = (businessId?: string, siteId?: string): UserRequestSpec => ({
  method: HttpMethod.POST,
  path: `/api/businesses/${businessId}/sites/${siteId}/registerClient`,
});

interface SiteSelectorProps {
  user: UserProfile,
  clientId: string,
  registered: (clientSecret: string, siteId: string) => void,
}

const SiteSelector = (props: SiteSelectorProps) => {
  const getSites = useUserQuery<SiteDto[]>(getSpec(props.user.selectedBusiness?.id));
  const displayMessage = useDisplayMessage();
  const [siteIdToRegister, setSiteIdToRegister] = useState<(string | undefined)>();

  const registerRequest = useUserRequest<RegisterSiteClientDto, SiteClientRegisteredDto>(
    requestSpec(props.user.selectedBusiness?.id, siteIdToRegister), {
      onSuccess: response => props.registered(response.clientSecret, siteIdToRegister!),
    onError: e => displayMessage.fail(e.message),
    onComplete: () => setSiteIdToRegister(undefined)
  });

  useEffect(() => {
    if (siteIdToRegister
      && !registerRequest.isLoading
      && !registerRequest.isSuccess) {
      registerRequest.request({
        clientId: props.clientId
      });
    }
  }, [siteIdToRegister, props.clientId, registerRequest]);

  return (<QueryView
    query={getSites}
    renderData={
      sites => <>
        <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
        <Table bordered>
          <thead>
            <tr>
              <th>Site Name</th >
            </tr>
          </thead>
          <tbody>
            {sites.map(site =>
              <tr key={site.id} >
                <td>
                  <Row className="justify-content-between" >
                    <Col>
                      {site.name}
                    </Col >
                    <Col className="text-end" >
                      <div className="position-relative">
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          placement="top"
                          overlay={(props) => (
                            site.isRegistered ? (
                              <Tooltip {...props}>
                                {site.name} has already been registered
                              </Tooltip>
                            ) : (<span />))
                          }>
                          <span className="w-100 h-100">
                            <SpinnerButton
                              className="btn-ghost-primary"
                              spinning={registerRequest.isLoading}
                              onClick={() => setSiteIdToRegister(site.id)}
                              disabled={site.isRegistered}>
                              Register
                            </SpinnerButton>
                          </span>
                        </OverlayTrigger>
                      </div>
                    </Col >
                  </Row >
                </td >
              </tr >
            )}
          </tbody >
        </Table >
      </>}
  />);
}

export const SiteRegistration = () => {
  const search = new URLSearchParams(useLocation().search);
  const [clientId, redirectUri] = [search.get('client_id'), search.get('redirect_uri')];

  const siteRegistered = (secret: string, siteId: string) => {
    const uri = `${redirectUri}?client_id=${clientId}&client_secret=${encodeURIComponent(secret)}&site_id=${siteId}`;
    redirect(uri);
  }
  
  return (clientId && redirectUri)
    ? <Container>
      <BusinessAdminView
        render={user =>
          <SiteSelector
            user={user}
            clientId={clientId}
            registered={siteRegistered} />
        } />
    </Container>
    : <Alert color="danger">
      An unexpected error occurred. Please contact Kahler Automation
    </Alert>
}