import React, { useState } from 'react';
import { Row, Table, Container } from 'reactstrap';
import KaAlert from '../../Common/KaAlert';
import { UserProfile } from '../../UserAccess/UserContext';
import { CenteredSpinner } from '../../Common/CenteredSpinner';
import useUserQuery, { UserQuerySpec } from '../../Common/useUserQuery';
import useDisplayMessage from '../../Common/useDisplayMessage';
import { CreateEditDestination } from './CreateEditDestination';
import CustomerDto from '../../Common/dtos/CustomerDto';
import DestinationDto from '../../Common/dtos/DestinationDto';
import DestinationUpsertDto from '../../Common/dtos/DestinationUpsertDto';
import formatPostalAddress from '../../Common/PostalAddressDisplay';
import { customerDisplayNameFromDto } from '../../../Utilities';
import BoldButton from '../../Common/BoldButton';

interface IProps {
  user: UserProfile,
  customer: CustomerDto,
  backClicked?: () => void
}

const getSpec = (businessId?: string, customerId?: string): UserQuerySpec => ({
  path: `/api/businesses/${businessId}/customers/${customerId}/destinations`,
});

const DestinationPage = (props: IProps) => {
  const getDestinations = useUserQuery<DestinationDto[]>(getSpec(props.user.selectedBusiness?.id, props.customer.id));
  const [showModal, setShowModal] = useState(false);
  const displayMessage = useDisplayMessage();
  const [editingDestination, setEditingDestination] = useState<DestinationDto | null>(null);

  const createEditSuccess = (c: DestinationUpsertDto) => {
    displayMessage.success(`Destination ${c.name} ${editingDestination ? 'updated' : 'created'}`);
    getDestinations.query();
    setShowModal(false);
  }

  const createClick = () => {
    setEditingDestination(null);
    setShowModal(true);
  }

  const editClick = (d: DestinationDto) => {
    setEditingDestination(d);
    setShowModal(true);
  }

  if (getDestinations.isLoading) return <CenteredSpinner />
  if (getDestinations.isError) return <h3>{getDestinations.error?.message}</h3>

  const formatDestinationPostalAddress = (destination: DestinationDto) => {
    return formatPostalAddress(
      destination.shippingAddressStreet,
      destination.shippingAddressCity,
      destination.shippingAddressState,
      destination.shippingAddressPostalCode,
      destination.shippingAddressCountry);
  };

  return <Container>
    <h2 className="col-auto ka-blue">Destinations for '{customerDisplayNameFromDto(props.customer)}'</h2>
    <Row className="justify-content-between mb-2">
      <BoldButton className="col-auto btn-ghost-secondary" variant="link" onClick={props.backClicked}>
        <i className="bi-chevron-left" /> Back
      </BoldButton>
      <BoldButton className="col-auto btn-ghost-primary" onClick={createClick}>Create Destination</BoldButton>
    </Row>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <Table bordered>
      <thead>
        <tr>
          <th>Name</th>
          <th>Address</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {getDestinations.data?.map(destination =>
          <tr key={destination.id}>
            <td>{destination.name}</td>
            <td>{formatDestinationPostalAddress(destination)}</td>
            <td align="right"><BoldButton className="btn-ghost-primary" onClick={() => editClick(destination)}>Edit</BoldButton></td>
          </tr>
        )}
      </tbody>
    </Table>
    {showModal &&
      <CreateEditDestination
        onSuccess={createEditSuccess}
        user={props.user}
        customerId={props.customer.id}
        initialDestination={editingDestination}
        onHide={() => setShowModal(false)} />
    }
  </Container>
}

export { DestinationPage };