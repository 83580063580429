import React, { useState } from 'react';
import useGetDashboardLayout, { DashboardLayoutResponse, WidgetDto } from '../../requests/useGetDashboardLayout';
import useUpdateDashboardLayout from '../../requests/useUpdateDashboardLayout';
import QueryView from '../Common/QueryView';
import { DashboardContext, Widget } from './DashboardContext';
import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

type ManagerProps = {
  dashboardLayout: DashboardLayoutResponse,
  children?: React.ReactNode
}

const DashboardProviderManger: React.FC<ManagerProps> = ({ dashboardLayout, children }) => {
  const useUpdateDashboard = useUpdateDashboardLayout();
  const [widgets, setWidgets] = useState<Widget[]>(
    dashboardLayout.widgets.map(w => ({ ...w, componentRef: React.createRef<HTMLDivElement>() })));

  const updateDashboardLayout = (updatedWidgets: Widget[]) => {
    useUpdateDashboard.request(updatedWidgets.map(w => ({ id: w.id.toString(), type: w.type })));
    setWidgets(updatedWidgets);
  }

  const swapWidgets = (dragWidgetId: UniqueIdentifier, dropWidgetId: UniqueIdentifier) => {
    const oldIndex = widgets.findIndex(w => w.id === dragWidgetId);
    const newIndex = widgets.findIndex(w => w.id === dropWidgetId);

    const update = arrayMove(widgets, oldIndex, newIndex);

    updateDashboardLayout(update);
  }

  const addWidget = (widget: WidgetDto) => {
    setWidgets([...widgets, { ...widget, componentRef: React.createRef<HTMLDivElement>() }]);
  }

  const removeWidget = (widgetId: UniqueIdentifier) =>
    updateDashboardLayout(widgets.filter(w => w.id !== widgetId));

  return <DashboardContext.Provider value={{ widgets, swapWidgets, addWidget, removeWidget }}>
    {children}
  </DashboardContext.Provider>
}

export const DashboardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const getDashboardLayout = useGetDashboardLayout();

  return <QueryView
    query={getDashboardLayout}
    renderData={(response) =>
      <DashboardProviderManger dashboardLayout={response}>
        {children}
      </DashboardProviderManger>
    } />
}
