import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ModalProps } from 'react-bootstrap';

export default function KaModal(props: ModalProps) {
  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="modal-title">{props.title}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.body}
      </Modal.Body>
      <Modal.Footer>
        {props.footer}
      </Modal.Footer>
    </Modal>
  );
}