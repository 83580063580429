import { QueryOptions } from "../Common/useQuery";
import useUserQuery from "../Common/useUserQuery";
import { useUser } from "../UserAccess/useUser";

export type BulkProductAllocationDto = {
  name: string,
  openOrderAllocation?: number,
  currentInventory?: number,
  error?: string
}

export type BulkProductAllocationResponse = {
  bulkProductAllocations: BulkProductAllocationDto[]
}

export type PathParams = {
  unit: string,
  siteId?: string,
  products?: string[],
}

const useGetBulkProductAllocation = (params: PathParams, options?: QueryOptions<BulkProductAllocationResponse>) => {
  const user = useUser();
  const baseUrl = `/api/businesses/${user.selectedBusiness?.id!}/bulkProductAllocation`;
  const urlParams = new URLSearchParams();

  if (params.siteId && params.siteId !== 'AllSites') urlParams.append('siteId', params.siteId);
  if (params.unit) urlParams.append('unit', params.unit);
  if (params.products) {
    params.products.forEach((id) => {
      if (id !== '') {
        urlParams.append('products', id)
      }
    });
  }

  return useUserQuery<BulkProductAllocationResponse>(
    { path: `${baseUrl}?${urlParams.toString()}` },
    options);
}

export default useGetBulkProductAllocation;
