import React from 'react';
import KaAlert from '../Common/KaAlert'
import KaModal from '../Common/KaModal';
import SpinnerButton from '../Common/SpinnerButton';
import useDisplayMessage from '../Common/useDisplayMessage';
import { HttpMethod } from '../Common/useFetch';
import useKaEmployeeRequest, { UserRequestSpec } from '../Common/useKaEmployeeRequest';
import { KaEmployeeProfile } from '../UserAccess/UserContext';
import { BusinessDto } from './BusinessList';
import { RowFormText } from '../Common/Forms/FormText';
import { Path, useForm } from 'react-hook-form';

interface IProps {
  kaUser: KaEmployeeProfile;
  business: BusinessDto;
  onHide: () => void;
  onSuccess: (message: string) => void;
}

export interface BusinessUpdateSiteAllowanceDto { siteAllowance: number }

const postSpec = (businessId?: string): UserRequestSpec => ({
  path: `/employeeApi/businesses/${businessId}/siteAllowance`,
  method: HttpMethod.PUT,
});

export const ManageSites = (props: IProps) => {
  const requestSpec = postSpec(props.business.id);
  const displayMessage = useDisplayMessage();

  const { register, handleSubmit, setValue, formState: { errors } } = useForm<BusinessUpdateSiteAllowanceDto>({
    defaultValues: { siteAllowance: props.business.siteAllowance }
  });

  const handleUpdateSiteAllowance = (newSiteAllowance: BusinessUpdateSiteAllowanceDto) => {
    useUpdateSiteAllowance.request(newSiteAllowance);
  }

  const useUpdateSiteAllowance = useKaEmployeeRequest<BusinessUpdateSiteAllowanceDto>(requestSpec, {
    onSuccess: () => props.onSuccess("Site Allowance updated successfully"),
    onError: (e) => displayMessage.fail(e.message),
  });

  const onClose = () => {
    props.onHide();
    displayMessage.clear();
  }

  const hookProps = (name: Path<BusinessUpdateSiteAllowanceDto>) => ({
    labelSpace: 3,
    inputSpace: 8,
    name: name,
    setValue,
    register,
    errors,
  })

  return (<KaModal
    onHide={onClose}
    show={true}
    title={`Manage sites for ${props.business.name}`}
    body={(<>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <RowFormText 
        {...hookProps("siteAllowance")}
        options={{
          valueAsNumber: true,
          validate: {
            integer:  v => Number.isInteger(v) || 'Must be a whole number',
            greaterThanOrEqualToZero: v => v >= 0 || 'Cannot be negative',
            isValidInteger: v => v <= 2147483647 || 'Must be a valid integer',
            notLowerThanNumberOfActiveSites: v => v >= props.business.numberOfSites || 'Cannot be less than the number of active sites'
          }
        }}
      />
    </>)}
    footer={(
      <SpinnerButton
        className="btn-ghost-primary"
        spinning={useUpdateSiteAllowance.isLoading}
        onClick={handleSubmit(handleUpdateSiteAllowance)} >
        Save
      </SpinnerButton>)
    }
  />);
}
