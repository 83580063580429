import React, { useState } from 'react';
import { useKaEmployee } from '../UserAccess/useKaEmployee';
import KaAlert from '../Common/KaAlert';
import useDisplayMessage from '../Common/useDisplayMessage';
import QueryView from '../Common/QueryView';
import useQuery, { QuerySpec } from '../Common/useQuery';
import SelectSearch, { SelectOption } from '../Common/Forms/SelectSearch';

type site = {
  id: string,
  name: string
}

type sitesResponse = {
  sites: site[]
}

type siteNotificationResponse = {
  notifications: siteNotification[]
}

type siteNotification = {
  id: string,
  acknowledged: boolean,
  routingKey: string,
  sequence: string,
  data: object
}

const SiteNotificationPage = () => {
  const kahlerUser = useKaEmployee();
  const displayMessage = useDisplayMessage();

  const [selectedSite, setSelectedSite] = useState<site | undefined>();

  const getSitesQuery = useQuery<sitesResponse>({
    headers: kahlerUser.fetchHeaders,
    path: `/employeeApi/sites/all`
  });

  const getSpec = (siteId?: string): QuerySpec => ({
    headers: kahlerUser.fetchHeaders,
    path: `/employeeApi/sites/${siteId}/allNotifications`
  });

  const getNotificationsQuery = useQuery<siteNotificationResponse>(getSpec(selectedSite?.id));

  return <QueryView query={getSitesQuery} renderData={resp =>
    <div className="w-100">
      <div className="w-25 mb-4">
        <SelectSearch
          id="site"
          placeholder="Select site"
          onChange={(siteId) => setSelectedSite(resp.sites.find((s) => s.id === siteId))}
          options={resp.sites.map<SelectOption>(s => ({ value: s.id, name: s.name }))} />
      </div>
      {selectedSite && 
        <QueryView query={getNotificationsQuery} renderData={data => 
          <div className="container">
            <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
            <table className="table table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col">Notification Id</th>
                  <th scope="col">Routing Key</th>
                  <th scope="col">Sequence</th>
                  <th scope="col">Acknowledged</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {data.notifications.map((n, index) =>
                  <>
                    <tr key={n.id}>
                      <td>{n.id}</td>
                      <td>{n.routingKey}</td>
                      <td>{n.sequence}</td>
                      <td className="text-capitalize">{String(n.acknowledged)}</td>
                      <td>
                        <button
                          className="btn btn-primary btn-sm"
                          data-bs-toggle="modal"
                          data-bs-target={"#notificationInfo" + index}>
                          View Data
                        </button>
                      </td>
                      <div
                        className="modal fade"
                        id={"notificationInfo" + index}
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby={"notificationInfo" + index + "Label"}
                        aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id={"notificationInfo" + index + "Label"}>Notification Data: {n.routingKey}</h5>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              <pre>{JSON.stringify(n.data, null, 2)}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        } />  
      }
    </div>
    } />
}

export { SiteNotificationPage }