import { HttpMethod } from "../components/Common/useFetch";
import { RequestOptions } from "../components/Common/useRequest";
import useUserRequest from "../components/Common/useUserRequest";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";

export interface UpdateBulkProductAllocationWidgetSettingsDto {
  name: string;
  selectedProductIds: string[] | undefined;
  selectedSiteId: string | undefined;
  selectedUnit: string | undefined;
}

const useUpdateBulkProductAllocationWidgetSettings = (widgetId: string, options?: RequestOptions<void>) => {
  const business = useSelectedBusiness();
  return useUserRequest<UpdateBulkProductAllocationWidgetSettingsDto>({
    path: `/api/businesses/${business.id}/bulkProductAllocationWidgetSettings/${widgetId}`,
    method: HttpMethod.PUT,
  }, options);
}

export default useUpdateBulkProductAllocationWidgetSettings;