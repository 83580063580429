import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React from 'react';
import { kaEmployeeSignInRequest } from '../authConfig';
import { NavButton } from './NavMenu';

export const KahlerLogin = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const kaEmployeeSignIn = () => instance.loginRedirect(kaEmployeeSignInRequest);

  return <ul className='navbar-nav position-absolute top-50 start-50 translate-middle'>
    {!isAuthenticated && <NavButton text="KA Employee Sign In" onClick={kaEmployeeSignIn} />}
  </ul>
}