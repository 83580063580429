import { HttpMethod } from "../components/Common/useFetch";
import { RequestOptions } from "../components/Common/useRequest";
import useUserRequest from "../components/Common/useUserRequest";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";
import { WidgetDto, WidgetType } from "./useGetDashboardLayout";

export interface AddWidgetDto {
  type: WidgetType;
  name: string;
}

const useAddWidget = (options?: RequestOptions<WidgetDto>) => {
  const business = useSelectedBusiness();
  return useUserRequest<AddWidgetDto, WidgetDto>({
    path: `/api/businesses/${business.id}/dashboardLayout/widgets`,
    method: HttpMethod.POST,
  }, options);
}

export default useAddWidget;
