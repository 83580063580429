import { QueryOptions } from "../components/Common/useQuery";
import useUserQuery from "../components/Common/useUserQuery";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";

export interface BranchDto {
  id: string;
  name: string;
  number?: string;
  addressStreet?: string;
  addressCity?: string;
  addressState?: string;
  addressPostalCode?: string;
  addressCountry?: string;
  phone?: string;
  email?: string;
}

const useGetBranches = (options?: QueryOptions<BranchDto[]>) => {
  const business = useSelectedBusiness();
  return useUserQuery<BranchDto[]>({ path: `/api/businesses/${business.id}/branches` }, options);
}

export default useGetBranches;
