import { HttpMethod } from "../components/Common/useFetch";
import { RequestOptions } from "../components/Common/useRequest";
import useUserRequest from "../components/Common/useUserRequest";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";

const useRemoveBranch = (branchId: string, options?: RequestOptions<void>) => {
  const business = useSelectedBusiness();
  return useUserRequest<void>({
    path: `/api/businesses/${business.id}/branches/${branchId}`,
    method: HttpMethod.DELETE,
  }, options);
}

export default useRemoveBranch;
