import { QueryOptions } from "../components/Common/useQuery";
import useUserQuery from "../components/Common/useUserQuery";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";

export interface CarrierDto {
  id: string;
  name: string;
  number?: string;
  addressStreet?: string;
  addressCity?: string;
  addressState?: string;
  addressPostalCode?: string;
  addressCountry?: string;
  phone?: string;
  email?: string;
  notes?: string;
}

const useGetCarriers = (options?: QueryOptions<CarrierDto[]>) => {
  const business = useSelectedBusiness();
  return useUserQuery<CarrierDto[]>({ path: `/api/businesses/${business.id}/carriers` }, options);
}

export default useGetCarriers;
