import React, { useEffect, useRef } from "react";
import { FormCommonProps } from "./FormCommon";
import { FieldValues } from "react-hook-form";
import { useFormError } from "./useFormError";
import { FormFeedback, FormGroup } from "reactstrap";
import { FormLabel } from "./FormLabel";
import { FormRow, FormRowSize } from "./FormRow";

export interface SelectOption {
  value: string,
  name?: string,
}

export interface SelectSearchProps {
  id: string,
  onChange: (value: string) => void;
  options: SelectOption[],
  selectedValue?: string,
  placeholder?: string,
  hideSearch?: boolean,
  disabled?: boolean,
}

function CreateHashForString(string: string) {
  var hash = 0, i, chr;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

function CreateHashForStrings(strings: Array<string>) {
  return strings.reduce((sum, s) => sum + CreateHashForString(s), 0);
}

const SelectSearch = (props: SelectSearchProps) => {
  const ref = useRef<HTMLSelectElement>(null)
  const disabled = props.disabled ?? false;
  const key = CreateHashForStrings(
    [...props.options.map(o => o.value), props.selectedValue ?? ""]);

  useEffect(() => {
    // @ts-ignore
    if (!ref.current.tomselect) {
      // @ts-ignore
      window.initJsSelect(ref.current);
    }
  });

  const selectOptions = JSON.stringify({
    placeholder: props.placeholder,
    hideSearch: props.hideSearch,
    maxOptions: null,
  });

  return <div className="tom-select-custom" key={key}>
    <select className="js-select w-100 pt-1"
      onChange={ev => props.onChange(ev.target.value)}
      autoComplete="off"
      id={props.id}
      data-hs-tom-select-options={selectOptions}
      ref={ref}
      key={key}
      disabled={disabled}
      value={props.selectedValue}
    >
      <option value="">
        {props.placeholder}
      </option>
      {props.options.map(o =>
        <option
          key={o.value}
          value={o.value}>
          {o.name ?? o.value}
        </option>)
      }
    </select>
  </div>
}

export default SelectSearch;

export interface FormSelectSearchProps<FormType extends FieldValues> extends FormCommonProps<FormType> {
  id: string,
  selectOptions: SelectOption[],
  selectedValue?: string,
  placeholder?: string,
  hideSearch?: boolean,
  disabled?: boolean,
  'data-testid'?: string
  onChange?: (val: React.ChangeEvent<HTMLSelectElement>) => void
}

export function FormSelectSearch(props: FormSelectSearchProps<any>) {
  return <FormGroup>
    <FormLabel {...props} />
    <FormSelectSearchControl {...props} />
  </FormGroup>
}

interface RowFormTextProps<FormType extends FieldValues> extends FormSelectSearchProps<FormType>, FormRowSize { }

export function RowFormSelectSearch(props: RowFormTextProps<any>) {
  return <FormRow
    {...props}
    control={<FormSelectSearchControl {...props} />}
  />
}

export const FormSelectSearchControl = (props: FormSelectSearchProps<any>) => {
  const ref = useRef<HTMLSelectElement>(null);
  const disabled = props.disabled ?? false;
  const key = CreateHashForStrings(
    [...props.selectOptions.map(o => o.value), props.selectedValue ?? ""]);
  var { inError, errorMessage } = useFormError(props);

  useEffect(() => {
    props.register(props.name, props.options);

    // @ts-ignore
    if (!ref.current.tomselect) {
      // @ts-ignore
      window.initJsSelect(ref.current);
    }
  });

  const selectOptions = JSON.stringify({
    placeholder: props.placeholder,
    hideSearch: props.hideSearch,
    maxOptions: null,
  });

  return <>
    <div className={`tom-select-custom ${inError ? 'is-invalid border border-danger rounded-1' : ''}`} key={key}>
      <select className="form-select"
        onChange={(val) => {
            props.onChange ? props.onChange(val) : props.setValue(props.name, val.target.value, { shouldValidate: true })
          }
        }
        name={props.name}
        autoComplete="off"
        id={props.name}
        data-hs-tom-select-options={selectOptions}
        ref={ref}
        key={key}
        disabled={disabled}
        defaultValue={props.selectedValue}
      >
        {props.placeholder && <option value="">
          {props.placeholder}
        </option>}
        {props.selectOptions.map(o =>
          <option
            key={o.value}
            value={o.value}>
            {o.name ?? o.value}
          </option>)
        }
      </select>
    </div>
    <FormFeedback>{errorMessage}</FormFeedback>
  </>
}
