import { QueryOptions } from "../Common/useQuery";
import useUserQuery from "../Common/useUserQuery";
import { useUser } from "../UserAccess/useUser";

export enum Status {
  Error = 'Error',
  Warning = 'Warning',
  Operational = 'Operational',
}

export type SiteStatusDto = {
  siteName: string,
  status: Status,
  error?: string
}

export type SitesStatusResponse = {
  sitesStatus: SiteStatusDto[]
}

const useGetSitesStatus = (options?: QueryOptions<SitesStatusResponse>) => {
  const user = useUser();

  return useUserQuery<SitesStatusResponse>(
    { path: `/api/businesses/${user.selectedBusiness?.id!}/sitesStatus` },
    options);
}

export default useGetSitesStatus;
