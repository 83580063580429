import React from 'react';
import KaModal from '../Common/KaModal';
import SiteDto from '../Common/dtos/SiteDto';
import SiteUpsertDto from '../Common/dtos/SiteUpsertDto';
import SpinnerButton from '../Common/SpinnerButton';
import { UserProfile } from '../UserAccess/UserContext';
import useUserRequest, { UserRequestSpec } from '../Common/useUserRequest';
import { HttpMethod } from '../Common/useFetch';
import { Path, useForm } from 'react-hook-form';
import { RowFormText } from '../Common/Forms/FormText';
import { useDisplayMessage } from '../Common/useDisplayMessage';
import KaAlert from '../Common/KaAlert';

export interface IProps {
  user: UserProfile,
  initialSite: SiteDto | null,
  onHide: () => void,
  onSuccess: (site: SiteUpsertDto) => void,
}

const sitesPath = (businessId?: string) => `/api/businesses/${businessId}/sites`;

const postSpec = (businessId?: string): UserRequestSpec => ({
  path: sitesPath(businessId),
  method: HttpMethod.POST,
});

const putSpec = (businessId?: string, siteId?: string): UserRequestSpec => ({
  path: `${sitesPath(businessId)}/${siteId}`,
  method: HttpMethod.PUT,
});

const newSite = (): SiteUpsertDto => ({ name: '' });

export const CreateEditSite = (props: IProps) => {
  const create = props.initialSite === null;
  let { id, ...initialSite } = { ...props.initialSite };
  const site = create ? newSite() : initialSite;

  const businessId = props.user.selectedBusiness?.id;
  const requestSpec = create
    ? postSpec(businessId)
    : putSpec(businessId, id);

  const displayMessage = useDisplayMessage();

  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm<SiteUpsertDto>({
    defaultValues: { ...site }
  });

  const handleCreateUpdate = (siteUpsert: SiteUpsertDto) => {
    useCreateEdit.request(siteUpsert);
  }

  const useCreateEdit = useUserRequest<SiteUpsertDto>(requestSpec, {
    onSuccess: () => props.onSuccess(getValues()),
    onError: (e) => displayMessage.fail(e.message),
  });

  const hookProps = (name: Path<SiteUpsertDto>) => ({
    labelSpace: 4,
    inputSpace: 7,
    name: name,
    setValue,
    register,
    errors,
  });

  const onClose = () => {
    props.onHide();
    displayMessage.clear();
  }

  return (<KaModal
    onHide={onClose}
    show={true}
    title={`${create ? 'Create' : 'Update'} a site`}
    body={(<>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <RowFormText displayName="Site Name"
        {...hookProps("name")}
        options={{
          required: 'Name is required',
          validate: {
            length: v => v.length < 101 || 'Name cannot be longer than 100 characters'
          },
          pattern: {
            value: /^[^,]*$/i,
            message: 'Name cannot contain commas'
          }
        }}
      />
    </>)}
    footer={(
      <SpinnerButton
        className="btn-ghost-primary"
        spinning={useCreateEdit.isLoading}
        onClick={handleSubmit(handleCreateUpdate)}>
        {`${create ? 'Create' : 'Update'}`}
      </SpinnerButton>)
    }
  />);
}
