import React, { useState } from 'react';
import { Button, Col, Row, Table } from 'reactstrap';
import { UserQuerySpec } from '../Common/useUserQuery';
import { Query } from '../Common/useQuery';
import ProductDto from '../Common/dtos/ProductDto'
import { Profile } from '../UserAccess/UserContext';
import { CreateEditOrderBlendItem, ItemType, OrderBlendItem } from './CreateEditOrderBlendItem'
import { trashIcon } from '../BootstrapIcons/TrashIcons';
import { editIcon } from '../BootstrapIcons/EditIcons';
import DisplayLinkImage from '../Common/DisplayLinkImage';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import QueryView from '../Common/QueryView';
import SpinnerButton from '../Common/SpinnerButton';
import { OrderBlendItemUpsertDto, OrderBlendUpsertDto } from '../Common/dtos/OrderUpsertDto';
import { OrderBlendItemDto } from '../Common/dtos/OrderDto';
import RecipeDto from '../Common/dtos/RecipeDto';
import { guidIsNullOrEmpty } from '../Common/ValidationRules';
import BoldButton from '../Common/BoldButton';
import useUserOrEmployeeQuery from '../Common/useUserOrEmployeeQuery';

interface IProps {
  profile: Profile,
  blends: OrderBlendUpsertDto[],
  updateBlends: (b: OrderBlendUpsertDto[]) => void,
  orderId: string | null | undefined,
  isVrtLoad: boolean,
  setUnrecoverableDataError: (exception: Error, severityLevel?: SeverityLevel) => void
  enabled: boolean,
}

const getProductsSpec = (businessId?: string): UserQuerySpec => ({
  path: `/api/businesses/${businessId}/products`,
});

const getRecipesSpec = (businessId?: string): UserQuerySpec => ({
    path: `/api/businesses/${businessId}/recipes`,
});

type ItemPopupState =
  null |
  { create: true, blendIndex: number } |
  { create: false, blendIndex: number, itemIndex: number, originalItem: OrderBlendItem }

const toBlendItem = (item: OrderBlendItemDto) => ({
    ref: item.ref,
    id: item.productId ?? item.recipeId,
    amount: item.amount,
    unit: item.unit
});

const toBlendItemUpsertDto = (item: OrderBlendItem) =>
    item.type === ItemType.Product
        ? { ref: item.ref, productId: item.id, amount: item.amount, unit: item.unit }
        : { ref: item.ref, recipeId: item.id, amount: item.amount, unit: item.unit };

export const CreateEditOrderBlends = (props: IProps) => {
  const productQuery = useUserOrEmployeeQuery<ProductDto[]>(getProductsSpec(props.profile.selectedBusiness?.id));
  const recipeQuery = useUserOrEmployeeQuery<RecipeDto[]>(getRecipesSpec(props.profile.selectedBusiness?.id));
  const [itemPopupState, siteItemPopupState] = useState<ItemPopupState>(null);

  const editItem = (blendIndex: number, itemIndex: number) => {
    siteItemPopupState({
      create: false,
      originalItem: toBlendItem(props.blends[blendIndex].items[itemIndex] as OrderBlendItemDto),
      blendIndex,
      itemIndex
    });
  };

  const addItem = (blendIndex: number) => siteItemPopupState({ create: true, blendIndex });

  const addBlend = () => {
    props.blends.push({ items: [] });
    props.updateBlends(props.blends);
  };

  const removeBlend = (blendIndex: number) => {
    props.blends.splice(blendIndex, 1);
    props.updateBlends(props.blends);
  };

  const removeItem = (blendIndex: number, itemIndex: number) => {
    props.blends[blendIndex].items.splice(itemIndex, 1);
    props.updateBlends(props.blends);
  };

  const itemChanged = (item: OrderBlendItem) => {
    if (!itemPopupState) throw new Error();
    const blendIndex = itemPopupState.blendIndex;
    const dto: OrderBlendItemUpsertDto = toBlendItemUpsertDto(item);

    if (itemPopupState.create) {
      props.blends[blendIndex].items.push(dto);
    } else {
      props.blends[blendIndex].items[itemPopupState.itemIndex] = dto;
    }

    props.updateBlends(props.blends);
    siteItemPopupState(null);
  };

  const listOfItemOptions = (products: ProductDto[], recipes: RecipeDto[]) => {
      return products.map(n => ({ id: n.id, name: n.name, type: ItemType.Product }))
          .concat(recipes.map(n => ({ id: n.id, name: n.name, type: ItemType.Recipe })));
  };

  return (<div title="">
    <Row className="justify-content-between mb-2">
    </Row>
    <Table bordered>
      <thead>
        <tr>
          <th>Items</th>
        </tr>
      </thead>
      <tbody>
        {
          props.blends.map((blend, bIndex) =>
            <BlendRow
              {...props}
              key={`BlendRow-${bIndex}`}
              blend={blend}
              blendIndex={bIndex}
              productQuery={productQuery}
              recipeQuery={recipeQuery}
              addItem={() => addItem(bIndex)}
              removeBlend={() => removeBlend(bIndex)}
              editItem={(pIndex) => editItem(bIndex, pIndex)}
              removeItem={(pIndex) => removeItem(bIndex, pIndex)}
            />
          )
        }
      </tbody>
    </Table>
    {props.isVrtLoad &&
      <BoldButton
        className="mx-1 btn-ghost-primary"
        onClick={addBlend}
        title="Add item">Add item</BoldButton>
    }
    {productQuery.isSuccess && recipeQuery.isSuccess && itemPopupState &&
      <CreateEditOrderBlendItem
        onSubmit={itemChanged}
        options={listOfItemOptions(productQuery.data as ProductDto[], recipeQuery.data as RecipeDto[])}
        profile={props.profile}
        mode={itemPopupState.create ?
          { create: true } :
          { create: false, originalItem: itemPopupState.originalItem }}
        onHide={() => siteItemPopupState(null)} />
    }
  </div>
  );
}

interface BlendRowProps extends IProps {
  blend: OrderBlendUpsertDto,
  blendIndex: number,
  productQuery: Query<ProductDto[]>,
  recipeQuery: Query<RecipeDto[]>,
  removeBlend: () => void,
  addItem: () => void,
  editItem: (productIndex: number) => void,
  removeItem: (productIndex: number) => void,
  enabled: boolean,
}

export const BlendRow = (props: BlendRowProps) => {
  return (
    <tr>
      <td>
        {props.isVrtLoad &&
          <h5>Blend {props.blendIndex + 1}</h5>
        }
        <BlendItems {...props} />
      </td>
      {props.isVrtLoad &&
        <td>
          <Row className="justify-content-between">
            <Col>
              <Button
                onClick={props.removeBlend}
                className="mx-1"
                title="Remove blend">{DisplayLinkImage(trashIcon, false)}</Button>
            </Col>
          </Row>
        </td>
      }
    </tr>
  );
}

const BlendItems = (props: BlendRowProps) => {
  return (<Row className="align-items-center justify-content-between">
    <Col>
      <Table bordered>
        <tbody>
          {
            props.blend.items.map((item, itemIndex) =>
              <BlendItemRow
                {...props}
                key={`BlendRow-${props.blendIndex}-${itemIndex}`}
                item={item}
                itemIndex={itemIndex}
                editItem={() => props.editItem(itemIndex)}
                removeItem={() => props.removeItem(itemIndex)}
              />
            )
          }
        </tbody>
      </Table>
      <SpinnerButton
        className="mx-1 btn-ghost-primary"
        onClick={props.addItem}
        spinning={props.productQuery.isLoading}
        disabled={!props.enabled}
        title="Add item">Add item</SpinnerButton>
    </Col>
  </Row>
  );
}

interface BlendItemRowProps extends BlendRowProps {
  item: OrderBlendItemUpsertDto,
  itemIndex: number,
  editItem: () => void,
  removeItem: () => void,
}

const BlendItemRow = (props: BlendItemRowProps) => {
    const indicateUnrecoverableError = (message: string) => props.setUnrecoverableDataError({
        name: `OrderId: ${props.orderId} Blend: ${props.blendIndex} Row: ${props.itemIndex}`,
        message }, SeverityLevel.Error);

    const renderProduct = (products: ProductDto[]) => {
        const product = products.find(product => product.id === props.item.productId);

        if (product) return <label>{product.name}</label>

        indicateUnrecoverableError(`Data consistency error. Product not found for item at position ${props.itemIndex + 1}`);

        return <label>?</label>
    }

    const renderRecipe = (recipes: RecipeDto[]) => {
        const recipe = recipes.find(recipe => recipe.id === props.item.recipeId);

        if (recipe) return <label>{recipe.name}</label>;
        
        indicateUnrecoverableError(`Data consistency error. Recipe not found for item at position ${props.itemIndex + 1}`);

        return <label>?</label>
    }

    const productSpecified = !guidIsNullOrEmpty(props.item.productId);
    const recipeSpecified = !guidIsNullOrEmpty(props.item.recipeId);

    if (!productSpecified && !recipeSpecified) {
        indicateUnrecoverableError(
            `Data consistency error. Neither a product or recipe is specified for item at position ${props.itemIndex + 1}`);
    }

    if (productSpecified && recipeSpecified) {
        indicateUnrecoverableError(
            `Data consistency error. Both a product and recipe are specified for item at position ${props.itemIndex + 1}`);
    }

  return (<tr>
    <td>
      <Row className="justify-content-between">
        <Col>
          {productSpecified && <QueryView query={props.productQuery} renderData={renderProduct} />}
          {recipeSpecified && <QueryView query={props.recipeQuery} renderData={renderRecipe} />}
        </Col>
      </Row>
    </td>
    <td>
      <Row className="justify-content-between">
        <Col>
          {props.item.amount?.toString() + " " + props.item.unit}
        </Col>
      </Row>
    </td>
    <td align="right">
      <Row className="justify-content-between">
        <Col>
          <Button
            color="primary"
            className="mx-1"
            onClick={props.editItem}
            title="Edit item"
            disabled={!props.enabled}
          >{DisplayLinkImage(editIcon, false)}</Button>
          <Button
            onClick={props.removeItem}
            className="mx-1"
            title="Remove item"
            disabled={!props.enabled}
          >{DisplayLinkImage(trashIcon, false)}</Button>
        </Col>
      </Row>
    </td>
  </tr>
  );
}
