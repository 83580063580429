import React, { ReactNode } from 'react';
import { Item } from './Item';
import { useDroppable } from '@dnd-kit/core';

export function DroppableItem(props: { key: string, children?: ReactNode, id: string, handle?: boolean }) {
  const { setNodeRef } = useDroppable({
    id: props.id,
  });

  return <Item ref={setNodeRef} {...props} />
}
