import React from 'react';
import KaAlert from '../Common/KaAlert'
import { FormCheckbox } from '../Common/FormCheckbox';
import SpinnerButton from '../Common/SpinnerButton';
import { UserProfile } from '../UserAccess/UserContext';
import KaModal from '../Common/KaModal';
import useUserRequest, { UserRequestSpec } from '../Common/useUserRequest';
import { HttpMethod } from '../Common/useFetch';
import useDisplayMessage from '../Common/useDisplayMessage';
import BusinessUserUpsertDto from '../Common/dtos/BusinessUserUpsertDto';
import { Path, useForm } from 'react-hook-form';
import { RowFormText } from '../Common/Forms/FormText';

interface IProps {
  user: UserProfile;
  visible: boolean;
  onHide: () => void;
  onSuccess: (businessUser: BusinessUserUpsertDto) => void;
}

const postSpec = (businessId?: string): UserRequestSpec => ({
  path: `/api/businesses/${businessId}/users`,
  method: HttpMethod.POST,
});

const newUser = (): BusinessUserUpsertDto => ({ emailAddress: '', administrator: false });

export const AddUser = (props: IProps) => {
  const user = newUser();
  const requestSpec = postSpec(props.user.selectedBusiness?.id);
  const displayMessage = useDisplayMessage();

  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm<BusinessUserUpsertDto>({
    defaultValues: { ...user }
  });

  const handleCreateUpdate = (businessUpsert: BusinessUserUpsertDto) => {
    useAddUser.request(businessUpsert);
  }

  const useAddUser = useUserRequest<BusinessUserUpsertDto>(requestSpec, {
    onSuccess: () => props.onSuccess(getValues()),
    onError: (e) => displayMessage.fail(e.message),
  });

  const updateBusinessUserAdminStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    user.administrator = e.target.checked;
    setValue("administrator", e.target.checked);
  }

  const hookProps = (name: Path<BusinessUserUpsertDto>) => ({
    labelSpace: 3,
    inputSpace: 8,
    name: name,
    setValue,
    register,
    errors,
  })

  const onClose = () => {
    props.onHide();
    displayMessage.clear();
  }

  return (<KaModal
    onHide={onClose}
    show={props.visible}
    title={'Add user to business'}
    body={(<>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <RowFormText displayName="User Email"
        {...hookProps("emailAddress")}
        options={{
          required: 'Email Address is required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Email Address is invalid'
          }
        }}
      />
      <FormCheckbox label="Mark new user as an administrator" onChange={updateBusinessUserAdminStatus} offset={3} />
    </>)}
    footer={(
      <SpinnerButton
        className="btn-ghost-primary"
        spinning={useAddUser.isLoading}
        onClick={handleSubmit(handleCreateUpdate)}>
        Add
      </SpinnerButton>)
    }
  />);
}
