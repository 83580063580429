import OrderDto from "../Common/dtos/OrderDto";
import { QueryOptions } from "../Common/useQuery";
import useUserQuery from "../Common/useUserQuery";
import { useSelectedBusiness } from "../UserAccess/useSelectedBusiness";

export interface OrderDisplayDto extends OrderDto {
  id: string;
  number: string;
  stage: string;
  hasTransactions: boolean;
  customerName: string;
  customerAccountNumber: string;
  itemNames: string[];
  siteNames: string[];
  createdAt: string;
  completedAt?: string;
}

export type PathParams = {
  completed: boolean,
}

const useGetOrders = (params: PathParams, options?: QueryOptions<OrderDisplayDto[]>) => {
  const business = useSelectedBusiness();
  const baseUrl = `/api/businesses/${business.id}/orders`;
  const urlParams = new URLSearchParams();

  urlParams.append('completed', params.completed.toString());

  return useUserQuery<OrderDisplayDto[]>(
    { path: `${baseUrl}?${urlParams.toString()}` },
    options);
}

export default useGetOrders;
