import React from 'react';
import { FieldValues } from 'react-hook-form';
import { UnitType } from '../Common/dtos/UnitDto';
import { FormCommonProps } from '../Common/Forms/FormCommon';
import { FormRowSize } from '../Common/Forms/FormRow';
import { unitList } from '../Common/Units'
import { FormSelectSearch, FormSelectSearchControl, RowFormSelectSearch } from '../Common/Forms/SelectSearch';

interface UnitSelectProps<FormType extends FieldValues> extends FormCommonProps<FormType> {
  id: string,
  'data-testid'?: string,
  unitType?: UnitType
  selectedValue?: string,
  disabled?: boolean,
}

interface RowUnitSelectProps<FormType extends FieldValues> extends FormCommonProps<FormType>, FormRowSize {
  id: string,
  unitType?: UnitType
  selectedValue?: string,
  disabled?: boolean,
}

function unitSelectOptionList(unitType?: UnitType) {
  return unitList()
    .filter(unit => unitType === undefined || unit.type === unitType)
    .map(unit => { return { value: unit.value, name: unit.name }; });
}

export function UnitsSelectControl(props: UnitSelectProps<any>) {
  return (<FormSelectSearchControl
    {...props}
    selectOptions={unitSelectOptionList(props.unitType)}
    hideSearch={true}
  />);
}

export function UnitsSelect(props: UnitSelectProps<any>) {
  return (<FormSelectSearch
    {...props}
    selectOptions={unitSelectOptionList(props.unitType)}
    hideSearch={true}
  />);
}

export function RowUnitsSelect(props: RowUnitSelectProps<any>) {
  return (<RowFormSelectSearch
    {...props}
    selectOptions={unitSelectOptionList(props.unitType)}
    hideSearch={true}
  />);
}