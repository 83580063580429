import { QueryOptions } from "../components/Common/useQuery";
import useUserQuery from "../components/Common/useUserQuery";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";

export interface ApplicatorDto {
  id: string;
  name: string;
  license?: string;
  epaNumber?: string;
  email?: string;
}

const useGetApplicators = (options?: QueryOptions<ApplicatorDto[]>) => {
  const business = useSelectedBusiness();

  return useUserQuery<ApplicatorDto[]>({ path: `/api/businesses/${business.id}/applicators` }, options);
}

export default useGetApplicators;