import { QueryOptions } from "../Common/useQuery";
import useUserQuery from "../Common/useUserQuery";
import { useUser } from "../UserAccess/useUser";

export type TransportsLoadedResponse = {
  startOfHour?: string,
  startOfDay?: string,
  startOfWeek?: string,
  startOfMonth?: string,
  loadedAmountHour?: number,
  loadedAmountDay?: number,
  loadedAmountWeek?: number,
  loadedAmountMonth?: number,
  loadedAmountYear?: number,
}

export type PathParams = {
  timeZoneOffset: number,
  siteId?: string,
}

const useGetTransportsLoaded = (params: PathParams, options?: QueryOptions<TransportsLoadedResponse>) => {
  const user = useUser();
  const baseUrl = `/api/businesses/${user.selectedBusiness?.id!}/transportsLoaded`;
  const urlParams = new URLSearchParams();

  if (params.timeZoneOffset) urlParams.append('timeZoneOffset', params.timeZoneOffset.toString());
  if (params.siteId) urlParams.append('siteId', params.siteId);

  return useUserQuery<TransportsLoadedResponse>(
    { path: `${baseUrl}?${urlParams.toString()}` },
    options);
}

export default useGetTransportsLoaded;
