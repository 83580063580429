import { QueryOptions } from "../components/Common/useQuery"
import { useUser } from "../components/UserAccess/useUser"
import useUserQuery from "../components/Common/useUserQuery"

export enum OrderStatus {
  Processing = 'Processing',
  Warning = 'Warning',
  Processed = 'Processed',
}

export type IntegrationOrderExportStatusDto = {
  state: OrderStatus,
  orderNumber: string,
  siteNames: string,
  completedAt: string,
}

export type IntegrationOrderExportStatusResponse = {
  integrationOrderExportStatuses: IntegrationOrderExportStatusDto[]
}

export type PathParams = {
  orderCount: number,
}

const useGetIntegrationOrderExportStatuses = (params: PathParams, options?: QueryOptions<IntegrationOrderExportStatusResponse>) => {
  const user = useUser();
  const baseUrl = `/api/businesses/${user.selectedBusiness?.id!}/integrationOrderExportStatuses`;
  const urlParams = new URLSearchParams();

  if (params.orderCount) urlParams.append('orderCount', params.orderCount.toString());

  return useUserQuery<IntegrationOrderExportStatusResponse>(
    { path: `${baseUrl}?${urlParams.toString()}` },
    options);
}

export default useGetIntegrationOrderExportStatuses;