import React, { useState } from 'react';
import { Row, Container, Table } from 'reactstrap';
import { CenteredSpinner } from '../Common/CenteredSpinner';
import KaAlert from '../Common/KaAlert';
import useDisplayMessage from '../Common/useDisplayMessage';
import useUserQuery, { UserQuerySpec } from '../Common/useUserQuery';
import { useUser } from '../UserAccess/useUser';
import { CreateEditSite } from './CreateEditSite';
import SiteDto from '../Common/dtos/SiteDto';
import SiteUpsertDto from '../Common/dtos/SiteUpsertDto';
import BusinessAdminTemplate from '../UserAccess/BusinessAdminTemplate';
import { isNullOrUndefined } from 'util';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import BoldButton from '../Common/BoldButton';

const getSpec = (businessId?: string): UserQuerySpec => ({
  path: `/api/businesses/${businessId}/sites`,
});

const SitePage = () => {
  const user = useUser();
  const business = user.selectedBusiness;
  const getSites = useUserQuery<SiteDto[]>(getSpec(business?.id), { autoQuery: business?.administrator });
  const [showModal, setShowModal] = useState(false);
  const displayMessage = useDisplayMessage();
  const [editingSite, setEditingSite] = useState<SiteDto | null>(null);

  const createEditSuccess = (s: SiteUpsertDto) => {
    displayMessage.success(`Site ${s.name} ${editingSite ? 'updated' : 'created'}`);
    getSites.query();
    setShowModal(false);
  }

  const editClick = (s: SiteDto) => {
    setEditingSite(s);
    setShowModal(true);
  }

  const createClick = () => {
    setEditingSite(null);
    setShowModal(true);
  }

  const unusedSites = isNullOrUndefined(business) || isNullOrUndefined(getSites.data) ? 0 :
    business.siteAllowance - getSites.data.length;

  if (getSites.isLoading) return <CenteredSpinner />
  if (getSites.isError) return <h3>{getSites.error?.message}</h3>

  return <BusinessAdminTemplate>
    <Container>
      {showModal &&
        <CreateEditSite
          onSuccess={createEditSuccess}
          user={user}
          initialSite={editingSite}
          onHide={() => setShowModal(false)} />
      }
      <Row className="justify-content-between mb-2">
        <div className="col-auto">
          <Row>
            <h2 className="col-auto my-auto ka-blue">Sites</h2>
            <label className="col-auto my-auto" title="unusedSites">({unusedSites} Remaining)</label>
          </Row>
        </div>
        <OverlayTrigger placement="bottom-start"
          overlay={unusedSites <= 0 ?
            <Tooltip>
              Please contact Kahler Automation to purchase additional sites.
            </Tooltip> : <></>}>
          <div className="col-auto" data-testid="create-site">
            <BoldButton
              className="btn-ghost-primary"
              onClick={createClick}
              disabled={unusedSites <= 0}
              style={{ pointerEvents: unusedSites <= 0 ? 'none' : 'auto' }}>Create Site</BoldButton>
          </div>
        </OverlayTrigger>
      </Row>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <Table className="border">
        <thead>
          <tr>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {getSites.data?.map(site =>
            <tr key={site.id}>
              <td>{site.name}</td>
              <td align="right"><BoldButton className="btn-ghost-secondary" onClick={() => editClick(site)}>Edit</BoldButton></td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  </BusinessAdminTemplate>
}

export { SitePage };