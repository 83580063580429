import React, { ReactNode } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Item } from './Item';

export function SortableItem(props: { key: string, children?: ReactNode, id: string, handle?: boolean }) {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({
    id: props.id,
  });
  
  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <Item 
      ref={setNodeRef} 
      style={style} 
      isOpacityEnabled={isDragging} 
      handleProps={props.handle ? { ref: setActivatorNodeRef, } : undefined} 
      attributes={attributes}
      listeners={listeners}
      {...props} />
  );
}