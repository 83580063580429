import React from 'react';
import Button from 'react-bootstrap/Button';
import { ButtonProps } from 'reactstrap';

interface SpinnerButtonProps extends ButtonProps {
  spinning: boolean,
  small?: boolean,
  'data-testid'?: string
}

export default function SpinnerButton(props: SpinnerButtonProps) {
  const { spinning, small, ...rest } = props;
  return <Button
    {...rest}
    variant="link"
    disabled={props.disabled || props.spinning}
    size={props.small ? "sm" : undefined}
    data-testid={(props['data-testid'] ?? "").trim().length > 0 ? props['data-testid'] : ""}
  >
    {props.spinning
      ? <span className="spinner-border spinner-border-sm" role="status" aria-busy="true"></span>
      : <strong>{props.children}</strong>}
  </Button>
}