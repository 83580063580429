import { EventType, IPublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import { useEffect } from "react";
import { useLogException } from "../../Monitoring/useMonitoring";

enum Errors {
  ForgotPassword = 'AADB2C90118',
  UserCanceled = 'AADB2C90091',
}

const useHandleForgotPassword = (pca: IPublicClientApplication, request: RedirectRequest) => {
  const logException = useLogException();

  useEffect(() => {
    const callbackId = pca.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE && event.error) {
        if (event.error.errorMessage.indexOf(Errors.ForgotPassword) > -1) {
          pca.loginRedirect(request);
          return;
        }

        if (event.error.errorMessage.indexOf(Errors.UserCanceled) > -1) {
          return;
        }

        logException(event.error, 3, { correlationId: event.error.correlationId });
      }
    });

    return () => {
      if (callbackId) {
        pca.removeEventCallback(callbackId);
      }
    };
  }, [pca, request, logException]);
}

export default useHandleForgotPassword;
