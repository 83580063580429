import { useState } from 'react';
import { DisplayMessage } from './KaAlert'

interface UseDisplayMessage {
  message: DisplayMessage | null,
  success: (message: string) => void,
  fail: (message: string) => void,
  clear: () => void,
}

export const useDisplayMessage = (): UseDisplayMessage => {
  const [message, setDisplayMessage] = useState<DisplayMessage | null>(null);
  const success = (message: string) => setDisplayMessage({ message: message, success: true });
  const fail = (message: string) => setDisplayMessage({ message: message, success: false });
  const clear = () => setDisplayMessage(null);

  return { message, success, fail, clear };
}

export default useDisplayMessage;
