import { HttpMethod } from "../components/Common/useFetch";
import { RequestOptions } from "../components/Common/useRequest";
import useUserRequest, { UserRequestSpec } from "../components/Common/useUserRequest";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";
import { TransportDto } from "./useGetTransports";

export type TransportUpsertDto = Omit<TransportDto, 'id'>;

const path = (businessId: string) => `/api/businesses/${businessId}/transports`;

const createSpec = (businessId: string): UserRequestSpec => ({
  path: path(businessId),
  method: HttpMethod.POST,
});

const updateSpec = (businessId: string, transportId: string): UserRequestSpec => ({
  path: `${path(businessId)}/${transportId}`,
  method: HttpMethod.PUT,
});

const useUpsertTransport = (transportId?: string, options?: RequestOptions<string>) => {
  const business = useSelectedBusiness();

  var spec = transportId
    ? updateSpec(business.id, transportId)
    : createSpec(business.id);

  return useUserRequest<TransportUpsertDto, string>(spec, options);
}

export default useUpsertTransport;
