import React, { useState } from "react";
import { Path, useForm } from "react-hook-form";
import { RowFormText } from "../Common/Forms/FormText";
import KaAlert from "../Common/KaAlert";
import KaModal from "../Common/KaModal";
import SpinnerButton from "../Common/SpinnerButton";
import useDisplayMessage from "../Common/useDisplayMessage";
import { CarrierDto } from "../../requests/useGetCarriers";
import useUpsertCarrier, { CarrierUpsertDto } from "../../requests/useUpsertCarrier";
import { RowHooksFormTextArea } from "../Common/Forms/FormTextArea";

interface CreateEditCarrierProps {
  initialCarrier: CarrierDto | null;
  onDismiss: () => void;
  onUpsert: (carrierId: string, carrierName: string, assignSites: boolean) => void;
}

export const CreateEditCarrier = (props: CreateEditCarrierProps) => {
  const displayMessage = useDisplayMessage();
  const { id, ...initcarrier } = { ...props.initialCarrier };
  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm<CarrierUpsertDto>({
    defaultValues: { ...initcarrier }
  });

  const [assignSites, setAssignSites] = useState(false);

  const dismissed = () => {
    props.onDismiss();
  };

  const upsert = useUpsertCarrier(props.initialCarrier?.id, {
    onSuccess: response => {
      props.onDismiss();
      const carrierId = props.initialCarrier ? props.initialCarrier.id : response;
      props.onUpsert(carrierId, getValues().name, assignSites);
    },
    onError: (e) => displayMessage.fail(e.message),
  });

  const submit = (formValues: CarrierUpsertDto, assign: boolean) => {
    setAssignSites(assign);
    upsert.request(formValues);
  }

  const formProps = (name: Path<CarrierUpsertDto>) => ({
    labelSpace: 4,
    inputSpace: 7,
    name: name,
    setValue,
    register,
    errors,
  });

  return <KaModal
    title={`${props.initialCarrier ? 'Update' : 'Create'} a carrier`} onHide={dismissed} show={true}
    body={(<>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <RowFormText {...formProps('name')}
        options={{
          required: 'Name is required',
          validate: { length: v => v.length < 51 || 'Name cannot be longer than 50 characters' }
        }} />
      <RowFormText {...formProps('number')}
        displayName="Carrier Number"
        options={{
          validate: { length: v => v.length < 51 || 'Number cannot be longer than 50 characters' }
        }} />
      <RowFormText {...formProps("addressStreet")}
        displayName="Street"
        options={{
          validate: {
            length: v => v.length < 51 || 'Address Street cannot be longer than 50 characters'
          }
        }} />
      <RowFormText {...formProps("addressCity")}
        displayName="City"
        options={{
          validate: {
            length: v => v.length < 51 || 'Address City cannot be longer than 50 characters'
          }
        }} />
      <RowFormText {...formProps("addressState")}
        displayName="State"
        options={{
          validate: {
            length: v => v.length < 51 || 'Address State cannot be longer than 50 characters'
          }
        }} />
      <RowFormText {...formProps("addressPostalCode")}
        displayName="Postal Code"
        options={{
          validate: {
            length: v => v.length < 16 || 'Address Postal Code cannot be longer than 15 characters'
          }
        }} />
      <RowFormText {...formProps("addressCountry")}
        displayName="Country"
        options={{
          validate: {
            length: v => v.length < 101 || 'Address Country cannot be longer than 100 characters'
          }
        }} />
      <RowFormText {...formProps('phone')}
        options={{
          validate: { length: v => v.length < 51 || 'Phone cannot be longer than 50 characters' }
        }} />
      <RowFormText {...formProps("email")}
        options={{
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Email Address is invalid'
          }
        }} />
      <RowHooksFormTextArea {...formProps("notes")} />
    </>)}
    footer={(
      <div className="btn-group">
        <SpinnerButton
          className="w-25 m-1 rounded btn-ghost-primary"
          spinning={upsert.isLoading && !upsert.isError}
          onClick={handleSubmit(values => submit(values, false))}>
          {`${props.initialCarrier ? 'Update' : 'Create'}`}
        </SpinnerButton>
        <SpinnerButton
          className="w-25 m-1 rounded btn-ghost-primary"
          spinning={upsert.isLoading && !upsert.isError}
          onClick={handleSubmit(values => submit(values, true))}>
          {`${props.initialCarrier ? 'Update and Assign' : 'Create and Assign'}`}
        </SpinnerButton>
      </div>)
    }
  />
};
