import React from "react"
import Select, { GroupBase, IndicatorSeparatorProps, MultiValue, OptionProps, Props, SingleValue, components } from 'react-select';

// Function is useful for solving type problems in onChange handler
export function isMultiValue<T>(arg: MultiValue<T> | SingleValue<T>): arg is MultiValue<T> {
  return Array.isArray(arg);
}

export const KaSelect = React.forwardRef(function KaSelect<
  Option extends { value: string, label: string },
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>>(props: Props<Option, IsMulti, Group>, ref: any) {

  const IndicatorSeparator = () => (
    <></>
  )

  const IconOption = (props: OptionProps<Option>) => (
    <components.Option {...props}>
      <div className={props.isSelected ? "selected-option" : ""}>
        {props.data.label}
      </div>
    </components.Option>
  );

  return <Select
    {...props}
    ref={ref}
    components={props.isClearable
      ? { Option: IconOption }
      : { IndicatorSeparator, Option: IconOption }}
    styles={{
      option: (provided, state) => ({
        ...provided,
        color: '#282a2c',
        backgroundColor: state.isSelected ? '#ebedf1' : 'inherit',
        '&:hover': { backgroundColor: '#ebedf1' }
      }),
      control: (base) => ({
        ...base,
        borderColor: "lightgrey",
        boxShadow: 'none',
        "&:hover": { borderColor: 'lightgrey' }
      }),
    }}
  />

});