import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { CenteredSpinner } from '../Common/CenteredSpinner';
import KaAlert from '../Common/KaAlert';
import KaModal from '../Common/KaModal';
import SpinnerButton from '../Common/SpinnerButton';
import useDisplayMessage from '../Common/useDisplayMessage';
import { HttpMethod } from '../Common/useFetch';
import useUserRequest, { UserRequestSpec } from '../Common/useUserRequest';
import { useUser } from '../UserAccess/useUser';
import useUserQuery, { UserQuerySpec } from '../Common/useUserQuery';
import BusinessAdminTemplate from '../UserAccess/BusinessAdminTemplate';
import { Row } from 'reactstrap';
import BoldButton from '../Common/BoldButton';

const getSpec = (businessId?: string): UserQuerySpec => ({
    path: `/api/businesses/${businessId}/externalIntegrations`,
});

interface ExternalIntegrationClientDto {
  clientId: string;
}

const registerSpec = (businessId?: string): UserRequestSpec => ({
  path: `/api/businesses/${businessId}/registerExternalIntegration`,
  method: HttpMethod.POST
});

interface RegisterDto { }

interface RegisteredDto {
  clientId: string,
  secret: string,
}

interface RegisteredFormProps {
  visible: boolean;
  clientId: string;
  secret: string;
  onHide: () => void;
}

const RegisteredForm = (props: RegisteredFormProps) => {
  return (
    <KaModal
      title="External integration registered"
      onHide={props.onHide}
      show={props.visible}
      body={<>
        <p>The following client ID and secret are used by the external integration to authenticate.</p>
        <ul>
          <li>Client ID: {props.clientId}</li>
          <li>Secret: {props.secret}</li>
        </ul>
      </>} />);
};

const removeSpec = (businessId?: string, clientId?: string): UserRequestSpec => ({
  path: `/api/businesses/${businessId}/externalintegrations/${clientId}`,
  method: HttpMethod.DELETE,
});

interface ConfirmRemoveProps {
  visible: boolean;
  working: boolean;
  clientId: string;
  onDismiss: () => void;
  onConfirm: () => void;
}

const ConfirmRemove = (props: ConfirmRemoveProps) => {
  return (<KaModal
    onHide={props.onDismiss}
    show={props.visible}
    title='Remove External Integration'
    body={`Are you sure that you want to permanently remove ${props.clientId}`}
    footer={
      <div className="container">
        <div className="row justify-content-between">
          <BoldButton className='col-3 btn-ghost-secondary' onClick={props.onDismiss}>Cancel</BoldButton>
          <SpinnerButton
            spinning={props.working}
            className='col-3 btn-ghost-danger'
            onClick={props.onConfirm}>
              Continue
          </SpinnerButton>
        </div>
      </div>
    }
  />)
};

interface ExternalIntegrationsListProps {
  obscured: boolean;
  onRemoveClicked: (client: ExternalIntegrationClientDto) => void;
}

const ExternalIntegrationsList = (props: ExternalIntegrationsListProps) => {
  const user = useUser();
  const getExternalIntegrations = useUserQuery<ExternalIntegrationClientDto[]>(getSpec(user.selectedBusiness?.id));

  useEffect(() => getExternalIntegrations.query(), [props.obscured]);

  if (getExternalIntegrations.isLoading) return <CenteredSpinner />;
  if (getExternalIntegrations.isError) return <>{getExternalIntegrations.error?.message}</>;

  return (
    <Table bordered>
      <thead>
        <tr>
          <th>Client ID</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {getExternalIntegrations.data?.map(client =>
          <tr key={client.clientId}>
            <td>{client.clientId}</td>
            <td>
              <BoldButton
                className="btn-ghost-danger"
                onClick={() => props.onRemoveClicked(client)}>
                Remove</BoldButton>
            </td>
          </tr>)}
      </tbody>
    </Table>);
};

export const ExternalIntegrationsPage = () => {
  const user = useUser();
  const [showRegisteredForm, setShowRegisteredForm] = useState(false);
  const [clientId, setClientId] = useState("");
  const [secret, setSecret] = useState("");
  const [confirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  const [clientIdToRemove, setClientIdToRemove] = useState("");
  const displayMessage = useDisplayMessage();
  const remove = useUserRequest(
    removeSpec(user.selectedBusiness?.id, clientIdToRemove), {
      onSuccess: () => {
        setConfirmRemoveVisible(false);
        displayMessage.success(`Client ${clientIdToRemove} removed.`);
      },
      onError: (err) => {
        setConfirmRemoveVisible(false);
        displayMessage.fail(err.message);
      }
    });

  const registered = (dto: RegisteredDto) => {
    setClientId(dto.clientId);
    setSecret(dto.secret);
    setShowRegisteredForm(true);
  };

  const useRegister = useUserRequest<RegisterDto, RegisteredDto>(registerSpec(user.selectedBusiness?.id), {
    onSuccess: (response) => registered(response),
    onError: (e) => displayMessage.fail(e.message)
  });

  const register = () => {
    useRegister.request({ });
  };

  const acknowledge = () => {
    setShowRegisteredForm(false);
    setClientId("");
    setSecret("");
  };

  const removeClicked = (client: ExternalIntegrationClientDto) => {
    setClientIdToRemove(client.clientId);
    setConfirmRemoveVisible(true);
  };

  const confirmRemoveDismissed = () => setConfirmRemoveVisible(false);

  const removeConfirmed = () => {
    remove.request(null);
  };

    return <BusinessAdminTemplate>
      <Container>
        <RegisteredForm
          visible={showRegisteredForm}
          clientId={clientId}
          secret={secret}
          onHide={acknowledge} />
        <ConfirmRemove
          visible={confirmRemoveVisible}
          working={remove.isLoading}
          clientId={clientIdToRemove}
          onDismiss={confirmRemoveDismissed}
          onConfirm={removeConfirmed} />
        <Row className="justify-content-between mb-2">
          <h2 className="col-auto ka-blue">External Integrations</h2>
          <BoldButton className="col-auto btn-ghost-primary" onClick={register}>Register Integration</BoldButton>
        </Row>
        <p><a href="./docs/extapi/index.html" target="_blank">API Documentation</a></p>
        <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
        <ExternalIntegrationsList
          obscured={showRegisteredForm || confirmRemoveVisible}
          onRemoveClicked={removeClicked} />
      </Container>
    </BusinessAdminTemplate>;
}