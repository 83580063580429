import { HttpMethod } from "../components/Common/useFetch";
import { RequestOptions } from "../components/Common/useRequest";
import useUserRequest from "../components/Common/useUserRequest";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";

export interface UpdateTransportsLoadedWidgetSettingsDto {
  name: string;
  selectedSiteId?: string;
}

const useUpdateTransportsLoadedWidgetSettings = (widgetId: string, options?: RequestOptions<void>) => {
  const business = useSelectedBusiness();
  return useUserRequest<UpdateTransportsLoadedWidgetSettingsDto>({
    path: `/api/businesses/${business.id}/transportsLoadedWidgetSettings/${widgetId}`,
    method: HttpMethod.PUT,
  }, options);
}

export default useUpdateTransportsLoadedWidgetSettings;