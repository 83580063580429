import { Reducer, useCallback, useEffect, useReducer } from "react";
import { useIsMounted } from "./useIsMounted";
import { useLogException, useTraceID } from "../Monitoring/useMonitoring";
import { SeverityLevel } from "@microsoft/applicationinsights-common";

export interface FetchSpec {
  path: string,
  method: HttpMethod,
  headers: () => Promise<Headers>,
}

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface Error {
  message: string;
}

interface ErrorResponse {
  message: string;
  operationId: string;
}

interface Fetch<Resp, Req> {
  isLoading: boolean,
  isError: boolean,
  isSuccess: boolean,
  fetch: (r: Req) => void,
  data: Resp | null,
  error: Error | null,
}

export interface FetchOptions<Resp> {
  onSuccess?: (resp: Resp) => void,
  onError?: (error: Error) => void,
  onComplete?: () => void,
}

enum RequestStep {
  Idle,
  RequestStarting,
  Requesting,
  Succeeded,
  Errored
}

enum RequestAction {
  Request,
  RequestStarted,
  Success,
  Error
}

type State<Req, Resp> =
  | { step: RequestStep.Idle }
  | { step: RequestStep.RequestStarting, body: Req }
  | { step: RequestStep.Requesting }
  | { step: RequestStep.Succeeded, data: Resp }
  | { step: RequestStep.Errored, error: Error }

type Action<Req, Resp> =
  | { type: RequestAction.Request, body: Req }
  | { type: RequestAction.RequestStarted }
  | { type: RequestAction.Success, data: Resp }
  | { type: RequestAction.Error, error: Error }

type FetchReducer<Req, Resp> = Reducer<State<Req, Resp>, Action<Req, Resp>>;

function reducer<Req, Resp>(state: State<Req, Resp>, action: Action<Req, Resp>): State<Req, Resp> {
  switch (action.type) {
    case RequestAction.Request:
      return { step: RequestStep.RequestStarting, body: action.body };
    case RequestAction.RequestStarted:
      return { step: RequestStep.Requesting }
    case RequestAction.Success:
      return {
        step: RequestStep.Succeeded,
        data: action.data
      };
    case RequestAction.Error:
      return {
        step: RequestStep.Errored,
        error: action.error
      };
  }
}

export default function useFetch<Resp, Req = void>(
  spec: FetchSpec, options?: FetchOptions<Resp>): Fetch<Resp, Req> {

  const isMounted = useIsMounted();
  const [state, dispatch] = useReducer<FetchReducer<Req, Resp>>(reducer, { step: RequestStep.Idle });

  const fetch = useCallback((body: Req) => {
    dispatch({ type: RequestAction.Request, body: body })
  }, []);

  const logException = useLogException();
  const traceId = useTraceID();
  const stackTrace = Error().stack;

  const fetchProcess = useCallback(async (body: Req) => {
    dispatch({ type: RequestAction.RequestStarted });

    const headers = await spec.headers();

    headers.append('Content-Type', 'application/json');

    var response = await window.fetch(spec.path, {
      method: spec.method,
      body: JSON.stringify(body),
      headers,
    });

    if (!response.ok) {
      const responseBody = (await response.json()) as ErrorResponse;
      let message: string;
      if (response.status === 499) {
        message = responseBody.message;
      } else {
        message = `An unexpected error occurred. Please contact Kahler Automation with the following operation ID: ${traceId}`;

        logException(
          Error(responseBody.message),
          SeverityLevel.Error,
          { requestOperationId: responseBody.operationId, stackTrace }
        );
      }

      if (isMounted()) {
        dispatch({ type: RequestAction.Error, error: { message } })
        if (options?.onError) options.onError({ message });
        if (options?.onComplete) options.onComplete();
      }
      return;
    }

    let respAsJson;
    try {
      respAsJson = await response.json();
    } catch (e) {
      respAsJson = {};
    }
    if (isMounted()) {
      dispatch({ type: RequestAction.Success, data: respAsJson });
      if (options?.onSuccess) options.onSuccess(respAsJson);
      if (options?.onComplete) options.onComplete();
    }
  }, [spec, options, isMounted]);

  useEffect(() => {
    if (state.step === RequestStep.RequestStarting) {
      fetchProcess(state.body);
    }
  }, [state]);

  const idleReturn: Fetch<Resp, Req> = {
    fetch,
    data: null,
    error: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
  }

  switch (state.step) {
    case RequestStep.Idle:
      return idleReturn;
    case RequestStep.Requesting:
    case RequestStep.RequestStarting:
      return {
        ...idleReturn,
        isLoading: true
      };
    case RequestStep.Succeeded:
      return {
        ...idleReturn,
        isSuccess: true,
        data: state.data,
      };
    case RequestStep.Errored:
    default:
      return {
        ...idleReturn,
        isError: true,
        error: state.error
      };
  }
}
