import React from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip as ChartToolTip,
  Plugin as ChartPlugin,
  Legend,
  TooltipPositionerFunction,
  ChartType,
  BarElement,
  ChartOptions,
  Point,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { BulkProductAllocationDto } from "../Requests/useGetBulkProductAllocation";
import { errorCircleIcon } from "../BootstrapIcons/ErrorIcons";


declare module 'chart.js' {
  interface TooltipPositionerMap {
    CustomPositioner: TooltipPositionerFunction<ChartType>;
  }
}

export const BulkProductAllocationDisplay = ({ data, unit }: { data: BulkProductAllocationDto[], unit: string }) => {

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    ChartToolTip,
  );

  const labels = data.map((product) => product.name);
  const overAllocatedBars: Array<number> = [];

  const getErrorBars = () => {
    const errorBars: Array<number> = [];
    data.forEach((dto, i) => {
      if (dto.error) {
        errorBars.push(i);
      }
    })

    return errorBars;
  }

  const openOrderAllocationData = data.map((product, i) => {
    if (!product.openOrderAllocation || product.openOrderAllocation < 0) { return 0; }
    if (product.currentInventory! >= product.openOrderAllocation!) { return product.openOrderAllocation!; }
    overAllocatedBars.push(i);
    return product.currentInventory!;
  });

  const currentInventoryData = data.map((product) => {
    if (!product.currentInventory || product.currentInventory < 0) { return 0; }
    if (product.currentInventory! >= product.openOrderAllocation!) { return product.currentInventory! - product.openOrderAllocation!; }
    return product.openOrderAllocation! - product.currentInventory!;
  });

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Allocated to Open Orders',
        data: openOrderAllocationData,
        borderWidth: 0,
        backgroundColor: openOrderAllocationData.map((_, i) => overAllocatedBars.includes(i) ? 'rgba(237, 71, 52)' : 'rgb(141,183,225)'),
        categoryPercentage: 0.95,
        barPercentage: 0.8,
        errorBars: getErrorBars(),
      },
      {
        label: 'Current Inventory',
        data: currentInventoryData,
        borderWidth: 0,
        backgroundColor: currentInventoryData.map((_, i) => overAllocatedBars.includes(i) ? 'rgb(246,163,154)' : 'rgba(27, 110, 194)'),
        categoryPercentage: 0.95,
        barPercentage: 0.8,
      },
    ],
  };

  ChartToolTip.positioners.CustomPositioner = function (_, position: Point) { return position; }

  const WarningDataLabel: ChartPlugin = {
    id: "WarningDataLabel",
    afterDatasetsDraw: (chart: ChartJS) => {
      const errorBars: Array<number> = (chart.data.datasets[0] as any).errorBars;
      errorBars.forEach(i => {
        const ctx = chart.ctx as CanvasRenderingContext2D;

        const point = chart.getDatasetMeta(0).data[i];
        const errorIconBase64 = btoa(errorCircleIcon);
        const img = new Image();
        img.src = `data:image/svg+xml;base64,${errorIconBase64}`;

        ctx.drawImage(img, point.x + 5, point.y - 8);
      });
    },
  }

  const chartOptions: ChartOptions = {
    indexAxis: 'y' as const,
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    interaction: {
      mode: 'nearest' as const,
      axis: 'y' as const,
      intersect: false
    },
    layout: {
      padding: {
        bottom: 2,
      }
    },
    scales: {
      x: {
        stacked: 'single' as const,
        grid: {
          drawTicks: false
        }
      },
      y: {
        stacked: 'single' as const,
        grid: {
          drawTicks: false
        },
        ticks: {
          padding: 4,
          callback: function (index: number) {
            const labelLengthLimit = 15;
            const productName = data[index].name;
            if (productName.length <= labelLengthLimit) { return productName; }
            const subString = productName.slice(0, labelLengthLimit - 1);
            return subString.slice(0, subString.lastIndexOf(" ")) + "...";
          }
        }
      }
    } as any,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
          generateLabels: (chart) => {
            return [
              {
                datasetIndex: 0,
                text: "Allocated to Open Orders",
                fontColor: '#666',
                fillStyle: 'rgb(141,183,225)',
                strokeStyle: 'rgb(141,183,225)',
                hidden: !chart.getDatasetMeta(0).visible,
              },
              {
                datasetIndex: 1,
                text: "Current Inventory",
                fontColor: '#666',
                fillStyle: 'rgba(27, 110, 194)',
                strokeStyle: 'rgba(27, 110, 194)',
                hidden: !chart.getDatasetMeta(1).visible,
              }
            ];
          }
        },
        onClick: function (e: any) { return; }
      },
      tooltip: {
        position: 'CustomPositioner' as const,
        enabled: false,
        external: function (context) {
          var tooltipEl = document.getElementById('chartjs-tooltip-bulkProductAllocation');

          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.className = "bg-white border border-2 rounded-3 chart-tooltip";
            tooltipEl.id = 'chartjs-tooltip-bulkProductAllocation';
            document.body.appendChild(tooltipEl);
          }

          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          } else {
            tooltipEl.style.opacity = "1";
          }

          const currentProduct = data.find((dp) => dp.name === tooltipModel.title[0]);

          if (tooltipModel.body && currentProduct?.error) {

            tooltipEl.innerHTML = `
              <table>
                <thead>
                  <tr>
                    <th class="ka-blue fs-5 fw-bold">
                      ${tooltipModel.title[0]}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span style="color:rgba(237, 71, 52);" class="text-wrap">
                        <i class="bi bi-x-circle-fill pe-1"></i>${currentProduct?.error}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>`;

          } else if (tooltipModel.body) {
            const tableHeadHtml = `
                <tr>
                  <th>
                    <span class="ka-blue fs-5 fw-bold pe-3">${tooltipModel.title[0]}</span>
                    <span class="text-secondary" style="float:right;">(${unit})</span>
                  </th>
                </tr>`;

            const barIndex = context.tooltip.dataPoints[0].dataIndex;
            const barColors = [
              overAllocatedBars.includes(barIndex) ? 'rgb(246,163,154)' : 'rgb(141,183,225)',
              overAllocatedBars.includes(barIndex) ? 'rgba(237, 71, 52)' : 'rgba(27, 110, 194)'
            ]

            var tableBodyHtml = `
              <tr>
                <td>
                  <i class="bi bi-circle-fill me-1" style="color: ${barColors[0]}"></i>
                  <span class="ka-blue pe-3">Allocated to Open Orders:
                    <span style="float:right;">${currentProduct?.openOrderAllocation?.toLocaleString()}</span>
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <i class="bi bi-circle-fill me-1" style="color: ${barColors[1]}"></i>
                  <span class="ka-blue pe-3">Current Inventory:
                    <span style="float:right;">${currentProduct?.currentInventory?.toLocaleString()}</span>
                  </span>
                </td>
              </tr>`;

            const remainingInventory = (currentProduct?.currentInventory ?? 0) - (currentProduct?.openOrderAllocation ?? 0);
            const remainingInventoryStyle = 'float:right;' + (remainingInventory < 0 ? 'color: rgba(237, 71, 52)' : '')
            const subtractionRowHtml = `
              <tr>
                <td>
                  <hr class="text-dark" style="margin: 2px -3px 5px 20px">
                  <i class="bi bi-circle-fill me-1" style="color: rgba(0, 0, 0, 0)"></i>
                  <span class="text-secondary pe-3">After Order Fulfillment:
                    <span style="${remainingInventoryStyle}">${remainingInventory.toLocaleString()}</span>
                  </span>
                </td>
              </tr>`;
            tableBodyHtml += subtractionRowHtml

            tooltipEl.innerHTML = `
                <table>
                  <thead>
                    ${tableHeadHtml}
                  </thead>
                  <tbody>
                    ${tableBodyHtml}
                  </tbody>
                </table>
              `;
          }

          const position = context.chart.canvas.getBoundingClientRect();
          const mousePositionLeft = position.left + window.pageXOffset;
          const mousePositionTop = position.top + window.pageYOffset;

          var tooltipLeft;
          var tooltipTop;

          const minTooltipWidth = Math.max(tooltipEl.clientWidth + 10, 320);

          if (window.screen.width - (tooltipModel.caretX + mousePositionLeft) < minTooltipWidth) {
            // Tooltip to the left
            tooltipLeft = mousePositionLeft + tooltipModel.caretX - tooltipEl.clientWidth - 10 + 'px';
            tooltipTop = mousePositionTop + tooltipModel.caretY - (tooltipEl.clientHeight / 2) + 'px';
          } else {
            // Tooltip right
            tooltipLeft = position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px';
            tooltipTop = position.top + window.pageYOffset + tooltipModel.caretY - (tooltipEl.clientHeight / 2) + 'px';;
          }

          tooltipEl.style.opacity = "1";
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = tooltipLeft;
          tooltipEl.style.top = tooltipTop;
          tooltipEl.style.maxWidth = '450px';
          tooltipEl.style.padding = '10px';
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.zIndex = "9999";

        }
      }
    },
  };

  return <Bar
    options={chartOptions}
    data={chartData}
    plugins={[ChartToolTip, WarningDataLabel]} />
}