export function isNullOrEmpty(string: string | undefined): boolean {
  return (string == null || string.trim() === "");
}

export function guidIsNullOrEmpty(string: string | undefined): boolean {
  return (isNullOrEmpty(string) || string === "00000000-0000-0000-0000-000000000000")
}

export function exceedsMaxLength(string: string | undefined, maxLength: number): boolean {
  return (!isNullOrEmpty(string) && string!.length > maxLength);
}

export function isValidEmail(email: string): boolean {
  const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return re.test(email);
}