import React, { useState } from 'react';
import { Container, Table, Row, Col } from 'react-bootstrap';
import KaAlert from '../Common/KaAlert'
import { useUser } from '../UserAccess/useUser';
import useDisplayMessage from '../Common/useDisplayMessage';
import useUserQuery, { UserQuerySpec } from '../Common/useUserQuery';
import useUserRequest, { UserRequestSpec } from '../Common/useUserRequest';
import { AddUser } from './AddUser';
import { CenteredSpinner } from '../Common/CenteredSpinner';
import BusinessUserDto from '../Common/dtos/BusinessUserDto';
import BusinessUserUpsertDto from '../Common/dtos/BusinessUserUpsertDto';
import BusinessAdminTemplate from '../UserAccess/BusinessAdminTemplate';
import Confirm from '../Common/Confirm';
import { HttpMethod } from '../Common/useFetch';
import { useNavigate } from 'react-router-dom';
import BoldButton from '../Common/BoldButton';

const getSpec = (businessId?: string): UserQuerySpec => ({
  path: baseUrl(businessId),
});

const promoteSpec = (businessId?: string, userId?: string): UserRequestSpec => ({
  path: `${baseUserUrl(businessId, userId)}/promote`,
  method: HttpMethod.POST,
});

const demoteSpec = (businessId?: string, userId?: string): UserRequestSpec => ({
  path: `${baseUserUrl(businessId, userId)}/demote`,
  method: HttpMethod.POST,
});

const removeSpec = (businessId?: string, userId?: string): UserRequestSpec => ({
  path: baseUserUrl(businessId, userId),
  method: HttpMethod.DELETE,
});

const baseUserUrl = (businessId?: string, userId?: string) => `${baseUrl(businessId)}/${userId}`;

const baseUrl = (businessId?: string) => `/api/businesses/${businessId}/users`;

const UserPage = () => {
  const me = useUser();
  const business = me.selectedBusiness;
  const navigate = useNavigate();
  const displayMessage = useDisplayMessage();
  const getUsers = useUserQuery<BusinessUserDto[]>(getSpec(business?.id), { autoQuery: business?.administrator });
  const [user, setUser] = useState<BusinessUserDto | null>(null);

  const [addUserVisible, setAddUserVisible] = useState(false);

  const addUserSuccess = (u: BusinessUserUpsertDto) => {
    displayMessage.success(`Added ${u.emailAddress} to business: ${business?.name}`);
    getUsers.query();
    setAddUserVisible(false);
  }

  const addClicked = () => setAddUserVisible(true);

  const addUserDismissed = () => setAddUserVisible(false);

  const [confirmPromoteVisible, setConfirmPromoteVisible] = useState(false);

  const promote = useUserRequest(
    promoteSpec(business?.id, user?.id), {
      onComplete: () => setConfirmPromoteVisible(false),
      onSuccess: () => {
        displayMessage.success(`${user?.emailAddress} promoted to an administrator.`);
        getUsers.query();
      },
      onError: (err) => displayMessage.fail(err.message)
    });

  const promoteClicked = (businessUser: BusinessUserDto) => {
    setUser(businessUser);
    setConfirmPromoteVisible(true);
  };

  const confirmPromoteDismissed = () => setConfirmPromoteVisible(false);

  const promotionConfirmed = () => promote.request(null);

  const [confirmDemoteVisible, setConfirmDemoteVisible] = useState(false);

  const demote = useUserRequest(
    demoteSpec(business?.id, user?.id), {
      onComplete: () => setConfirmDemoteVisible(false),
      onSuccess: () => {
        if (user?.id === me.id) {
          navigate('/');
          return;
        }

        displayMessage.success(`${user?.emailAddress} demoted to a user.`);
        getUsers.query();
      },
      onError: (err) => displayMessage.fail(err.message)
    });

  const demoteClicked = (businessUser: BusinessUserDto) => {
    setUser(businessUser);
    setConfirmDemoteVisible(true);
  };

  const confirmDemoteDismissed = () => setConfirmDemoteVisible(false);

  const demotionConfirmed = () => demote.request(null);

  const [confirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  
  const remove = useUserRequest(
    removeSpec(business?.id, user?.id), {
      onComplete: () => setConfirmRemoveVisible(false),
      onSuccess: () => {
        displayMessage.success(`${user?.emailAddress} removed.`);
        getUsers.query();
      },
      onError: (err) => displayMessage.fail(err.message)
    });

  const removeClicked = (businessUser: BusinessUserDto) => {
    setUser(businessUser);
    setConfirmRemoveVisible(true);
  };

  const confirmRemoveDismissed = () => setConfirmRemoveVisible(false);

  const removalConfirmed = () => remove.request(null);

  if (getUsers.isLoading) return <CenteredSpinner />
  if (getUsers.isError) return <h3>{getUsers.error?.message}</h3>

  return (<BusinessAdminTemplate>
    <Container>
      <AddUser
        visible={addUserVisible}
        onSuccess={addUserSuccess}
        user={me}
        onHide={addUserDismissed} />
      <Confirm
        visible={confirmPromoteVisible}
        title='Promote User to Administrator'
        body={`Are you sure that you want to promote ${user?.emailAddress} to an administrator`}
        onDismiss={confirmPromoteDismissed}
        onConfirm={promotionConfirmed} />
      <Confirm
        visible={confirmDemoteVisible}
        title='Demote Administrator to User'
        body={`Are you sure that you want to demote ${user?.emailAddress} to a user`}
        onDismiss={confirmDemoteDismissed}
        onConfirm={demotionConfirmed} />
      <Confirm
        visible={confirmRemoveVisible}
        title='Remove User'
        body={`Are you sure that you want to permanently remove ${user?.emailAddress}`}
        onDismiss={confirmRemoveDismissed}
        onConfirm={removalConfirmed} />
      <Row className="justify-content-between mb-2">
        <h2 className="col-auto ka-blue">Users</h2>
        <BoldButton className="col-auto btn-ghost-primary" onClick={addClicked}>Add User</BoldButton>
      </Row>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <Table bordered>
        <thead>
          <tr>
            <th>User Email</th>
            <th>Administrator</th>
            <th>Has Signed In</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {getUsers.data?.map(businessUser =>
            <tr key={businessUser.id}>
              <td>{businessUser.emailAddress}</td>
              <td>
                <Row>
                  <Col>{businessUser.administrator ? "Yes" : "No"}</Col>
                  {businessUser.id !== me.id &&
                    <Col>{businessUser.administrator
                      ? <BoldButton className='btn-ghost-danger' onClick={() => demoteClicked(businessUser)}>Demote</BoldButton>
                      : <BoldButton className='btn-ghost-primary' onClick={() => promoteClicked(businessUser)}>Promote</BoldButton>}
                    </Col>}
                </Row>
              </td>
              <td>{businessUser.hasSignedIn ? "Yes" : "No"}</td>
              <td>
                {businessUser.id !== me.id &&
                  <BoldButton className="btn-ghost-danger" onClick={() => removeClicked(businessUser)}>Remove</BoldButton>}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  </BusinessAdminTemplate>);
}

export { UserPage }