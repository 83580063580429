import React, { useState } from "react";
import { Container, Row, Table } from "reactstrap";
import { CenteredSpinner } from "../Common/CenteredSpinner";
import KaAlert from "../Common/KaAlert";
import useDisplayMessage from "../Common/useDisplayMessage";
import FilterInput, { textSatisfiesFilter } from "../FilterInput";
import { CreateEditCarrier } from "./CreateEditCarrier";
import BoldButton from "../Common/BoldButton";
import { Button } from "react-bootstrap";
import Confirm from "../Common/Confirm";
import useGetCarriers, { CarrierDto } from "../../requests/useGetCarriers";
import useRemoveCarrier from "../../requests/useRemoveCarrier";
import { SiteAssignmentForm } from "../Common/SiteAssignment/SiteAssignmentForm";
import { useUser } from "../UserAccess/useUser";

enum States {
  None,
  AddEditCarrier,
  AssignSites
}

type PopupState =
  { state: States.None; } |
  {
    state: States.AddEditCarrier;
    carrier: CarrierDto | null;
  } |
  {
    state: States.AssignSites;
    carrier: CarrierDto;
  };

const apiPath = (businessId?: string): string => `/api/businesses/${businessId}/carriers`;

const apiGetSiteAssignmentPath = (businessId?: string, carrierId?: string): string =>
  `${apiPath(businessId)}/${carrierId}/siteAssignment`;

const apiSetSiteAssignmentPath = (businessId?: string, carrierId?: string): string =>
  `${apiPath(businessId)}/${carrierId}/assignToSites`;

const alphabeticallyByName = (a: CarrierDto, b: CarrierDto) => a.name.localeCompare(b.name);

const CarrierPage = () => {
  const user = useUser();
  const displayMessage = useDisplayMessage();
  const [assignSitesForCarrierId, setAssignSitesForCarrierId] = useState<string | undefined>();
  const [filterText, setFilterText] = useState('');
  const [popupState, setPopupState] = useState<PopupState>({ state: States.None });
  const [carrierToRemove, setCarrierToRemove] = useState<CarrierDto | undefined>(undefined);

  const getCarrier = useGetCarriers({
    onSuccess: data => {
      if (assignSitesForCarrierId && data) {
        const carrierToAssign = data.find(r => r.id === assignSitesForCarrierId);
        
        carrierToAssign
          ? assignSitesClick(carrierToAssign)
          : displayMessage.fail(
            "Could not assign sites because the carrier was not found")

        setAssignSitesForCarrierId(undefined);
      }
    }
  });

  const containsFilterText = (carrier: CarrierDto) => {
    const satisfiesFilter = textSatisfiesFilter(filterText);
    return satisfiesFilter(carrier.name) || satisfiesFilter(carrier.number);
  };

  const filterTextChanged = (text: string) => setFilterText(text);

  const removeSuccess = () => {
    displayMessage.success(`Carrier ${carrierToRemove?.name} removed`);
    return getCarrier.query();
  };

  const removeRequest = useRemoveCarrier(carrierToRemove?.id ?? '', {
    onSuccess: removeSuccess,
    onError: (err) => displayMessage.fail(err.message),
    onComplete: () => setCarrierToRemove(undefined),
  });

  const createClicked = () => {
    displayMessage.clear();
    setPopupState({ state: States.AddEditCarrier, carrier: null });
  }

  const editClicked = (carrier: CarrierDto) => {
    displayMessage.clear();
    setPopupState({ state: States.AddEditCarrier, carrier: carrier });
  }

  const carrierUpserted = (carrierId: string, carrierName: string, assignSites: boolean) => {
    if (popupState.state !== States.AddEditCarrier) return;

    displayMessage.success(`Carrier ${carrierName} ${popupState.carrier ? 'updated' : 'created'}`);
    if (assignSites) setAssignSitesForCarrierId(carrierId);

    setPopupState({ state: States.None });
    getCarrier.query();
  }

  const editDismissed = () => setPopupState({ state: States.None });

  const assignSitesClick = (carrier: CarrierDto) => {
    displayMessage.clear();
    setPopupState({ state: States.AssignSites, carrier });
  }

  const assignSitesSuccess = () => {
    if (popupState.state === States.AssignSites) {
      displayMessage.success(`Sites successfully updated for ${popupState.carrier?.name}`);
    }

    assignSitesClosed();
  }

  const assignSitesClosed = () => setPopupState({ state: States.None });

  if (getCarrier.isError) return <h3>{getCarrier.error?.message}</h3>
  if (getCarrier.isLoading || removeRequest.isLoading) return <CenteredSpinner />

  return <Container>
    {popupState.state === States.AddEditCarrier &&
      <CreateEditCarrier
        initialCarrier={popupState.carrier}
        onUpsert={carrierUpserted}
        onDismiss={editDismissed} />}
    {popupState.state === States.AssignSites &&
      <SiteAssignmentForm
        title={`Select sites for ${popupState.carrier.name}`}
        getAssignedSitesPath={apiGetSiteAssignmentPath(user.selectedBusiness?.id, popupState.carrier?.id)}
        assignSitePath={apiSetSiteAssignmentPath(user.selectedBusiness?.id, popupState.carrier?.id)}
        onHide={assignSitesClosed}
        onSuccess={assignSitesSuccess} />
    }
    <Confirm
      visible={!!carrierToRemove}
      title='Remove carrier'
      body={`Are you sure that you want to remove carrier ${carrierToRemove?.name}?`}
      onDismiss={() => setCarrierToRemove(undefined)}
      onConfirm={() => removeRequest.request()} />
    <Row className='justify-content-between mb-2'>
      <h2 className='col-auto ka-blue'>Carriers</h2>
      <BoldButton className='col-auto btn-ghost-primary' onClick={createClicked}>Create Carrier</BoldButton>
    </Row>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <form>
      <div className='row gx-2 gx-md-3 mb-7'>
        <div className='col-md-4 mb-2 mb-md-0'>
          <FilterInput placeholder='Filter carriers by keyword' onFilterTextChange={filterTextChanged} />
        </div>
      </div>
    </form>
    <Table bordered style={{tableLayout: "fixed"}}>
      <thead>
        <tr>
          <th className="col-3">Name</th>
          <th className="col-1">Number</th>
          <th className="col-4">Notes</th>
          <th className="col-1"></th>
        </tr>
      </thead>
      <tbody>
        {getCarrier.data?.filter(containsFilterText).sort(alphabeticallyByName).map(carrier =>
          <tr key={carrier.id}>
            <td>{carrier.name}</td>
            <td>{carrier.number}</td>
            <td>
              <div className="crop-text-3">{carrier.notes}</div>
            </td>
            <td align="right">
              <div className='dropdown'>
                <Button id='actionsMenuButton'
                  className='dropdown-toggle btn-ghost-secondary'
                  variant='link'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'><strong>Actions</strong></Button>
                <div className='dropdown-menu' aria-labelledby='actionsMenuButton'>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => editClicked(carrier)}>Edit</BoldButton>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => assignSitesClick(carrier)}>Assign sites</BoldButton>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => setCarrierToRemove(carrier)}>Remove</BoldButton>
                </div>
              </div>
            </td>
          </tr>)}
      </tbody>
    </Table>
  </Container>
};

export { CarrierPage };
