import React from 'react';
import KaModal from '../Common/KaModal';
import { RowUnitsSelect } from '../Units/UnitSelect'
import { Profile } from '../UserAccess/UserContext';
import { OrderBlendItemUpsertDto } from '../Common/dtos/OrderUpsertDto';
import { useForm } from 'react-hook-form';
import { RowFormText } from '../Common/Forms/FormText';
import { guidIsNullOrEmpty } from '../Common/ValidationRules';
import BoldButton from '../Common/BoldButton';
import { RowFormSelectSearch, SelectOption } from '../Common/Forms/SelectSearch';

export interface IProps {
  profile: Profile,
  mode: Mode,
  options: ItemOption[]
  onSubmit: (updatedItem: OrderBlendItemUpsertDto) => void,
  onHide: () => void
}

export interface ItemOption {
    id: string;
    name: string;
    type: ItemType;
}

export enum ItemType {
    Product,
    Recipe
}

export interface OrderBlendItem {
    ref?: string;
    id?: string;
    type?: ItemType;
    amount: number;
    unit: string;
}

export type Mode =
  { create: true } |
  { create: false, originalItem: OrderBlendItem }

export const CreateEditOrderBlendItem = (props: IProps) => {
  const { ref, ...item } = props.mode.create
    ? { ref: '', amount: 0, id: undefined, unit: 'Pound' }
    : props.mode.originalItem;
  const hasRef = !guidIsNullOrEmpty(ref);

  const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm<OrderBlendItem>({
    defaultValues: { ...item }
  });

  const formProps = (name: string) => ({
    labelSpace: 4,
    inputSpace: 7,
    name,
    setValue,
    register,
    errors,
  })

  const options = props.options
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(option => ({ value: option.id, name: option.name }));

  let prdRecOptions: SelectOption[] = options;

  return (<KaModal
    onHide={props.onHide}
    show={true}
    title={`${props.mode.create ? 'Add' : 'Update'} an item`}
    body={(<>
      {
        hasRef &&
        <h6>Cannot change the product/recipe or unit on this item because it has already been saved to the order.</h6>
      }
      <RowFormSelectSearch
        id="id"
        {...formProps("id")}
        disabled={hasRef}
        displayName="Product/Recipe"
        selectOptions={prdRecOptions}
        selectedValue={getValues("id")}
        placeholder="Select product or recipe"
        options={{
          required: 'A product or recipe must be selected',
        }}
      />
      <RowFormText {...formProps("amount")}
        options={{
          valueAsNumber: true,
          validate: {
            number: v => !isNaN(Number(v)) || 'Must be a number',
            greaterThanZero: v => v > 0 || 'Must be a non-zero amount'
          }
        }}
      />
      <RowUnitsSelect
        id="unit"
        {...formProps("unit")}
        selectedValue={getValues("unit")}
        disabled={hasRef}
      />
    </>)}
    footer={(
      <BoldButton className="btn-ghost-primary" onClick={handleSubmit(itemFromForm => {
        if (hasRef) {
          itemFromForm.ref = ref;
          itemFromForm.id = item.id;
          itemFromForm.unit = item.unit;
        }

        itemFromForm.type = props.options.find(n => n.id === itemFromForm.id)!.type;

        props.onSubmit(itemFromForm);
      })}>
        {props.mode.create ? 'Add' : 'Update'}
      </BoldButton>)
    }
  />);
}
