import React from 'react';
import { Col } from 'react-bootstrap';
import KaAlert from '../Common/KaAlert'
import SpinnerButton from '../Common/SpinnerButton';
import { KaEmployeeProfile } from '../UserAccess/UserContext';
import BusinessCreateDto from '../Common/dtos/BusinessCreateDto';
import { isValidEmail } from '../Common/ValidationRules';
import { Path, useForm } from 'react-hook-form';
import useKaEmployeeRequest, { UserRequestSpec } from '../Common/useKaEmployeeRequest';
import { HttpMethod } from '../Common/useFetch';
import useDisplayMessage from '../Common/useDisplayMessage';
import { RowFormText } from '../Common/Forms/FormText';
import { Label } from 'reactstrap';

interface IProps {
  kaUser: KaEmployeeProfile;
  businessCreated: () => void;
}

const postSpec: UserRequestSpec = {
  path: 'employeeApi/businesses',
  method: HttpMethod.POST,
};

export const CreateBusiness = (props: IProps) => {
  const newBusiness: BusinessCreateDto = {
    name: '',
    serviceContractNumber: '',
    emailAddress: '',
    sandbox: false,
  }

  const displayMessage = useDisplayMessage();

  const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm<BusinessCreateDto>({
    defaultValues: { ...newBusiness }
  });

  const handleCreate = (business: BusinessCreateDto) => {
    useCreate.request(business);
  }

  const useCreate = useKaEmployeeRequest<BusinessCreateDto>(postSpec, {
    onSuccess: () => {
      displayMessage.success(`Business "${getValues()?.name}" created`)
      props.businessCreated();
    },
    onError: (e) => displayMessage.fail(e.message),
  });

  const hookProps = (name: Path<BusinessCreateDto>) => ({
    labelSpace: 2,
    inputSpace: 7,
    name: name,
    setValue,
    register,
    errors,
  })

  return (<div>
    <h2 className="ka-blue">Create a new business</h2>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <RowFormText displayName="Business Name"
      {...hookProps("name")}
      options={{
        required: 'Business Name is required',
        validate: {
          length: v => v.length < 101 || 'Business Name cannot be longer than 100 characters'
        }
      }}
    />
    <RowFormText displayName='Service Contract Number'
      {...hookProps('serviceContractNumber')}
      options={{
        required: 'Service Contract Number is required',
        validate: {
          length: v => v.length < 51 || 'Service Contract Number cannot be longer than 50 characters'
        }
      }}
    />
    <RowFormText displayName="Initial Admin's Email"
      placeholder="initial admin's email (email@example.com)"
      {...hookProps('emailAddress')}
      options={{
        required: "Initial Admin's email address is required",
        validate: {
          isValidEmail: e => isValidEmail(e) || "Initial Admin's email address is invalid"
        }
      }}
    />
    <div className="row">
      <Label className="col col-form-label" xs={2} htmlFor='sandbox'>
        Sandbox Business:
      </Label>
      <div className="col form-check form-switch m-2">
        <input
          type="checkbox"
          className="form-check-input"
          {...register('sandbox')}
        />
      </div>
    </div>
    <Col xs={{ offset: 2 }}>
      <SpinnerButton
        className="btn-ghost-primary"
        spinning={useCreate.isLoading}
        onClick={handleSubmit(handleCreate)}>
        Create Business
      </SpinnerButton>
    </Col>
  </div>);
}