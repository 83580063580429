import React from "react";
import { Path, useForm } from "react-hook-form";
import { RowFormText } from "../Common/Forms/FormText";
import KaAlert from "../Common/KaAlert";
import KaModal from "../Common/KaModal";
import SpinnerButton from "../Common/SpinnerButton";
import useDisplayMessage from "../Common/useDisplayMessage";
import { BranchDto } from "../../requests/useGetBranches";
import useUpsertBranch, { BranchUpsertDto } from "../../requests/useUpsertBranch";

interface CreateEditBranchProps {
  initialBranch: BranchDto | null;
  onDismiss: () => void;
  onUpsert: (branchName: string) => void;
}

export const CreateEditBranch = (props: CreateEditBranchProps) => {
  const displayMessage = useDisplayMessage();
  const { id, ...initbranch } = { ...props.initialBranch };
  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm<BranchUpsertDto>({
    defaultValues: { ...initbranch }
  });

  const dismissed = () => {
    props.onDismiss();
  };

  const upsert = useUpsertBranch(props.initialBranch?.id, {
    onSuccess: () => props.onUpsert(getValues().name),
    onError: (e) => displayMessage.fail(e.message),
  });

  const formProps = (name: Path<BranchUpsertDto>) => ({
    labelSpace: 4,
    inputSpace: 7,
    name: name,
    setValue,
    register,
    errors,
  });

  return <KaModal
    title={`${props.initialBranch ? 'Update' : 'Create'} a branch`} onHide={dismissed} show={true}
    body={(<>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <RowFormText {...formProps('name')}
        options={{
          required: 'Name is required',
          validate: { length: v => v.length < 51 || 'Name cannot be longer than 50 characters' }
        }} />
      <RowFormText {...formProps('number')}
        displayName="Branch Number"
        options={{
          validate: { length: v => v.length < 51 || 'Number cannot be longer than 50 characters' }
        }} />
      <RowFormText {...formProps("addressStreet")}
        displayName="Street"
        options={{
          validate: {
            length: v => v.length < 51 || 'Address Street cannot be longer than 50 characters'
          }
        }} />
      <RowFormText {...formProps("addressCity")}
        displayName="City"
        options={{
          validate: {
            length: v => v.length < 51 || 'Address City cannot be longer than 50 characters'
          }
        }} />
      <RowFormText {...formProps("addressState")}
        displayName="State"
        options={{
          validate: {
            length: v => v.length < 51 || 'Address State cannot be longer than 50 characters'
          }
        }} />
      <RowFormText {...formProps("addressPostalCode")}
        displayName="Postal Code"
        options={{
          validate: {
            length: v => v.length < 16 || 'Address Postal Code cannot be longer than 15 characters'
          }
        }} />
      <RowFormText {...formProps("addressCountry")}
        displayName="Country"
        options={{
          validate: {
            length: v => v.length < 101 || 'Address Country cannot be longer than 100 characters'
          }
        }} />
      <RowFormText {...formProps('phone')}
        options={{
          validate: { length: v => v.length < 51 || 'Phone cannot be longer than 50 characters' }
        }} />
      <RowFormText {...formProps("email")}
        options={{
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Email Address is invalid'
          }
        }} />
    </>)}
    footer={(
      <div className="btn-group">
        <SpinnerButton
          className="w-25 m-1 rounded btn-ghost-primary"
          spinning={upsert.isLoading && !upsert.isError}
          onClick={handleSubmit(upsert.request)}>
          {`${props.initialBranch ? 'Update' : 'Create'}`}
        </SpinnerButton>
      </div>)
    }
  />
};
