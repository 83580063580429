import React, { useState } from "react"
import CountUp from "react-countup"
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useInterval from "../Common/useInterval";
import useGetProductLoaded, { ProductLoadedResponse } from "../Requests/useGetProductLoaded";
import { WidgetDisplay } from "./WidgetDisplay";
import { ProductLoadedSettings, WidgetProps } from "./DashboardContext";
import { isMultiValue, KaSelect } from "../Common/KaSelect";
import KaModal from "../Common/KaModal";
import { CenteredSpinner } from "../Common/CenteredSpinner";
import ProductDto from "../Common/dtos/ProductDto";
import useUserQuery from "../Common/useUserQuery";
import { useUser } from "../UserAccess/useUser";
import useUpdateProductLoadedWidgetSettings from "../../requests/useUpdateProductLoadedWidgetSettings";
import KaAlert from "../Common/KaAlert";
import useDisplayMessage from "../Common/useDisplayMessage";
import useGetSitesNonAdmin from "../Requests/useGetSitesNonAdmin";

export const units = ["Pound", "Gallon", "Ton"]

const abbreviateUnit = (unit: string) => {
  switch (unit) {
    case "Pound": return "lbs";
    case "Gallon": return "gal";
    default: return unit + "s";
  }
}

type ProductLoadedWidgetProps = WidgetProps & ProductLoadedSettings;

export const ProductLoaded = (props: ProductLoadedWidgetProps) => {
  const user = useUser();
  const business = user.selectedBusiness;
  const [unit, setUnit] = useState<string>(props.selectedUnit ? props.selectedUnit : units[0]);
  const [site, setSite] = useState<string>(props.selectedSiteId ? props.selectedSiteId : 'AllSites');
  const [name, setName] = useState<string>(props.name === "" ? "Product Loaded" : props.name);
  const [product, setProduct] = useState<string | undefined>(props.selectedProductId);
  const [data, setData] = useState<ProductLoadedResponse | undefined>(undefined);

  const getSites = useGetSitesNonAdmin();
  const getProducts = useUserQuery<ProductDto[]>({
    path: `/api/businesses/${business?.id}/products`
  }, {
    onSuccess: (resp) => {
      if (product === undefined && resp.length > 0) {
        setProduct(resp[0].id)
      }
    }
  });

  const getAggregateProduct = useGetProductLoaded({
    unit: unit ?? units[0],
    timeZoneOffset: new Date().getTimezoneOffset(),
    product: product,
    siteId: site === 'AllSites' ? undefined : site,
  }, {
    autoQuery: product !== undefined,
    onSuccess: (resp) => {
      setData(resp);
    }
  });

  useInterval(() => product ? getAggregateProduct.query() : undefined, 30000, false);

  const CounterWithTime = ({ amount, displayText, dateText, error }:
    {
      amount: number | undefined,
      displayText: string,
      dateText: string,
      error: string | undefined
    }) => {
    return <div className="w-100">
      <div className="row px-0 d-flex justify-content-center">
        <h4 className="ka-blue mb-0 w-auto">
          {amount === undefined
            ? <OverlayTrigger
              placement={'right'}
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip>
                  {getAggregateProduct.error?.message ?? error ?? "Unexpected error occurred"}
                </Tooltip>
              }>
              <span className="text-muted">N/A</span>
            </OverlayTrigger>
            : <CountUp
              end={amount!}
              suffix={" " + abbreviateUnit(unit)}
              duration={0.5}
              redraw={false}
              preserveValue={true} />}
        </h4>
      </div>
      <div className="row px-0 d-flex justify-content-center">
        <p className="mb-0 w-auto" style={{ fontSize: 13 }}>{displayText}</p>
      </div>
      {dateText &&
        <div className="row px-0 d-flex justify-content-center">
          <p className="mb-0 w-auto" style={{ fontSize: 11 }}>{dateText}</p>
        </div>
      }
    </div>
  }

  const Settings = (show: boolean, onClose: () => void) => {
    const displayMessage = useDisplayMessage();
    const [modifiedName, setModifiedName] = useState(name);
    const [selectedUnit, setSelectedUnit] = useState<string>(unit);
    const [selectedSite, setSelectedSite] = useState<string>(site ?? 'AllSites');
    const [selectedProduct, setSelectedProduct] = useState<string | undefined>(product);

    const updateSettings = useUpdateProductLoadedWidgetSettings(props.id.toString(), {
      onSuccess: () => {
        setName(modifiedName);
        setUnit(selectedUnit);
        setSite(selectedSite);
        setProduct(selectedProduct);
        displayMessage.clear();
        onClose();
      },
      onError: (err) => displayMessage.fail(err.message)
    });

    if (getSites.isLoading || getProducts.isLoading || getAggregateProduct.isLoading) return <KaModal
      onHide={onClose}
      show={show}
      title={'Product Loaded Settings'}
      body={(<div className="mt-3">
        <CenteredSpinner />
      </div>)}
    />
    if (getSites.isError || getProducts.isError || getAggregateProduct.isError) return <KaModal
      onHide={onClose}
      show={show}
      title={'Product Loaded Settings'}
      body={(<div className="mt-3">
        <h3>{getSites.error?.message ?? getProducts.error?.message ?? getAggregateProduct.error?.message}</h3>
      </div>)}
    />

    const unitOption = (unit: string) => ({ value: unit, label: unit + "s" });

    const sortedSites = getSites.data?.sort((a, b) => a.name.localeCompare(b.name));
    const sortedSiteOptions = sortedSites?.map(s => ({ value: s.id, label: s.name })) ?? [];
    const AggregateSite = { value: 'AllSites', label: "All Sites" };
    const siteOptions = [AggregateSite].concat(sortedSiteOptions);
    const sortedProducts = getProducts.data?.sort((a, b) => a.name.localeCompare(b.name));
    const productOptions = sortedProducts?.map(p => ({ value: p.id, label: p.name })) ?? [];

    const saveSettings = () => 
      updateSettings.request({
        name: modifiedName,
        selectedProductId: selectedProduct,
        selectedSiteId: selectedSite === 'AllSites' ? undefined : selectedSite,
        selectedUnit: selectedUnit
      });

    return (<KaModal
      onHide={onClose}
      show={show}
      title={'Product Loaded Settings'}
      body={(<>
        <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
        <h5>Widget Name:</h5>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Widget Name"
          value={modifiedName}
          onChange={(e) => setModifiedName(e.target.value)}/>
        <div className="row mt-3">
          <div className="col">
            <h5>Site:</h5>
            <KaSelect
              placeholder="Select Site"
              options={siteOptions}
              onChange={(valueObject) => {
                if (!isMultiValue(valueObject)) { setSelectedSite(valueObject?.value!); }
              }}
              defaultValue={siteOptions.find((option) => option.value === site!)}
            />
          </div>
          <div className="col">
            <h5>Unit:</h5>
            <KaSelect
              placeholder="Select Unit"
              defaultValue={unitOption(unit)}
              onChange={(valueObject) => {
                if (!isMultiValue(valueObject)) { setSelectedUnit(valueObject?.value!) }
              }}
              options={units.map(u => unitOption(u))} />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <div className="w-75">
            <h5>Product:</h5>
            <KaSelect
              placeholder="Select Product"
              options={productOptions}
              defaultValue={product ? productOptions.find(o => o.value === product) : undefined}
              onChange={(valueObject) => {
                if (!isMultiValue(valueObject)) { setSelectedProduct(valueObject?.value!); }
              }} />
          </div>
        </div>
      </>)}
      footer={(
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => saveSettings()}>
          Save
        </button>)}
    />)
  }

  var errorMessage = "";
  if (getSites.isError) { errorMessage = getSites.error!.message; }
  else if (getProducts.isError) { errorMessage = getProducts.error!.message; }
  else if (getAggregateProduct.isError) { errorMessage = getAggregateProduct.error!.message; }
  else if (product === undefined) { errorMessage = "No products to show" };

  return <WidgetDisplay
    widgetId={props.id}
    title={name}
    path="/productLoaded"
    isLoading={getAggregateProduct.isLoading || getSites.isLoading || getProducts.isLoading}
    settings={Settings}>
    {data ? <>
      <div className="row mb-2 px-3 mt-2">
        <div className="col ps-0">
          <CounterWithTime
            amount={data?.loadedAmountHour}
            displayText={"Within the hour"}
            dateText={data?.startOfHour ? "Starting " + data.startOfHour : ""}
            error={data?.error} />
        </div>
        <div className="col pe-0">
          <CounterWithTime
            amount={data?.loadedAmountDay}
            displayText={"Today"}
            dateText={data?.startOfDay ? "Starting " + data.startOfDay : ""}
            error={data?.error} />
        </div>
      </div>
      <div className="row mb-2 px-3">
        <div className="col ps-0">
          <CounterWithTime
            amount={data?.loadedAmountWeek}
            displayText={"This week"}
            dateText={data?.startOfWeek ? "Starting on " + data.startOfWeek : ""}
            error={data?.error} />
        </div>
        <div className="col pe-0 mb-1">
          <CounterWithTime
            amount={data?.loadedAmountMonth}
            displayText={"This month"}
            dateText={data?.startOfMonth ? "Starting on " + data.startOfMonth : ""}
            error={data?.error} />
        </div>
        <div className="row text-center" style={{ height: 50 }}>
          <div className="text-wrap my-auto">{data?.productName ? `Showing ${data?.productName}` : 'No products to show' }</div>
        </div>
      </div>
      </>
      : <div className="col h-100 d-flex align-items-center" style={{ color: 'rgba(237, 71, 52)' }}>
        <div className="w-100 mx-4">
          <div className="row text-center">
            <i className="bi bi-x-circle-fill fs-1"></i>
          </div>
          <div className="row text-center fs-5">
            <p>{errorMessage}</p>
          </div>
        </div>
      </div>
    }
  </WidgetDisplay>
};
