import React, { useState } from 'react';
import KaAlert from '../Common/KaAlert';
import { useUser } from '../UserAccess/useUser';
import { Row, Table, Container } from 'reactstrap';
import { CenteredSpinner } from '../Common/CenteredSpinner';
import useUserQuery from '../Common/useUserQuery';
import useDisplayMessage from '../Common/useDisplayMessage';
import { CreateEditCustomer } from './CreateEditCustomer';
import { DestinationPage } from './Destinations/DestinationPage';
import formatPostalAddress from '../Common/PostalAddressDisplay';
import CustomerDto from '../Common/dtos/CustomerDto';
import CustomerUpsertDto from '../Common/dtos/CustomerUpsertDto';
import FilterInput, { textSatisfiesFilter } from '../FilterInput';
import useUserRequest, { UserRequestSpec } from '../Common/useUserRequest';
import { HttpMethod } from '../Common/useFetch';
import Confirm from '../Common/Confirm';
import { customerDisplayNameFromDto } from '../../Utilities';
import { Button } from 'react-bootstrap';
import BoldButton from '../Common/BoldButton';

const getSpec = (businessId: string) => ({ path: `/api/businesses/${businessId}/customers` });

const removeRequestSpec = (businessId?: string, customerId?: string): UserRequestSpec => ({
  path: `/api/businesses/${businessId}/customers/${customerId}`,
  method: HttpMethod.DELETE,
});

const CustomerPage = () => {
  const user = useUser();
  const getCustomers = useUserQuery<CustomerDto[]>(getSpec(user.selectedBusiness?.id ?? ""));
  const [filterText, setFilterText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const displayMessage = useDisplayMessage();
  const [editingCustomer, setEditingCustomer] = useState<CustomerDto | null>(null);
  const [customerToRemove, setCustomerRemove] = useState<CustomerDto | undefined>(undefined);
  const [showDestinations, setShowDestinations] = useState(false);

  const byNameAlphabetically = (a: CustomerDto, b: CustomerDto) => a.name.localeCompare(b.name);

  const filterTextChanged = (text: string) => setFilterText(text);

  const containsFilterText = (customer: CustomerDto) => {
    const satisfiesFilter = textSatisfiesFilter(filterText);
    return satisfiesFilter(customer.name) || satisfiesFilter(customer.accountNumber);
  };

  const createEditSuccess = (c: CustomerUpsertDto) => {
    displayMessage.success(`Customer ${c.name} ${editingCustomer ? 'updated' : 'created'}`);
    getCustomers.query();
    setShowModal(false);
  }

  const removeSuccess = () => {
    displayMessage.success(`Customer ${customerToRemove?.name} removed`);
    return getCustomers.query();
  };

  const removeRequest = useUserRequest<void, void>(
    removeRequestSpec(user.selectedBusiness?.id, customerToRemove?.id), {
    onSuccess: removeSuccess,
    onError: (err) => displayMessage.fail(err.message),
    onComplete: () => setCustomerRemove(undefined),
  });

  const createClick = () => {
    setEditingCustomer(null);
    setShowModal(true);
  }

  const editClick = (c: CustomerDto) => {
    setEditingCustomer(c);
    setShowModal(true);
  }

  const showDestinationsClick = (c: CustomerDto) => {
    setEditingCustomer(c);
    setShowDestinations(true);
  }

  if (getCustomers.isLoading || removeRequest.isLoading) return <CenteredSpinner />
  if (getCustomers.isError) return <h3>{getCustomers.error?.message}</h3>

  const formatCustomerPostalAddress = (customer: CustomerDto) => {
    return formatPostalAddress(
      customer.billingAddressStreet,
      customer.billingAddressCity,
      customer.billingAddressState,
      customer.billingAddressPostalCode,
      customer.billingAddressCountry);
  };

  return <>
    <Confirm
      visible={!!customerToRemove}
      title='Remove customer'
      body={`When a customer is removed it cannot be restored and all destinations associated with the customer will be removed.  
               Are you sure you want to remove ${customerToRemove?.name}?`}
      onDismiss={() => setCustomerRemove(undefined)}
      onConfirm={() => removeRequest.request()} />
    {editingCustomer != null && showDestinations
      ? <DestinationPage user={user} customer={editingCustomer} backClicked={() => setShowDestinations(false)} />
      : <Container>
        <Row className="justify-content-between mb-2">
          <h2 className="col-auto ka-blue">Customers</h2>
          <BoldButton className="col-auto btn-ghost-primary" onClick={createClick}>Create Customer</BoldButton>
        </Row>
        <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
        <form>
          <div className="row gx-2 gx-md-3 mb-7">
            <div className="col-md-4 mb-2 mb-md-0">
              <FilterInput placeholder='Filter customers by keyword' onFilterTextChange={filterTextChanged} />
            </div>
          </div>
        </form>
        <Table bordered>
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th className="col-2"></th>
            </tr>
          </thead>
          <tbody>
            {getCustomers.data?.filter(containsFilterText).sort(byNameAlphabetically).map(customer =>
              <tr key={customer.id}>
                <td>{customerDisplayNameFromDto(customer)}</td>
                <td>{formatCustomerPostalAddress(customer)}</td>
                <td className="d-flex">
                  <div className="dropdown">
                    <Button className='dropdown-toggle btn-ghost-secondary' variant='link' id='actionsMenuButton' data-bs-toggle='dropdown' aria-expanded='false'>
                      <strong>Actions</strong>
                    </Button>
                    <div className='dropdown-menu' aria-labelledby='actionsMenuButton'>
                      <BoldButton className='dropdown-item btn-ghost-secondary' onClick={() => editClick(customer)}>Edit</BoldButton>
                      <BoldButton className='dropdown-item btn-ghost-secondary' onClick={() => setCustomerRemove(customer)}>Remove</BoldButton>
                    </div>
                  </div>
                  <BoldButton className="mx-1 btn-ghost-secondary" onClick={() => showDestinationsClick(customer)}>Destinations</BoldButton>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {showModal &&
          <CreateEditCustomer
            onSuccess={createEditSuccess}
            user={user}
            initialCustomer={editingCustomer}
            onHide={() => setShowModal(false)} />
        }
      </Container>
    }
  </>
}

export { CustomerPage };