import React, { useState } from 'react';
import useGetSitesStatus, { SiteStatusDto } from '../Requests/useGetSitesStatus';
import { SiteStatusToIndicator, SiteStatusToDisplay, SortSitesStatuses } from './SitesStatusWidget';
import useInterval from '../Common/useInterval';
import { NavLink } from 'react-router-dom';
import { CenteredSpinner } from '../Common/CenteredSpinner';

const SitesStatusPage = () => {
  const refreshTimeMs = 3000;
  const [data, setData] = useState<SiteStatusDto[] | null>(null);

  const getSitesStatus = useGetSitesStatus({
    onSuccess: (resp) => setData(SortSitesStatuses(resp.sitesStatus))
  });

  useInterval(() => getSitesStatus.query(), refreshTimeMs, false);

  if (data === null && getSitesStatus.isLoading) return <CenteredSpinner />
  if (getSitesStatus.isError) return <h3>{getSitesStatus.error?.message}</h3>
  if (data?.length === 0 ) return <h3>No Site Status Available</h3>

  return <>
  <div className="ms-n2 pb-3">
    <NavLink className="card-link my-auto text-nowrap" to="/" style={{ fontSize: 20 }}>
      <i className="bi-chevron-left" />
      Site Connection Status
    </NavLink>
  </div>  
  <div className="w-100 vh-50 d-flex justify-content-between">
    <table className="table table-borderless">
      <thead>
        <tr>
          <th>Site</th>
          <th>Status</th>
          <th>{data?.some(s => s.error !== undefined) ? "Message" : ""}</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((siteStatus) => <tr key={siteStatus.siteName} className="my-1">
          <td>
            <i className={`mx-2
              ${SiteStatusToIndicator(siteStatus)}`}>
            </i>
            {siteStatus.siteName}
          </td>
          <td>{SiteStatusToDisplay(siteStatus)}</td>
          <td>{siteStatus.error}</td>
        </tr>)} 
      </tbody>
    </table>
  </div>
</>}

export { SitesStatusPage };