import React, { useState } from "react";
import { Container, Row, Table } from "reactstrap";
import { CenteredSpinner } from "../Common/CenteredSpinner";
import KaAlert from "../Common/KaAlert";
import useDisplayMessage from "../Common/useDisplayMessage";
import FilterInput, { textSatisfiesFilter } from "../FilterInput";
import { Button } from "react-bootstrap";
import useGetTransports, { TransportDto } from "../../requests/useGetTransports";
import BoldButton from "../Common/BoldButton";
import { CreateEditTransport } from "./CreateEditTransport";
import useRemoveTransport from "../../requests/useRemoveTransport";
import Confirm from "../Common/Confirm";
import { SiteAssignmentForm } from "../Common/SiteAssignment/SiteAssignmentForm";
import { useUser } from "../UserAccess/useUser";

type PopupState =
  { state: "None"; } |
  {
    state: "AddEditTransport";
    transport: TransportDto | null;
  } |
  {
    state: "AssignSites";
    transport: TransportDto;
  };

const apiPath = (businessId?: string): string => `/api/businesses/${businessId}/transports`;

const apiGetSiteAssignmentPath = (businessId?: string, transportId?: string): string =>
  `${apiPath(businessId)}/${transportId}/siteAssignment`;

const apiSetSiteAssignmentPath = (businessId?: string, transportId?: string): string =>
  `${apiPath(businessId)}/${transportId}/assignToSites`;

const alphabeticallyByName = (a: TransportDto, b: TransportDto) => a.name.localeCompare(b.name);

const TransportPage = () => {
  const user = useUser();
  const displayMessage = useDisplayMessage();
  const [popupState, setPopupState] = useState<PopupState>({ state: "None" });
  const [assignSitesForTransportId, setAssignSitesForTransportId] = useState<string | undefined>();
  const [filterText, setFilterText] = useState('');
  const [transportToRemove, setTransportToRemove] = useState<TransportDto | undefined>(undefined);

  const getTransports = useGetTransports({
    onSuccess: data => {
      if (assignSitesForTransportId && data) {
        const transportToAssign = data.find(r => r.id === assignSitesForTransportId);

        transportToAssign
          ? assignSitesClick(transportToAssign)
          : displayMessage.fail(
            "Could not assign sites because the transport was not found")

        setAssignSitesForTransportId(undefined);
      }
    }
  });

  const createClicked = () => {
    displayMessage.clear();
    setPopupState({ state: "AddEditTransport", transport: null });
  }

  const editClicked = (transport: TransportDto) => {
    displayMessage.clear();
    setPopupState({ state: "AddEditTransport", transport });
  }

  const transportUpserted = (transportId: string, transportName: string, assignSites: boolean) => {
    if (popupState.state !== 'AddEditTransport') return;

    displayMessage.success(`Transport ${transportName} ${popupState.transport ? 'updated' : 'created'}`);
    if (assignSites) setAssignSitesForTransportId(transportId);

    setPopupState({ state: 'None' });
    getTransports.query();
  }

  const editDismissed = () => setPopupState({ state: 'None' });

  const assignSitesClick = (transport: TransportDto) => {
    displayMessage.clear();
    setPopupState({ state: 'AssignSites', transport });
  }

  const assignSitesSuccess = () => {
    if (popupState.state === 'AssignSites') {
      displayMessage.success(`Sites successfully updated for ${popupState.transport?.name}`);
    }

    assignSitesClosed();
  }

  const assignSitesClosed = () => setPopupState({ state: 'None' });

  const containsFilterText = (transport: TransportDto) => {
    const satisfiesFilter = textSatisfiesFilter(filterText);
    return satisfiesFilter(transport.name) || satisfiesFilter(transport.number);
  };

  const filterTextChanged = (text: string) => setFilterText(text);

  const removeSuccess = () => {
    displayMessage.success(`Transport ${transportToRemove?.name} removed`);
    return getTransports.query();
  };

  const removeRequest = useRemoveTransport(transportToRemove?.id ?? '', {
    onSuccess: removeSuccess,
    onError: (err) => displayMessage.fail(err.message),
    onComplete: () => setTransportToRemove(undefined),
  });

  if (getTransports.isError) return <h3>{getTransports.error?.message}</h3>
  if (getTransports.isLoading) return <CenteredSpinner />

  return <Container>
    {popupState.state === 'AddEditTransport' && <CreateEditTransport
      initialTransport={popupState.transport}
      onUpsert={transportUpserted}
      onDismiss={editDismissed} />}
    {popupState.state === 'AssignSites' &&
      <SiteAssignmentForm
        title={`Select sites for ${popupState.transport.name}`}
        getAssignedSitesPath={apiGetSiteAssignmentPath(user.selectedBusiness?.id, popupState.transport?.id)}
        assignSitePath={apiSetSiteAssignmentPath(user.selectedBusiness?.id, popupState.transport?.id)}
        onHide={assignSitesClosed}
        onSuccess={assignSitesSuccess} />
    }
    <Confirm
      visible={!!transportToRemove}
      title='Remove transport'
      body={`Are you sure that you want to remove transport ${transportToRemove?.name}?`}
      onDismiss={() => setTransportToRemove(undefined)}
      onConfirm={() => removeRequest.request()} />
    <Row className='justify-content-between mb-2'>
      <h2 className='col-auto ka-blue'>Transports</h2>
      <BoldButton className='col-auto btn-ghost-primary' onClick={createClicked}>Create Transport</BoldButton>
    </Row>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <form>
      <div className='row gx-2 gx-md-3 mb-7'>
        <div className='col-md-4 mb-2 mb-md-0'>
          <FilterInput placeholder='Filter transports by keyword' onFilterTextChange={filterTextChanged} />
        </div>
      </div>
    </form>
    <Table bordered>
      <thead><tr><th>Name</th><th>Number</th><th></th></tr></thead>
      <tbody>
        {getTransports.data?.filter(containsFilterText).sort(alphabeticallyByName).map(transport =>
          <tr key={transport.id}>
            <td>{transport.name}</td>
            <td>{transport.number}</td>
            <td align="right">
              <div className='dropdown'>
                <Button
                  className='dropdown-toggle btn-ghost-secondary'
                  variant='link'
                  data-bs-toggle='dropdown'><strong>Actions</strong></Button>
                <div className='dropdown-menu'>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => editClicked(transport)}>Edit</BoldButton>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => assignSitesClick(transport)}>Assign sites</BoldButton>
                  <BoldButton
                    className='dropdown-item btn-ghost-secondary'
                    onClick={() => setTransportToRemove(transport)}>Remove</BoldButton>
                </div>
              </div>
            </td>
          </tr>)}
      </tbody>
    </Table>
  </Container>
};

export { TransportPage };
