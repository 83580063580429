import { useContext } from 'react';
import { UserContext, UserProfile, LoginType } from './UserContext';

export const useUser = (): UserProfile => {
  const { user } = useContext(UserContext);

  if (user?.userType === LoginType.User) {
    return user as UserProfile;
  }

  throw new Error("useUser hook can only be used inside of components contained inside an UserTemplate component");
}