import { useContext } from 'react';
import useQuery, { Query, QueryOptions } from './useQuery';
import { UserQuerySpec } from './useUserQuery';
import { LoginType, UserContext, UserStatus } from '../UserAccess/UserContext';

export default function useUserOrEmployeeQuery<Resp>(spec: UserQuerySpec, options?: QueryOptions<Resp>): Query<Resp> {
  const userStatus = useContext<UserStatus>(UserContext);

  let headers: (() => Promise<Headers>) | null = userStatus.user?.fetchHeaders ?? null;
  let path = spec.path

  if (userStatus.user?.userType === LoginType.KaEmployee) {
    path = path.replace("api", "employeeApi")
  }

  if (!headers) {
    throw new Error("useUserOrEmployeeQuery hook can only be used inside of " +
      "components that ensure a user or KaEmployee is signed in");
  }

  return useQuery<Resp>({
    path: path,
    headers,
  }, options);
}