import React from 'react';
import { FieldValues } from 'react-hook-form';
import { FormGroup, FormFeedback } from 'reactstrap';
import { toDisplayFromCamelCase } from '../../../Utilities';
import { FormCommonProps } from './FormCommon';
import { FormLabel } from './FormLabel';
import { FormRow, FormRowSize } from './FormRow';
import { useFormError } from './useFormError';

interface FormTextAreaProps<FormType extends FieldValues> extends FormCommonProps<FormType> {
  rowCount?: number,
  placeholder?: string,
  noPlaceholder?: boolean,
}

interface RowFormTextAreaProps<FormType extends FieldValues> extends FormTextAreaProps<FormType>, FormRowSize { }

export function FormTextArea(props: FormTextAreaProps<any>) {
  return <FormGroup>
    <FormLabel {...props} />
    <FormTextControl {...props} />
  </FormGroup>
}

export function RowHooksFormTextArea(props: RowFormTextAreaProps<any>) {
  return <FormRow
    {...props}
    control={<FormTextControl {...props} />}
  />
}

function FormTextControl(props: FormTextAreaProps<any>) {
  var { inError, errorMessage } = useFormError(props);
  var placeholder = props.placeholder ?? (props.displayName ?? toDisplayFromCamelCase(props.name)).toLowerCase();
  const controlProps = props.register(props.name, { ...props.options });

  const onBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    props.setValue(props.name, e.target.value?.trim());
    controlProps.onBlur(e);
  }

  return <>
    <textarea
      className={`form-control ${inError ? 'is-invalid' : ''}`}
      rows={props.rowCount ?? 2}
      placeholder={props.noPlaceholder ? '' : `Enter ${placeholder}`}
      ref={controlProps.ref}
      name={controlProps.name}
      onChange={controlProps.onChange}
      onBlur={onBlur}
      disabled={!(props.enabled ?? true)}
    />
    <FormFeedback>{errorMessage}</FormFeedback>
  </>
}
