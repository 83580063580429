import React from 'react';
import KaAlert from '../Common/KaAlert'
import SpinnerButton from '../Common/SpinnerButton';
import { UserProfile } from '../UserAccess/UserContext';
import useUserRequest, { UserRequestSpec } from '../Common/useUserRequest';
import { HttpMethod } from '../Common/useFetch';
import { useDisplayMessage } from '../Common/useDisplayMessage';
import KaModal from '../Common/KaModal';
import ProductDto from '../Common/dtos/ProductDto';
import ProductUpsertDto from '../Common/dtos/ProductUpsertDto';
import { Path, useForm } from 'react-hook-form';
import { RowFormText } from '../Common/Forms/FormText';
import { RowUnitsSelect } from '../Units/UnitSelect';
import { UnitType } from '../Common/dtos/UnitDto';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { FormRow } from '../Common/Forms/FormRow';

interface IProps {
  user: UserProfile,
  initialProduct: ProductDto | null,
  onHide: () => void,
  onSuccess: (product: ProductUpsertDto) => void,
  setProductToAssignToSites: (product: ProductUpsertDto | null) => void
}

const productsPath = (businessId?: string) => `/api/businesses/${businessId}/products`;

const postSpec = (businessId?: string): UserRequestSpec => ({
  path: productsPath(businessId),
  method: HttpMethod.POST,
});

const putSpec = (businessId?: string, productId?: string): UserRequestSpec => ({
  path: `${productsPath(businessId)}/${productId}`,
  method: HttpMethod.PUT,
});

const newProduct = (): ProductUpsertDto => ({
  name: '',
  epaNumber: '',
  ticketNotes: '',
  density: {
    value: 0,
    massUnit: 'Pound',
    volumeUnit: 'Gallon'
  },
  densityConstellationControlled: true,
});

export const CreateEditProduct = (props: IProps) => {
  const create = props.initialProduct === null;
  let { id, ...initialProduct } = { ...props.initialProduct }
  const product = create ? newProduct() : initialProduct;

  const businessId = props.user.selectedBusiness?.id;
  const requestSpec = create
    ? postSpec(businessId)
    : putSpec(businessId, id);

  const displayMessage = useDisplayMessage();

  const { register, handleSubmit, getValues, setValue, formState: { errors }, watch } = useForm<ProductUpsertDto>({
    defaultValues: {
      name: product.name,
      epaNumber: product.epaNumber,
      ticketNotes: product.ticketNotes,
      density: product.density,
      densityConstellationControlled: product.densityConstellationControlled,
    },
  });

  const handleCreateUpdate = (productUpsert: ProductUpsertDto) => {
    productUpsert.density = productUpsert.densityConstellationControlled ? productUpsert.density : { value: 0, massUnit: 'Pound', volumeUnit: 'Gallon' }
    useCreateEdit.request(productUpsert);
  }

  const handleCreateUpdateAndProceedToSiteAssignment = (productUpsert: ProductUpsertDto) => {
    productUpsert.density = productUpsert.densityConstellationControlled ? productUpsert.density : { value: 0, massUnit: 'Pound', volumeUnit: 'Gallon' }
    props.setProductToAssignToSites(productUpsert);
    useCreateEdit.request(productUpsert);
  }

  const useCreateEdit = useUserRequest<ProductUpsertDto>(requestSpec, {
    onSuccess: () => props.onSuccess(getValues()),
    onError: (e) => {
      displayMessage.fail(e.message)
      props.setProductToAssignToSites(null);
    }
  });

  const hookProps = (name: Path<ProductUpsertDto>) => ({
    labelSpace: 4,
    inputSpace: 7,
    name: name,
    setValue,
    register,
    errors,
  })

  const onClose = () => {
    props.onHide();
    displayMessage.clear();
  }

  const densityConstellationControlled = watch("densityConstellationControlled");

  return (<KaModal
    onHide={onClose}
    show={true}
    title={`${create ? 'Create' : 'Update'} a product`}
    body={(<>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <RowFormText displayName="Product Name"
        {...hookProps("name")}
        options={{
          required: 'Name is required',
          validate: {
            length: v => v.length < 51 || 'Name cannot be longer than 50 characters'
          }
        }}
      />
      <FormRow displayName="Density Source" {...hookProps("densityConstellationControlled")} 
        control={<Nav tabs className="d-flex justify-content-center nav-justified">
            <NavItem>
              <NavLink active={!densityConstellationControlled} onClick={() => setValue("densityConstellationControlled", false)}>
                TMX
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={densityConstellationControlled} onClick={() => setValue("densityConstellationControlled", true)}>
                Constellation
              </NavLink>
            </NavItem>
          </Nav>}
      />
      {densityConstellationControlled && <>
        <RowFormText displayName='Density Value'
          {...hookProps("density.value")}
          options={{
            valueAsNumber: true,
            validate: {
              number:  v => !isNaN(Number(v)) || 'Must be a number',
              greaterThanOrEqualToZero: v => v >= 0 || 'Cannot be negative'
            }
          }}
        />
        <RowUnitsSelect id='density.massUnit' displayName='Density Mass Unit' unitType={UnitType.Mass} selectedValue={getValues("density.massUnit")}
          {...hookProps('density.massUnit')} />
        <RowUnitsSelect id='density.volumeUnit' displayName='Density Volume Unit' unitType={UnitType.Volume} selectedValue={getValues("density.volumeUnit")}
          {...hookProps('density.volumeUnit')} />
      </>}
      <RowFormText displayName='EPA Number' placeholder='EPA number'
        {...hookProps("epaNumber")}
        options={{
          validate: {
            length: v => v.length < 51 || 'EPA number cannot be longer than 50 characters'
          }
        }}
      />
      <RowFormText displayName="Ticket Notes"
        {...hookProps("ticketNotes")}
      />
    </>)}
    footer={(<div className="btn-group">
      <SpinnerButton
        className="w-25 m-1 rounded btn-ghost-primary"
        spinning={useCreateEdit.isLoading}
        onClick={handleSubmit(handleCreateUpdate)}>
        {`${create ? 'Create' : 'Update'}`}
      </SpinnerButton>
      <SpinnerButton
        className="w-25 m-1 rounded btn-ghost-primary"
        spinning={useCreateEdit.isLoading}
        onClick={handleSubmit(handleCreateUpdateAndProceedToSiteAssignment)}>
        {`${create ? 'Create and Assign' : 'Update and Assign'}`}
      </SpinnerButton>
    </div>)
    }
  />);
}
