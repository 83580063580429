import UnitDto, { UnitType } from './dtos/UnitDto';

export function unitList(): Array<UnitDto> {
  return [
    { value: "Pound", name: "Pound", type: UnitType.Mass },
    { value: "Gallon", name: "Gallon", type: UnitType.Volume },
    { value: "Ounce", name: "Ounce", type: UnitType.Mass },
    { value: "Kilogram", name: "Kilogram", type: UnitType.Mass },
    { value: "Pint", name: "Pint", type: UnitType.Volume },
    { value: "Quart", name: "Quart", type: UnitType.Volume },
    { value: "Liter", name: "Liter", type: UnitType.Volume },
    { value: "Ton", name: "Ton", type: UnitType.Mass },
    { value: "FluidOunce", name: "Fluid ounce", type: UnitType.Volume },
    { value: "CubicFoot", name: "Cubic foot", type: UnitType.Volume },
    { value: "MetricTon", name: "Metric ton", type: UnitType.Mass },
  ];
}
