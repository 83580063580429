import React, { useContext } from 'react';
import { LoginType, UserContext, UserStatus } from './UserContext';

type Props = {
  children: React.ReactNode;
}

export const UserTemplate: React.FC<Props> = ({ children }) => {
  const userStatus = useContext<UserStatus>(UserContext);
  return (<>
    {userStatus.user?.userType === LoginType.User && children}
  </>);
}
