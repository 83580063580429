import React, { useContext, useState } from "react"
import useDisplayMessage from "../Common/useDisplayMessage";
import { WidgetDto, WidgetType } from "../../requests/useGetDashboardLayout";
import useAddWidget from "../../requests/useAddWidget";
import { DashboardContext } from "./DashboardContext";
import KaModal from "../Common/KaModal";
import KaAlert from "../Common/KaAlert";
import { isMultiValue, KaSelect } from "../Common/KaSelect";
import SpinnerButton from "../Common/SpinnerButton";
import { UncontrolledTooltip } from "reactstrap";
import { DroppableItem } from "../Common/Sortable/DroppableItem";

const deleteWidgetId = 'e2ddc514-6bcc-44d9-9873-05d7234e2d74';

const AddWidgetModal = ({ show, onClose }: { show: boolean, onClose: () => void }) => {
  const { addWidget } = useContext(DashboardContext);
  const [selectedType, setSelectedType] = useState<WidgetType>(WidgetType.AggregateProductLoaded);

  const displayMessage = useDisplayMessage();
  const addWidgetRequest = useAddWidget({
    onSuccess: (dto: WidgetDto) => {
      addWidget(dto);
      onClose();
    },
    onError: (error) => displayMessage.fail(error.message),
  });

  const saveSettings = () => {
    addWidgetRequest.request({ type: selectedType, name: widgetLabel(selectedType) });
  };

  const widgetLabel = (wt: WidgetType) => wt.replace(/([A-Z])/g, ' $1').trim();

  const widgetOption = (wt: WidgetType) => ({ value: wt, label: widgetLabel(wt) });

  return <KaModal
    onHide={onClose}
    show={show}
    title={'Add New Widget'}
    body={(<div className="mt-3">
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <label className="h5" htmlFor="widgetType">Widget Type:</label>
      <KaSelect
        inputId="widgetType"
        options={Object.values(WidgetType).map(widgetOption)}
        value={widgetOption(selectedType)}
        onChange={valueObject => {
          if (valueObject && !isMultiValue(valueObject))
            setSelectedType(valueObject.value as WidgetType)
        }}
      />
    </div>)}
    footer={(
      <SpinnerButton
        onClick={() => saveSettings()}
        spinning={addWidgetRequest.isLoading}>
        Add
      </SpinnerButton>
    )}
  />
}

const AddRemoveWidget = ({ isDragging }: { isDragging: boolean }) => {
  const { widgets } = useContext(DashboardContext);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const atMaxWidgets = widgets.length >= 8;
  const addOpacity = atMaxWidgets ? 0.4 : 1;

  return <>
    <AddWidgetModal
      show={showAddModal}
      onClose={() => setShowAddModal(false)} /><button
        id="add-widget"
        data-testid="add-widget"
        className="border-0 shadow-none bg-transparent p-0"
        disabled={atMaxWidgets}
        onClick={() => setShowAddModal(true)}>
      <DroppableItem key={deleteWidgetId} id={deleteWidgetId}>
        <div className="widget card h-100 border">
          <div className="my-auto mx-auto">
            {isDragging
              ? <>
                <div className="row">
                  <i className="bi bi-trash ka-blue" style={{ fontSize: "7em" }}></i>
                </div>
                <div className="row mt-n2">
                  <h3 className="text-center ka-blue">
                    Remove Widget
                  </h3>
                </div>
              </>
              : <>
                <div className="row">
                  <i className="bi bi-plus-circle ka-blue" style={{ fontSize: "7em", opacity: addOpacity }}></i>
                </div>
                <div className="row mt-n2">
                  <h3 className="text-center ka-blue" style={{ opacity: addOpacity }}>
                    Add New Widget
                  </h3>
                </div>
              </>}
          </div>
        </div>
      </DroppableItem>
    </button>
    {
      atMaxWidgets &&
      <UncontrolledTooltip placement="top" target="add-widget">
        Cannot add more than 8 widgets
      </UncontrolledTooltip>
    }
  </>;
}

export { AddRemoveWidget, deleteWidgetId };
