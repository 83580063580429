import { HttpMethod } from "../components/Common/useFetch";
import { RequestOptions } from "../components/Common/useRequest";
import useUserRequest from "../components/Common/useUserRequest";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";

export interface UpdateWidgetDto {
  id: string;
}

const useUpdateDashboardLayout = (options?: RequestOptions<void>) => {
  const business = useSelectedBusiness();
  return useUserRequest<UpdateWidgetDto[]>({
    path: `/api/businesses/${business.id}/dashboardLayout`,
    method: HttpMethod.PUT,
  }, options);
}

export default useUpdateDashboardLayout;